// src/components/Visitas.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const Visitas = ({ titulo, visitas }) => {
  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-left-warning shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                {titulo}
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-100">{visitas}</div>
            </div>
            <div className="col-auto text-gray-100">
              <FontAwesomeIcon icon={faEye} style={{ fontSize: '25px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visitas;
