import React from "react";

const Modal = ({ show, onClose, content, onPrev, onNext }) => {
  if (!show || !content) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          {" "}
          <i className="fas fa-times"></i>
        </button>
        <button className="nav-button prev-button" onClick={onPrev}>
          {" "}
          <i className="fas fa-chevron-left"></i>
        </button>
        {content.type === "image" ? (
          <img src={content.url} alt="Expanded" className="expanded-media" />
        ) : (
          <video controls className="expanded-media">
            <source src={content.url} type="video/mp4" />
            Tu navegador no soporta la etiqueta de video.
          </video>
        )}
        <button className="nav-button next-button" onClick={onNext}>
          {" "}
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Modal;
