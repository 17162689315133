// src/utils/validaciones.js
export const validarContraseña = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*]/;
    const hasUpperCase = /[A-Z]/; // Al menos una letra mayúscula
    const hasLowerCase = /[a-z]/; // Al menos una letra minúscula
    const errors = [];
  
    if (password.length < minLength) {
      errors.push("La contraseña debe tener al menos 8 caracteres.");
    }
    if (!hasNumber.test(password)) {
      errors.push("La contraseña debe contener al menos un número.");
    }
    if (!hasSpecialChar.test(password)) {
      errors.push("La contraseña debe contener al menos un símbolo especial (!@#$%^&*).");
    }
    if (!hasUpperCase.test(password)) {
      errors.push("La contraseña debe contener al menos una letra mayúscula.");
    }
    if (!hasLowerCase.test(password)) {
      errors.push("La contraseña debe contener al menos una letra minúscula.");
    }
  
    return errors;
  };
  