import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { adminDeleteTrainer } from '../features/AdminSlice';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const ListadoEntrenadores = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trainers = useSelector((state) => state.trainers.trainers);
  const [filteredTrainers, setFilteredTrainers] = useState(trainers);
  const [filterInput, setFilterInput] = useState("");

  useEffect(() => {
    setFilteredTrainers(trainers);
  }, [trainers]);

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterInput(value);
    setFilteredTrainers(
      trainers.filter(trainer =>
        trainer.nombre.toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = (trainerId) => {
    confirmAlert({
      title: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar este entrenador?',
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            dispatch(adminDeleteTrainer(trainerId));
            toast.success('Entrenador eliminado exitosamente');
          }
        },
        {
          label: 'No',
          onClick: () => toast.info('Eliminación cancelada')
        }
      ]
    });
  };

  const handleEdit = (trainerId) => {
    navigate(`/trainer-profile/${trainerId}`);
  };

  return (
    <div className="col-xl-12 col-lg-12 overflow-hidden" id="Tabla">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-success">
            Listado de Entrenadores:
          </h6>
          <input
            type="text"
            placeholder="Buscar entrenadores..."
            className="form-control"
            value={filterInput}
            onChange={handleFilter}
          />
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-dark table-bordered">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Teléfono</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredTrainers.map(trainer => (
                  <tr key={trainer.id}>
                    <td>{trainer.nombre}</td>
                    <td>{trainer.email}</td>
                    <td>{trainer.telefono}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleEdit(trainer.id)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(trainer.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListadoEntrenadores;
