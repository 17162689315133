import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Aseguramos que los íconos de Leaflet se carguen correctamente
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const LocationMap = ({ formData, editable, handleChange }) => {
  const [position, setPosition] = useState([formData.latitud || -34.6037, formData.longitud || -58.3816]); // Coordenadas iniciales

  useEffect(() => {
    if (formData.latitud && formData.longitud) {
      setPosition([formData.latitud, formData.longitud]);
    }
  }, [formData.latitud, formData.longitud]);

  return (
    <div className="location-map-section">
      <h3>Ubicación Mapa</h3>
      {editable ? (
        <MapContainer
          center={position}
          zoom={13}
          style={{ height: "400px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapClickHandler setPosition={setPosition} handleChange={handleChange} />
          <Marker position={position}>
            <Popup>Arrastra el marcador para seleccionar tu ubicación</Popup>
          </Marker>
        </MapContainer>
      ) : (
        position && (
          <MapContainer
            center={position}
            zoom={13}
            style={{ height: "400px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup>Ubicación del entrenador</Popup>
            </Marker>
          </MapContainer>
        )
      )}
    </div>
  );
};

// Componente para manejar clics en el mapa y obtener la ubicación seleccionada
const MapClickHandler = ({ setPosition, handleChange }) => {
  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setPosition([lat, lng]);
      handleChange({ target: { name: "latitud", value: lat } });
      handleChange({ target: { name: "longitud", value: lng } });
    },
  });
  return null;
};

export default LocationMap;
