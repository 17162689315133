// src/services/loginService.js
import api from './axiosConfig';

// Servicio de inicio de sesión
export const login = async (userData) => {
  try {
    const response = await api.post('/login', userData);
    return response.data;
  } catch (error) {
    console.error('Error en el inicio de sesión:', error);
    
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Error desconocido');
    }

    throw new Error('Error en el inicio de sesión'); // Error genérico en caso de que no haya detalles adicionales
  }
};
