import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateTrainer,
  updateTrainerProfilePhoto,
  uploadTrainerMediaFiles,
} from "../features/TrainersSlice";
import { updateUserState } from "../features/authSlice";
import TrainerProfileHeader from "./TrainerProfileHeader";
import ImagesSection from "./ImagesSection";
import About from "./TrainerAbout";
import Experience from "./TrainerExperience";
import Services from "./TrainerServices";
import Availability from "./TrainerAvailability";
import Pricing from "./TrainerPricing";
import LocationMap from "./TrainerLocationMap";
import { toast } from "react-toastify";

const CrearPerfil = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [formData, setFormData] = useState({
    nombre: user.nombre || "",
    especialidad: "",
    telefono: "",
    descripcion: "",
    ciudad: "",
    latitud: 0,
    longitud: 0,
    mapLink: "",
    precio: 0,
    sobreMi: "",
    experiencia: "",
    servicios: "",
    disponibilidad: "",
    pricing: "",
    social: {},
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [notification, setNotification] = useState("");
  const [photoFile, setPhotoFile] = useState(null);
  const [mediaFiles, setMediaFiles] = useState([]);

  const steps = ["Encabezado", "Imágenes", "Detalles 1", "Detalles 2"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      foto:"",
      media:null,
      [name]: value,
    });
  };

  const handleMapLinkChange = (e) => {
    setFormData({
      ...formData,
      mapLink: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setPhotoFile(file);

    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   setProfileImage(reader.result);
    // };
    // if (file) {
    //   reader.readAsDataURL(file);
    // }
  };

  const handleMediaChange = (acceptedFiles) => {
    const validFiles = acceptedFiles.filter(
      (file) => file.type.startsWith("image/") || file.type === "video/mp4"
    );
    setMediaFiles([...mediaFiles, ...validFiles]); // Almacena los archivos multimedia seleccionados
  };

  console.log(formData);

  const validateForm = () => {
    if (!formData.nombre?.trim()) {
      toast.error("El nombre es obligatorio.");
      return false;
    }
    if (!formData.especialidad?.trim()) {
      toast.error("La especialidad es obligatoria.");
      return false;
    }
    if (!formData.telefono?.trim()) {
      toast.error("El telefono es obligatorio.");
      return false;
    }
    if (!formData.descripcion?.trim()) {
      toast.error("La descripcion es obligatoria.");
      return false;
    }
    if (!formData.ciudad?.trim()) {
      toast.error("La localidad es obligatoria.");
      return false;
    }
    if (!formData.precio || isNaN(formData.precio) || formData.precio <= 0) {
      toast.error("El precio debe ser un número positivo.");
      return false;
    }
    if (!formData.sobreMi?.trim()) {
      toast.error("Sobre mi es obligatorio.");
      return false;
    }
    // if (!formData.fotoUrl) {
    //   toast.error("Debe subir una imagen de perfil.");
    //   return false;
    // }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    console.log("Form Data Antes del envio:", formData); // Verifica los datos

    try {
      if (photoFile) {
        // Subir la nueva foto de perfil a AWS S3 si se seleccionó una
        await dispatch(
          updateTrainerProfilePhoto({ trainerId: user.id, photoFile })
        ).unwrap();
      }

      // Subir archivos multimedia a AWS S3 si se seleccionaron
      if (mediaFiles.length > 0) {
        for (const mediaFile of mediaFiles) {
          await dispatch(
            uploadTrainerMediaFiles({ trainerId: user.id, mediaFile })
          ).unwrap();
        }
        setMediaFiles([]); // Reiniciar los archivos subidos
      }

      const completeFormData = {
        type: user.type,
        ...formData,
        perfilCompleto: true,
      };

      console.log(user.id, completeFormData);

      const updatedTrainer = await dispatch(
        updateTrainer({ trainerId: user.id, trainerData: completeFormData })
      ).unwrap();
      console.log(updateTrainer);

      // Actualizar el estado del usuario en authSlice
      dispatch(updateUserState(updatedTrainer));

      toast.success("Perfil creado exitosamente");

    } catch (error) {
      // Imprime el error en la consola
      console.error("Error al crear el perfil:", error);
      console.error(
        "Detalles del error:",
        error.message || error.response?.data || error
      );

      // Muestra el mensaje de error al usuario
      toast.error(
        "Error al crear el perfil. Por favor, verifica los datos e inténtalo de nuevo."
      );
    }
  };

  const handleNextStep = () => {
    setNotification("");
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setNotification("");
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <div className="create-profile-page blurred">
      <div className="create-profile">
        <button
          onClick={() => navigate(-1)}
          className="fa-solid fa-arrow-left back-button"
        ></button>
        <h1>Crear Perfil de Entrenador</h1>
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "65ch", width: "100%" }}
        >
          {currentStep === 1 && (
            <TrainerProfileHeader
              trainer={formData}
              editable={true}
              setEditable={() => {}}
              formData={formData}
              setFormData={setFormData}
              profileImage={formData.fotoUrl}
              handleImageChange={handleImageChange}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleVerifyClick={() => {}}
              isTrainerAuthenticated={true}
              isCreating={true}
            />
          )}
          {currentStep === 2 && (
            <ImagesSection
              trainer={formData}
              editable={true}
              onMediaUpload={handleMediaChange}
            />
          )}
          {currentStep === 3 && (
            <div className="profile-section">
              <About
                formData={formData}
                editable={true}
                handleChange={handleChange}
              />
              <Experience
                formData={formData}
                editable={true}
                handleChange={handleChange}
              />
              <Services
                formData={formData}
                editable={true}
                handleChange={handleChange}
              />
            </div>
          )}
          {currentStep === 4 && (
            <div className="profile-section">
              <Availability
                formData={formData}
                editable={true}
                handleChange={handleChange}
              />
              <Pricing
                formData={formData}
                editable={true}
                handleChange={handleChange}
              />
              <LocationMap
                formData={formData}
                editable={true}
                handleChange={handleMapLinkChange}
              />
            </div>
          )}
          <div className="step-navigation">
            {currentStep > 1 && (
              <button type="button" onClick={handlePreviousStep}>
                Anterior
              </button>
            )}
            {currentStep < steps.length ? (
              <button type="button" onClick={handleNextStep}>
                Siguiente
              </button>
            ) : (
              <button type="submit">Crear Perfil</button>
            )}
          </div>
        </form>
        {notification && <p className="notification">{notification}</p>}
      </div>
    </div>
  );
};

export default CrearPerfil;
