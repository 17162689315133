import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify'; 

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      toast.error('Por favor, complete todos los campos obligatorios.');
      return;
    }

    // Parametros que enviamos a EmailJS
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      subject: formData.subject,
      message: formData.message,
      to_email: 'treinoapp990@gmail.com',
    };

    emailjs
      .send(
        'service_87ml07w', //Service ID de EmailJS
        'template_e3f6nzq', //Template ID de EmailJS
        templateParams,
        'Siq044lIJzJ2u6Rb7' //Public Key de EmailJS
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          toast.success('Mensaje enviado exitosamente');
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
          }); // Limpiar el formulario después del envío
        },
        (error) => {
          console.error('FAILED...', error);
          toast.error('Hubo un error al enviar el mensaje. Inténtalo de nuevo.');
        }
      );
  };

  return (
    <section className="section" id="contact-us">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d209591.45544802354!2d-56.1974716!3d-34.834449150000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f80ffc63bf7d3%3A0x6b321b2e355bec99!2sMontevideo%2C%20Departamento%20de%20Montevideo!5e0!3m2!1ses-419!2suy!4v1725925693846!5m2!1ses-419!2suy"
                width="100%"
                height="600px"
                style={{ border: '0' }}
                allowFullScreen
                title="Google Maps"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div className="contact-form">
              <form id="contact" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input
                        name="name"
                        type="text"
                        id="name"
                        placeholder="Nombre*"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input
                        name="email"
                        type="email"
                        id="email"
                        placeholder="Email*"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <fieldset>
                      <input
                        name="subject"
                        type="text"
                        id="subject"
                        placeholder="Asunto"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <textarea
                        name="message"
                        rows="6"
                        id="message"
                        placeholder="Mensaje"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <button type="submit" id="form-submit" className="main-button">
                        Enviar Mensaje
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
