import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTrainers,
  getPublishedTrainers,
  getTrainerById,
  getTrainerByEmail,
  registerTrainer,
  loginTrainer,
  updateTrainerAccount,
  updateTrainerPhoto,
  uploadVerificationPhoto,
  uploadTrainerMedia,
  deleteTrainer,
  deleteAllTrainers,
} from "../services/trainerService";
import { localMedia } from "../utils/loadedUsers";


// Función para obtener un medio aleatorio
const getRandomMedia = () => {
  const randomIndex = Math.floor(Math.random() * localMedia.length);
  return localMedia[randomIndex];
};

// Thunks para operaciones asincrónicas

//Obtener todos los entrenadores
export const fetchTrainers = createAsyncThunk(
  "trainers/fetchTrainers",
  async (_, { rejectWithValue }) => {
    try {
      const trainers = await getTrainers();
      return trainers;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al obtener los entrenadores"
      );
    }
  }
);

// Obtener solo entrenadores publicados
export const fetchPublishedTrainers = createAsyncThunk(
  "trainers/fetchPublishedTrainers",
  async (_, { rejectWithValue }) => {
    try {
      const trainers = await getPublishedTrainers();
      console.log(trainers);
      return trainers;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Error al obtener los entrenadores publicados"
      );
    }
  }
);

// Obtener un entrenador por ID
export const fetchTrainer = createAsyncThunk(
  "trainers/fetchTrainer",
  async (trainerId, { rejectWithValue }) => {
    try {
      const trainer = await getTrainerById(trainerId);
      trainer.media = Array.from({ length: 3 }, () => getRandomMedia());
      return trainer;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al obtener el entrenador"
      );
    }
  }
);

// Obtener un entrenador por email
export const fetchTrainerByEmail = createAsyncThunk(
  "trainers/fetchTrainerByEmail",
  async (email, { rejectWithValue }) => {
    try {
      const trainer = await getTrainerByEmail(email);
      return trainer;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Error al obtener el entrenador por email"
      );
    }
  }
);

// Crear un nuevo entrenador
export const addTrainer = createAsyncThunk(
  "trainers/addTrainer",
  async (trainerData, { rejectWithValue }) => {
    try {
      const newTrainer = await registerTrainer(trainerData);
      return newTrainer;
    } catch (error) {
      console.error("Error al agregar entrenador", error);
      return rejectWithValue(
        error.response?.data?.message || "Error al agregar entrenador"
      );
    }
  }
);

// Login entrenador
export const loginTrainerAccount = createAsyncThunk(
  "trainers/loginTrainerAccount",
  async (trainerData, { rejectWithValue }) => {
    try {
      const logTrainer = await loginTrainer(trainerData);
      return logTrainer;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al iniciar sesion de entrenador"
      );
    }
  }
);

// Actualizar un entrenador existente
export const updateTrainer = createAsyncThunk(
  "trainers/updateTrainer",
  async ({ trainerId, trainerData }, { rejectWithValue }) => {
    try {
      console.log(trainerId, trainerData);
      const updatedTrainer = await updateTrainerAccount(trainerId, trainerData);
      console.log(updateTrainer);
      return updatedTrainer;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al actualizar entrenador"
      );
    }
  }
);

// Actualizar la foto de un entrenador existente
export const updateTrainerProfilePhoto = createAsyncThunk(
  "trainers/updateTrainerProfilePhoto",
  async ({ trainerId, photoFile }, { rejectWithValue }) => {
    try {
      const updatedTrainer = await updateTrainerPhoto(trainerId, photoFile);
      console.log(updatedTrainer.data);
      return updatedTrainer;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Error al actualizar la foto del entrenador"
      );
    }
  }
);

// Subir la foto de verificación de un entrenador
export const uploadTrainerVerificationPhoto = createAsyncThunk(
  "trainers/uploadTrainerVerificationPhoto",
  async ({ trainerId, photoFile }, { rejectWithValue }) => {
    try {
      const verificationPhotoUrl = await uploadVerificationPhoto(
        trainerId,
        photoFile
      );
      return verificationPhotoUrl;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Error al subir la foto de verificación"
      );
    }
  }
);

// Thunk para subir medios (imágenes o videos) al perfil del entrenador
export const uploadTrainerMediaFiles = createAsyncThunk(
  "trainers/uploadTrainerMediaFiles",
  async ({ trainerId, mediaFile }, { rejectWithValue }) => {
    try {
      const mediaUrl = await uploadTrainerMedia(trainerId, mediaFile);
      return mediaUrl; 
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Error al subir los medios del entrenador"
      );
    }
  }
);

// Eliminar entrenador
export const deleteTrainerAccount = createAsyncThunk(
  "trainers/deleteTrainerAccount",
  async (trainerId, { dispatch, rejectWithValue }) => {
    try {
      await deleteTrainer(trainerId);
      return trainerId;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Error al eliminar la cuenta del entrenador"
      );
    }
  }
);

// Eliminar todos los entrenadores
export const deleteAllTrainersAccount = createAsyncThunk(
  "trainers/deleteAllTrainersAccount",
  async (_, { rejectWithValue }) => {
    try {
      await deleteAllTrainers();
      return "Todos los entrenadores fueron eliminados con exito";
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error al eliminar todos los entrenadores"
      );
    }
  }
);

// Slice principal
const trainersSlice = createSlice({
  name: "trainers",
  initialState: {
    trainers: [],
    selectedTrainer: null,
    status: "idle",
    error: null,
  },
  reducers: {
    selectTrainer: (state, action) => {
      state.selectedTrainer = action.payload;
    },
    clearTrainers: (state) => {
      state.trainers = [];
      state.status = "idle";
      state.error = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Trainers
      .addCase(fetchTrainers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrainers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trainers = action.payload;
      })
      .addCase(fetchTrainers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Fetch Published Trainers
      .addCase(fetchPublishedTrainers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPublishedTrainers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trainers = action.payload;
      })
      .addCase(fetchPublishedTrainers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch Single Trainer
      .addCase(fetchTrainer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrainer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedTrainer = action.payload;
      })
      .addCase(fetchTrainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch Trainer by Email
      .addCase(fetchTrainerByEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrainerByEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedTrainer = action.payload;
      })
      .addCase(fetchTrainerByEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Add Trainer
      .addCase(addTrainer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addTrainer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trainers.push(action.payload);
      })
      .addCase(addTrainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Login Trainer
      .addCase(loginTrainerAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginTrainerAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trainers.push(action.payload);
      })
      .addCase(loginTrainerAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update Trainer
      .addCase(updateTrainer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTrainer.fulfilled, (state, action) => {
        state.status = "succeeded";
        // const index = state.trainers.findIndex(
        //   (trainer) => trainer.id === action.payload.id
        // );
        // if (index !== -1) {
        //   state.trainers[index] = action.payload;
        // }
        if (
          state.selectedTrainer &&
          state.selectedTrainer.id === action.payload.id
        ) {
          state.selectedTrainer = action.payload;
        }
      })
      .addCase(updateTrainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update Trainer Profile Photo
      .addCase(updateTrainerProfilePhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTrainerProfilePhoto.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.trainers.findIndex(
          (trainer) => trainer.id === action.payload.id
        );
        if (index !== -1) {
          state.trainers[index] = action.payload; // Actualiza el entrenador en la lista
        }
        if (
          state.selectedTrainer &&
          state.selectedTrainer.id === action.payload.id
        ) {
          state.selectedTrainer = action.payload; // Actualiza el entrenador seleccionado
        }
      })
      .addCase(updateTrainerProfilePhoto.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Subir la foto de verificación del entrenador
      .addCase(uploadTrainerVerificationPhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadTrainerVerificationPhoto.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Aquí puedes actualizar el estado del entrenador con la nueva foto de verificación
      })
      .addCase(uploadTrainerVerificationPhoto.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Subida de medios (imágenes y videos) al perfil del entrenador
      .addCase(uploadTrainerMediaFiles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadTrainerMediaFiles.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { trainerId, mediaUrl } = action.payload;

        const trainerIndex = state.trainers.findIndex(
          (trainer) => trainer.id === trainerId
        );

        if (trainerIndex !== -1) {
          // Agregamos la nueva media al perfil del entrenador
          state.trainers[trainerIndex].media = [
            ...(state.trainers[trainerIndex].media || []),
            mediaUrl,
          ];
        }

        if (state.selectedTrainer && state.selectedTrainer.id === trainerId) {
          state.selectedTrainer.media = [
            ...(state.selectedTrainer.media || []),
            mediaUrl,
          ];
        }
      })
      .addCase(uploadTrainerMediaFiles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //Delete Trainer
      .addCase(deleteTrainerAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTrainerAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trainers = state.trainers.filter(
          (trainer) => trainer.id !== action.payload
        );
        if (
          state.selectedTrainer &&
          state.selectedTrainer.id === action.payload
        ) {
          state.selectedTrainer = null;
        }
      })
      .addCase(deleteTrainerAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete All Trainers
      .addCase(deleteAllTrainersAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAllTrainersAccount.fulfilled, (state) => {
        state.status = "succeeded";
        state.trainers = [];
        state.selectedTrainer = null;
      })
      .addCase(deleteAllTrainersAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { selectTrainer, clearTrainers, clearError } =
  trainersSlice.actions;

export default trainersSlice.reducer;
