import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  updateTrainer,
  fetchTrainer,
  updateTrainerProfilePhoto,
  uploadTrainerMediaFiles,
} from "../features/TrainersSlice";
import Modal from "./Modal";
import Comments from "./Comments";
import About from "./TrainerAbout";
import Experience from "./TrainerExperience";
import Services from "./TrainerServices";
import Availability from "./TrainerAvailability";
import Pricing from "./TrainerPricing";
import LocationMap from "./TrainerLocationMap";
import ImagesSection from "./ImagesSection";
import TrainerProfileHeader from "./TrainerProfileHeader";
// import VisitasPerfilEntrenador from "./VisitasPerfilEntrenador";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import avatarImg from "../assets/img/avatar.png";

const TrainerProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [originalData, setOriginalData] = useState({});
  const [activeSection, setActiveSection] = useState("Imágenes");
  const [modalIndex, setModalIndex] = useState(-1);
  const [photoFile, setPhotoFile] = useState(null);
  const [mediaFiles, setMediaFiles] = useState([]);

  useEffect(() => {
    dispatch(fetchTrainer(id)).then((response) => {
      console.log(response);
      if (response.payload) {
        setFormData(response.payload);
        setOriginalData(response.payload);
        setProfileImage(response.payload.fotoUrl || avatarImg);
      }
    });
  }, [dispatch, id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      foto:"",
      media:null,
      [e.target.name]: e.target.value,
    });
  };

  // Manejar el cambio de imagen
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setPhotoFile(file); // Guardar el archivo para enviarlo después

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Manejar la subida de imágenes y videos
  const handleMediaChange = (acceptedFiles) => {
    const validFiles = acceptedFiles.filter(
      (file) => file.type.startsWith("image/") || file.type === "video/mp4"
    );
    setMediaFiles([...mediaFiles, ...validFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (photoFile) {
        // Si hay una nueva foto seleccionada, actualiza la foto de perfil
        await dispatch(
          updateTrainerProfilePhoto({ trainerId: id, photoFile })
        ).unwrap();
        toast.success("Foto de perfil actualizada correctamente.");
      }

      if (mediaFiles.length > 0) {
        for (const mediaFile of mediaFiles) {
          await dispatch(
            uploadTrainerMediaFiles({ trainerId: id, mediaFile })
          ).unwrap();
        }
        setMediaFiles([]);
        toast.success("Archivos multimedia subidos correctamente.");
      }

      if (JSON.stringify(formData) !== JSON.stringify(originalData)) {
        // Si hay otros cambios en el perfil, actualízalos
        console.log(formData);
        const response = await dispatch(
          updateTrainer({ trainerId: id, trainerData: formData })
        ).unwrap();
        const updatedTrainer = response?.updatedTrainer || response;
        if (updatedTrainer) {
          setFormData(updatedTrainer);
          setOriginalData(updatedTrainer);
          setEditable(false);
          toast.success("Cambios guardados exitosamente.");
        }
      } else if (!photoFile) {
        toast.info("No hay cambios para guardar.");
      }

    } catch (error) {
      console.error("Error al actualizar el entrenador:", error);
      toast.error("Hubo un error al guardar los cambios. Inténtalo de nuevo.");
    }
  };

  const handleVerifyClick = () => {
    alert("Iniciar proceso de verificación");
  };

  const handleSelectPlan = () => {
    navigate(`/membership-plans/${id}`);
  };

  if (!id) {
    return <div>Entrenador no encontrado</div>;
  }

  // const handleMediaClick = (index) => {
  //   setModalIndex(index);
  // };

  const closeModal = () => {
    setModalIndex(-1);
  };

  const navigateModal = (direction) => {
    setModalIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex < 0) return originalData.media.length - 1;
      if (newIndex >= originalData.media.length) return 0;
      return newIndex;
    });
  };

  const isTrainerAuthenticated =
    user && user.rol === "TRAINER" && user.id === originalData.id;

  return (
    <div className="trainer-profile-page">
      <div className="trainer-profile">
        <button
          onClick={() => navigate(-1)}
          className="fa-solid fa-arrow-left back-button"
        ></button>
        <TrainerProfileHeader
          trainer={originalData}
          user={user}
          editable={editable}
          setEditable={setEditable}
          formData={formData}
          setFormData={setFormData}
          profileImage={profileImage}
          handleImageChange={handleImageChange}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleVerifyClick={handleVerifyClick}
          isTrainerAuthenticated={isTrainerAuthenticated}
          handleSelectPlan={handleSelectPlan}
        />
        <div className="profile-sections">
          <button
            className={`profile-section-button ${
              activeSection === "Imágenes" ? "active" : ""
            }`}
            onClick={() => setActiveSection("Imágenes")}
          >
            <i className="fas fa-images"></i> Imágenes
          </button>
          <button
            className={`profile-section-button ${
              activeSection === "Perfil" ? "active" : ""
            }`}
            onClick={() => setActiveSection("Perfil")}
          >
            <i className="fas fa-user"></i> Perfil
          </button>
          <button
            className={`profile-section-button ${
              activeSection === "Comentarios" ? "active" : ""
            }`}
            onClick={() => setActiveSection("Comentarios")}
          >
            <i className="fas fa-comments"></i> Comentarios
          </button>
        </div>
        <div className="profile-content">
          {activeSection === "Imágenes" && (
            <ImagesSection trainer={originalData} editable={editable} onMediaUpload={handleMediaChange}/>
          )}
          {activeSection === "Perfil" && (
            <div className="profile-section">
              <About
                formData={formData}
                editable={editable}
                handleChange={handleChange}
              />
              <Experience
                formData={formData}
                editable={editable}
                handleChange={handleChange}
              />
              <Services
                formData={formData}
                editable={editable}
                handleChange={handleChange}
              />
              <Availability
                formData={formData}
                editable={editable}
                handleChange={handleChange}
              />
              <Pricing
                formData={formData}
                editable={editable}
                handleChange={handleChange}
              />
              <LocationMap
                formData={formData}
                editable={editable}
                handleChange={handleChange}
              />
            </div>
          )}
          {activeSection === "Comentarios" && (
            <div id="comentarios" className="comments-section">
              <Comments trainerId={originalData.id} />
            </div>
          )}
        </div>
      </div>
      {modalIndex !== -1 &&
        originalData.media &&
        originalData.media[modalIndex] && (
          <Modal
            show={modalIndex !== -1}
            onClose={closeModal}
            content={originalData.media[modalIndex]}
            onPrev={() => navigateModal(-1)}
            onNext={() => navigateModal(1)}
          />
        )}
    </div>
  );
};

export default TrainerProfile;
