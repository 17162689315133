// src/components/UserActivity.js
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../features/UsersSlice";
import { fetchUserReviews } from "../features/CommentsSlice";

const UserActivity = () => {
  const user = useSelector((state) => state.auth.user);
  const trainers = useSelector((state) => state.trainers.trainers);
  const comments = useSelector((state) => state.comments.comments);
  const userStatus = useSelector((state) => state.users.status);
  const reviewsStatus = useSelector((state) => state.comments.status); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (user && user.rol === "USER") {
      if (userStatus === "idle") {
        dispatch(fetchUser(user.id)); // Refrescar los datos del usuario si no se han cargado
      }
      if (reviewsStatus === "idle") {
        dispatch(fetchUserReviews(user.id)); // Cargar las reseñas del usuario si no se han cargado
      }
    }
  }, [dispatch, user, userStatus, reviewsStatus]);
  

  const updatedUser = useSelector((state) =>
    state.users.selectedUser
  );

  if (!updatedUser || !comments || comments.length === 0) {
    return (
      <div className="user-activity-page blurred">
        <div className="user-activity">
          <button className="fa-solid fa-arrow-left back-button" onClick={() => navigate(-1)}>
          </button>
          <div className="no-reviews">No tienes reseñas.</div>
        </div>
      </div>
    );
  }

  const getTrainerNameById = (trainerId) => {
    const trainer = trainers.find((t) => t.id === trainerId);
    return trainer ? trainer.nombre : "Entrenador desconocido";
  };

  const handleReviewClick = (trainerId) => {
    navigate(`/trainer-profile/${trainerId}#comentarios`); // Navegar al perfil del entrenador y desplazarse a la sección de comentarios
  };

  return (
    <div className="user-activity-page blurred">
      <div className="user-activity">
        <button className="fa-solid fa-arrow-left back-button" onClick={() => navigate(-1)}>
        </button>
        <h2>Actividad Reciente</h2>
        <h3>Lista De Reseñas</h3>
        {comments.slice().reverse().map((review) => (
          <div 
            key={review.id} 
            className="review-item" 
            onClick={() => handleReviewClick(review.profesorId)} // Manejar el clic en la reseña
            style={{ cursor: 'pointer' }}
          >
            <div className="review-header">
              <strong>Entrenador:</strong> {getTrainerNameById(review.profesorId)}
            </div>
            <div className="review-body">
              <div>
                <strong>Comentario:</strong> {review.texto}
              </div>
              <div>
                <strong>Rating:</strong> {review.rating} / 5
              </div>
              <div>
                <strong>Fecha:</strong>{" "}
                {new Date(review.createAt).toLocaleDateString()}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserActivity;
