import React from 'react';

const Pricing = ({ formData, editable, handleChange }) => {
  const pricing = Array.isArray(formData.pricing) ? formData.pricing : [];

  return (
    <div className="pricing-section">
      <h3>Precios y Paquetes</h3>
      {editable ? (
        <textarea
          name="pricing"
          value={formData.pricing}
          onChange={handleChange}
          className="edit-textarea"
        />
      ) : (
        <p>
          {formData.pricing}
        </p>
      )}
    </div>
  );
};

export default Pricing;
