import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import { Link } from "react-router-dom";
import MapCenterer from './useMap';  // Importar el nuevo componente

const TrainerMap = ({ trainers, selectedLocation, userLocation }) => {
  return (
    <MapContainer
      center={userLocation ? [userLocation.lat, userLocation.lng] : [-34.9011, -56.1645]}
      zoom={userLocation ? 14 : 12}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      
      {selectedLocation && <MapCenterer selectedLocation={selectedLocation} />}

      {trainers.map((trainer, index) => (
        <Marker
          key={index}
          position={[trainer.latitud, trainer.longitud]}
          icon={new L.Icon({
            iconUrl: trainer.fotoUrl,
            iconSize: [50, 50],
            iconAnchor: [25, 25],
            popupAnchor: [0, -25],
            className: 'custom-marker'
          })}
        >
          <Popup>
            <img className='trainerPopup-img' src={trainer.fotoUrl} /><br /><br />
            <strong>{trainer.nombre}</strong><br />
            {trainer.especialidad}<br />
            <Link to={`/entrenadores/${trainer.id}`}>Ver Perfil</Link>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};


export default TrainerMap;
