import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, updateUser, updateUserProfilePhoto } from '../features/UsersSlice'; // Importa el thunk
import { useNavigate, useParams } from 'react-router-dom';
import avatarImg from "../assets/img/avatar.png";

const UserProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user); // Usuario autenticado
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({});
  const [profileImage, setProfileImage] = useState('');
  const [originalData, setOriginalData] = useState({});
  const [notification, setNotification] = useState('');
  const [photoFile, setPhotoFile] = useState(null); // Nueva variable para almacenar el archivo de la foto

  useEffect(() => {
    dispatch(fetchUser(id)).then((response) => {
      console.log("Fetch User Response: ", response);
      if (response.payload) {
        setFormData(response.payload);
        setOriginalData(response.payload);
        setProfileImage(response.payload.foto || avatarImg);
      }
    });
  }, [dispatch, id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected file: ", file); // Log para verificar el archivo seleccionado
    setPhotoFile(file); // Guardar el archivo en el estado para enviarlo luego

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (photoFile) {
        // Si se ha seleccionado una nueva foto, actualiza la foto del perfil
        console.log("Subiendo nueva foto...");
        await dispatch(updateUserProfilePhoto({ userId: id, photoFile })).unwrap();
        console.log("Foto subida exitosamente.");
      }

      if (JSON.stringify(formData) !== JSON.stringify(originalData)) {
        console.log("Updating user data...", formData);
        const response = await dispatch(updateUser({ userId: id, userData: formData })).unwrap();
        
        const updatedUser = response?.updatedUser || response; // Cambia según tu estructura real
  
        if (updatedUser) {
          if (user.id === id) {
            localStorage.setItem('user', JSON.stringify(updatedUser));
          }
          setFormData(updatedUser);
          setOriginalData(updatedUser);
          setEditable(false);
          setNotification('Cambios guardados exitosamente');
        } else {
          throw new Error('No se pudo actualizar el usuario correctamente.');
        }
      } else if (!photoFile) {
        setNotification('No hay cambios para guardar');
      }

      setTimeout(() => {
        setNotification('');
      }, 3000);
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
      setNotification('Hubo un error al guardar los cambios. Inténtalo de nuevo.');
      setTimeout(() => {
        setNotification('');
      }, 3000);
    }
  };

  const handleBackClick = () => {
    navigate(-1); // Navega hacia atrás en el historial
  };

  return (
    <div className="user-profile-page">
      <div className="user-profile">
        <button className="fa-solid fa-arrow-left back-button" onClick={handleBackClick}>
        </button>
        <h2>Perfil del Usuario</h2>
        {notification && <div className="notification">{notification}</div>}
        <form onSubmit={handleSubmit}>
          <div className="profile-image-container">
            <img src={profileImage} alt="Perfil" className="profile-image" style={{backgroundColor:'#cfcfcf'}}/>
            {editable && (
              <input type="file" accept="image/*" onChange={handleImageChange} />
            )}
          </div>
          <div className="form-group">
            <label>Nombre Completo:</label>
            <input
              type="text"
              name="nombre"
              value={formData?.nombre || ''}
              onChange={handleChange}
              disabled={!editable}
            />
          </div>
          <div className="form-group">
            <label>Correo Electrónico:</label>
            <input
              type="email"
              name="email"
              value={formData?.email || ''}
              onChange={handleChange}
              disabled={!editable}
            />
          </div>
          <div className="form-group">
            <label>Número de Teléfono:</label>
            <input
              type="tel"
              name="telefono"
              value={formData?.telefono || ''}
              onChange={handleChange}
              disabled={!editable}
            />
          </div>
          <div className="form-group">
            <label>Objetivos de Fitness:</label>
            <input
              type="text"
              name="objetivos"
              value={formData?.objetivos || ''}
              onChange={handleChange}
              disabled={!editable}
            />
          </div>
          <div className="form-group">
            <label>Preferencias de Entrenamiento:</label>
            <input
              type="text"
              name="preferencias"
              value={formData?.preferencias || ''}
              onChange={handleChange}
              disabled={!editable}
            />
          </div>
          <div className="buttons-container">
            <button
              type="button"
              className="edit-button-user"
              onClick={() => setEditable(user.rol === 'ADMIN' || String(user.id) === String(id))}
              disabled={editable}
            >
              Editar
            </button>
            <button type="submit" className="save-button" disabled={!editable && !photoFile}>
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
