import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { calcularInversion } from "../features/TransaccionesSlice";

const MontoFinalInversiones = () => {
  const inversion = useSelector((state) => state.transacciones.inversion);
  const dispatch = useDispatch();
  const transacciones = useSelector(
    (state) => state.transacciones.transacciones
  );

  // dispatch(calcularInversion());

  useEffect(() => {
    calcular();
  }, [transacciones]);

  const calcular = () => {
    dispatch(calcularInversion());
  };
  return (
    /* <!-- Content Col --> */
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-left-success shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                Ingresos Totales:
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-100">
                {inversion}
              </div>
            </div>
            <div className="col-auto text-gray-100">
              <FontAwesomeIcon
                icon={faDollarSign}
                style={{ fontSize: "25px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MontoFinalInversiones;
