import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction = () => (
  <section className="section" id="call-to-action">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="cta-content">
            <h2>
             ¡No lo <em>pienses</em>, comienza  <em>hoy</em>!
            </h2>
            <p>
            El siguiente nivel te está esperando. ¡Hazte miembro ahora!
            </p>
            <div className="main-button scroll-to-section">
              <Link to="/registro">Convertirse en miembro</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;
