import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const NumeroEntrenadores = () => {
  const numeroEntrenadores = useSelector((state) => state.trainers.trainers);
  return (
    /* <!-- Content Col --> */
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-left-danger shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                Numero De Entrenadores:
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-100">
                {numeroEntrenadores.length}
              </div>
            </div>
            <div className="col-auto text-gray-100">
              <FontAwesomeIcon
                icon={faPersonWalking}
                style={{ fontSize: "25px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumeroEntrenadores;
