import React, { useEffect } from 'react';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/tabs';
import ScrollReveal from 'scrollreveal';
import MainBanner from './MainBanner';
import FeaturedTrainers from './EntrenadoresDestacadosMain';
import HowItWorks from './ComoFunciona';
import CallToAction from './CallToAction';
import ContactForm from './ContactForm';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const Inicio = () => {
  const { hash } = useLocation(); // Obtener el hash actual de la URL (ej: #contact-us)

  useEffect(() => {
    // Inicialización de tabs
    if ($("#tabs").length) {
      $("#tabs").tabs();
    }

    // Inicialización de ScrollReveal
    ScrollReveal().reveal('.reveal', { delay: 500 });

    // Si hay un hash, hacer scroll a esa sección cuando se cargue el componente
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]); // Ejecutar este efecto cuando el hash cambie

  return (
    <div>
      <MainBanner id="main-banner" />
      <FeaturedTrainers id="featured-trainers" />
      <HowItWorks id="how-it-works" />
      <CallToAction id="call-to-action" />
      <ContactForm id="contact-us" /> {/* El ID de la sección que queremos */}
      <Footer />
    </div>
  );
};

export default Inicio;
