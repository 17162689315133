// src/components/TrainerItem.js

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faRegularHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faCrown,
  faStar,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import { updateUser } from "../features/UsersSlice";

const membershipIcons = {
  BASIC: faMedal,
  PREMIUM: faStar,
  ELITE: faCrown,
};

const TrainerItem = ({
  id,
  nombre,
  especialidad,
  ciudad,
  precio,
  verificado,
  fotoUrl,
  descripcion,
  social,
  membresia,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [isFavorite, setIsFavorite] = useState(false);

  // Cargar favoritos del localStorage cuando el componente se monta
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favoriteTrainers")) || [];
    setIsFavorite(storedFavorites.some((fav) => fav.id === id));
  }, [id]);

 // Verifica si el entrenador ya está en la lista de favoritos del usuario
//  const isFavorite = useSelector((state) => {
//   if (!user || !user.entrenadoresFavoritos) return false;
//   return user.entrenadoresFavoritos.some((favT) => favT.id === id); // Verifica si el ID está en favoritos
// });

  const handleFavoriteClick = async (e) => {
    console.log("id entrenador " + id);
    e.preventDefault();

    const storedFavorites = JSON.parse(localStorage.getItem("favoriteTrainers")) || [];
    let updatedFavorites;

    if (isFavorite) {
      // Si ya es favorito, eliminarlo
      updatedFavorites = storedFavorites.filter((favT) => favT.id !== id);
      console.log(`Removed trainer ${id} from favorites.`);
    } else {
      // Si no es favorito, agregarlo
      updatedFavorites = [...storedFavorites, { id, nombre, fotoUrl, especialidad }];
      console.log(`Added trainer ${id} to favorites.`);
    }

    // Actualizar el estado y el localStorage
    localStorage.setItem("favoriteTrainers", JSON.stringify(updatedFavorites));
    setIsFavorite(!isFavorite);
    // e.preventDefault();
    // // Log de información actual del usuario y sus favoritos
    // console.log("User:", user);
    // console.log("Current Favorites:", user.entrenadoresFavoritos);

    // // Clona los entrenadores favoritos actuales
    // let updatedFavorites = [...(user.entrenadoresFavoritos || [])];

    // if (isFavorite) {
    //   // Si ya es favorito, eliminarlo
    //   updatedFavorites = updatedFavorites.filter((favT) => favT.id !== id);
    //   console.log(`Removed trainer  ${id} from favorites.`);
    // } else {
    //   // Si no es favorito, agregarlo
    //   updatedFavorites.push({
    //     id: id,
    //     nombre:nombre,
    //     fotoUrl:fotoUrl,
    //     especialidad: especialidad
    //   });
    //   console.log(`Added trainer ${id} to favorites.`);
    // }

    // console.log("Updated Favorites:", updatedFavorites);

    // // Actualiza el usuario con la nueva lista de favoritos
    // dispatch(
    //   updateUser({
    //     userId: user.id,
    //     userData: {
    //       type: user.type,
    //       entrenadoresFavoritos: updatedFavorites,
    //     },
    //   })
    // )
    //   .then((response) => {
    //     console.log("Update User Response:", response);
    //   })
    //   .catch((error) => {
    //     console.error("Error updating user:", error);
    //   });
  };

  return (
    <div className="col-lg-4 col-md-6 col-sm-12">
      <Link to={`/entrenadores/${id}`}>
        <div className="trainer-item">
          <div className="image-thumb">
            <img src={fotoUrl} alt={nombre} className="trainerItem-image" />
            {membresia && (
              <div
                className={`membership-icon ${membresia.toLowerCase()}`}
                title={`${membresia}`}
              >
                <FontAwesomeIcon icon={membershipIcons[membresia]} size="2x" />
              </div>
            )}
            {user && user.rol === "USER" && (
              <button
                className={`favorite-button ${isFavorite ? "favorite" : ""}`}
                onClick={handleFavoriteClick}
              >
                <FontAwesomeIcon
                  icon={isFavorite ? faSolidHeart : faRegularHeart}
                />
              </button>
            )}
            <div className="trainer-info">
              <h4>
                {nombre}{" "}
                {verificado && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="verified-icon"
                    title="Verificado"
                  />
                )}
              </h4>
            </div>
          </div>
          <div className="down-content">
            <span>{especialidad}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default TrainerItem;
