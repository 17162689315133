import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, addUser } from "../features/UsersSlice"; 
import { fetchTrainers, addTrainer } from "../features/TrainersSlice"; 
import { validarContraseña } from '../utils/validaciones';
import { toast } from 'react-toastify';

const RegistroForm = () => {
  const [vacio, setVacio] = useState(true);
  const [userType, setUserType] = useState("trainer");
  const [acceptTerms, setAcceptTerms] = useState(false); // Estado para manejar el checkbox de términos
  const userInput = useRef(null);
  const emailInput = useRef(null);
  const passInput = useRef(null);
  const passConfInput = useRef(null);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { users, status: userStatus } = useSelector((state) => state.users); 
  const { trainers, status: trainerStatus } = useSelector((state) => state.trainers); 

  const [error, setError] = useState({
    user: "",
    email: "",
    pass: "",
    passConf: "",
    error: "",
  });

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUsers());
    }
    if (trainerStatus === "idle") {
      dispatch(fetchTrainers());
    }
  }, [userStatus, trainerStatus, dispatch]);

  const habBtn = () => {
    // Verifica que los campos no estén vacíos y que los términos estén aceptados
    const camposLlenos =
      userInput.current.value &&
      emailInput.current.value &&
      passInput.current.value &&
      passConfInput.current.value;

    // Solo habilitamos el botón si los campos están completos y se aceptaron los términos
    setVacio(!(camposLlenos && !acceptTerms));
  };

  const validarEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const registrar = async () => {
    try {
      let datoUser = userInput.current.value;
      let datoEmail = emailInput.current.value;
      let datoPass = passInput.current.value;
      let datoPassConf = passConfInput.current.value;

      if (!datoUser) {
        setError({ user: "Ingresar nombre de usuario" });
        throw new Error("Ingresar un nombre de usuario");
      }
      if (!datoEmail || !validarEmail(datoEmail)) {
        setError({ email: "Ingresar correo electrónico válido" });
        throw new Error("Ingresar un correo electrónico válido");
      }

      const passwordErrors = validarContraseña(datoPass);
      if (passwordErrors.length > 0) {
        setError({ pass: passwordErrors.join(" ") });
        throw new Error(passwordErrors.join(" "));
      }

      if (datoPass !== datoPassConf) {
        setError({ passConf: "Las contraseñas no coinciden" });
        throw new Error("Las contraseñas no coinciden");
      }

      const existingUser = users.find(
        (u) => u.name === datoUser || u.email === datoEmail
      );
      const existingTrainer = trainers.find(
        (t) => t.name === datoUser || t.email === datoEmail
      );

      if (existingUser || existingTrainer) {
        throw new Error(
          "El nombre de usuario o correo electrónico ya está registrado"
        );
      }

      const newUser = {
        nombre: datoUser,
        email: datoEmail,
        password: datoPass,
      };

      if (userType === "trainer") {
        await dispatch(addTrainer(newUser)).unwrap();
      } else {
        await dispatch(addUser(newUser)).unwrap();
      }

      toast.success("Registro exitoso! Por favor, inicia sesión.");
      navigate("/login"); // Redirige a la página de inicio de sesión
  
    } catch (msj) {
      setError({ error: msj.message });
      console.error("Error al registrar el usuario:", msj);
    }
  };

  const handleTermsChange = (e) => {
    setAcceptTerms(e.target.checked); // Actualizamos el estado del checkbox
    habBtn(); // Verificamos si los campos están completos al marcar/desmarcar el checkbox
  };

  return (
    <div className="auth-form">
      <div className="user-type-toggle">
        <button
          className={userType === "trainer" ? "active" : ""}
          onClick={() => setUserType("trainer")}
        >
          Soy Entrenador
        </button>
        <button
          className={userType === "user" ? "active" : ""}
          onClick={() => setUserType("user")}
        >
          Soy Usuario
        </button>
      </div>
      <form className="user">
        <div className="form-group">
          <input
            type="text"
            ref={userInput}
            onChange={habBtn}
            className="form-control form-control-user"
            id="registroUsuario"
            aria-describedby="emailHelp"
            placeholder="Ingresar nombre de usuario..."
          />
          <p style={{ color: "red" }}> {error.user} </p>
        </div>
        <div className="form-group">
          <input
            type="email"
            ref={emailInput}
            onChange={habBtn}
            className="form-control form-control-user"
            id="registroEmail"
            placeholder="Correo Electrónico"
          />
          <p style={{ color: "red" }}> {error.email} </p>
        </div>
        <div className="form-group">
          <input
            type="password"
            ref={passInput}
            onChange={habBtn}
            className="form-control form-control-user"
            id="registroPassword"
            placeholder="Contraseña"
          />
          <p style={{ color: "red" }}> {error.pass} </p>
        </div>
        <div className="form-group">
          <input
            type="password"
            ref={passConfInput}
            onChange={habBtn}
            className="form-control form-control-user"
            id="registroPasswordConf"
            placeholder="Confirmar Contraseña"
          />
          <p style={{ color: "red" }}> {error.passConf} </p>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox small">
            {/* Checkbox de términos y condiciones */}
            <input
              type="checkbox"
              className="custom-control-input"
              id="termsCheckbox"
              checked={acceptTerms}
              onChange={handleTermsChange}
            />
            <label className="custom-control-label" htmlFor="termsCheckbox">
              Acepto los <a href="/terms">Términos y Condiciones</a>
            </label>
            <p style={{ color: "red", fontSize: "16px" }}> {error.error} </p>
          </div>
        </div>
        <input
          onClick={registrar}
          type="button"
          value="Registrar"
          id="btnRegistro"
          disabled={vacio} // Botón deshabilitado si los campos están vacíos o no acepta los términos
          className="btn btn-success btn-user btn-block"
        />
        <hr />
        <a
          href="https://accounts.google.com/AccountChooser"
          className="btn btn-google btn-user btn-block"
        >
          <i className="fab fa-google fa-fw"></i> Registrarse con Google
        </a>
      </form>
    </div>
  );
};

export default RegistroForm;
