import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { uploadTrainerVerificationPhoto, updateTrainer } from '../features/TrainersSlice'; 

// Subir la foto de verificación del entrenador
export const uploadVerificationPhoto = createAsyncThunk(
  'verification/uploadVerificationPhoto',
  async ({ trainerId, photoFile }, { dispatch, rejectWithValue }) => {
    try {
      const verificationPhotoUrl = await dispatch(uploadTrainerVerificationPhoto({ trainerId, photoFile })).unwrap();
      return verificationPhotoUrl;
    } catch (error) {
      return rejectWithValue('Error al subir la foto de verificación');
    }
  }
);

export const approveVerification = createAsyncThunk(
  'verification/approveVerification',
  async (trainerId, { dispatch, rejectWithValue }) => {
    try {
      const updatedTrainerData = {
        type: "profesor",
        verificado: true,
        solicitoVerificado: false,
        fotoVerificacion: "",
      };
      const updatedTrainer = await dispatch(updateTrainer({ trainerId, trainerData: updatedTrainerData })).unwrap();
      return updatedTrainer;
    } catch (error) {
      return rejectWithValue('Error al aprobar la verificación');
    }
  }
);

export const rejectVerification = createAsyncThunk(
  'verification/rejectVerification',
  async (trainerId, { dispatch, rejectWithValue }) => {
    try {
      const updatedTrainerData = {
        type: "profesor",
        solicitoVerificado: false,
        fotoVerificacion: "",
      };
      const updatedTrainer = await dispatch(updateTrainer({ trainerId, trainerData: updatedTrainerData })).unwrap();
      return updatedTrainer;
    } catch (error) {
      return rejectWithValue('Error al rechazar la verificación');
    }
  }
);

const verificationSlice = createSlice({
  name: 'verification',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadVerificationPhoto.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadVerificationPhoto.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(uploadVerificationPhoto.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(approveVerification.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(approveVerification.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(approveVerification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(rejectVerification.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(rejectVerification.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(rejectVerification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default verificationSlice.reducer;
