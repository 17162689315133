// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-gradient-dark text-white pt-4 pb-4">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <h5>Contacto</h5>
                    <ul className="list-unstyled">
                        <li><Link to="mailto:treinoapp990@gmail.com">Email: treinoapp990@gmail.com
                        </Link></li>
                        <li>Tel: +123 456 7890</li>
                        <li><Link to="#contact-us">Formulario de contacto</Link></li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h5>Redes Sociales</h5>
                    <ul className="list-unstyled">
                        <li><Link to="https://www.facebook.com/"><i className="fab fa-facebook-f"></i>  Facebook</Link></li>
                        <li><Link to="https://www.twitter.com/"><i className="fab fa-twitter"></i>  Twitter</Link></li>
                        <li><Link to="https://www.instagram.com/"><i className="fab fa-instagram"></i>  Instagram</Link></li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h5>Legal</h5>
                    <ul className="list-unstyled">
                        <li><Link to="/privacidad" >Política de Privacidad</Link></li>
                        <li><Link to="/terminos" >Términos y Condiciones</Link></li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h5>Más</h5>
                    <ul className="list-unstyled">
                        <li><Link to="/Sobre Nosotros" >Sobre Nosotros</Link></li>
                        <li className="scroll-to-section"><Link to="#our-classNamees">Blog</Link></li>
                        <li><Link to="/faq" >FAQs</Link></li>
                    </ul>
                </div>
            </div>
            {/* Copyright Row */}
            <div className="row mt-3">
                <div className="col-12 text-center">
                    <small>© 2024 por Treino App. Todos los derechos reservados.</small>
                </div>
            </div>
        </div>
    </footer>
    );
};

export default Footer;