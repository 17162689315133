import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Collapse, Button } from "reactstrap";
import { useSelector } from "react-redux";
import AdminAlertas from "./AdminAlertas";
import TrainerAlertas from "./TrainerAlertas";
import UserAlertas from "./UserAlertas";

const Alertas = () => {
  const user = useSelector((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);

  // Definir la cantidad de notificaciones según el rol
  const getNotificationCount = () => {
    if (user.rol === "ADMIN") {
      return (
        <AdminAlertas getNotificationCount={true} /> // Calculamos en AdminAlertas
      );
    } else if (user.rol === "TRAINER") {
      return <TrainerAlertas getNotificationCount={true} />; // Calculamos en TrainerAlertas
    } else if (user.rol === "USER") {
      return <UserAlertas getNotificationCount={true} />; // Calculamos en UserAlertas
    }
    return 0;
  };

  const renderContent = () => {
    if (user.rol === "ADMIN") {
      return <AdminAlertas />;
    } else if (user.rol === "TRAINER") {
      return <TrainerAlertas />;
    } else if (user.rol === "USER") {
      return <UserAlertas />;
    }
    return null;
  };

  return (
    <>
      <li className="nav-item dropdown no-arrow mx-1">
        <Button
          className="nav-link dropdown-toggle"
          id="alertsDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={() => setIsOpen(!isOpen)}
          style={{
            border: "none",
            height: "auto",
            top: "40%",
            color: "#e4e4e7",
            background: "transparent",
            fontSize: "larger",
          }}
        >
          <FontAwesomeIcon
            icon={user && user.rol !== "TRAINER" && user.rol !== "ADMIN" ? faHeart : faBell}
          />
          <span className="badge badge-counter badge-danger">
            {getNotificationCount()} {/* Aquí mostramos el número */}
          </span>
        </Button>
        <Collapse
          isOpen={isOpen}
          className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="alertsDropdown"
        >
          <h6 className="dropdown-header">
            {user.rol === "ADMIN" || user.rol === "TRAINER" ? "Notificaciones" : "Entrenadores Favoritos"}
          </h6>
          {renderContent()}
        </Collapse>
      </li>
      <div className="topbar-divider d-none d-sm-block"></div>
    </>
  );
};

export default Alertas;
