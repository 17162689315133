import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal, faCrown, faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTrainer, fetchTrainer } from "../features/TrainersSlice";
import { toast } from "react-toastify";

const MembershipPlans = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trainerId } = useParams();
  const user = useSelector((state) => state.auth.user);

  const isAdmin = user?.rol === "ADMIN";

  const [trainerProfile, setTrainerProfile] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isPendingApproval, setIsPendingApproval] = useState(false);

  useEffect(() => {
    const fetchTrainerProfile = async () => {
      try {
        let profile;
        if (isAdmin) {
          profile = await dispatch(fetchTrainer(trainerId)).unwrap(); // Admin ve cualquier perfil
        } else {
          profile = await dispatch(fetchTrainer(user.id)).unwrap(); // Entrenador ve su propio perfil
        }

        if (profile && Object.keys(profile).length > 0) {
          setTrainerProfile(profile);

          // Verificar si hay una solicitud pendiente
          if (profile.membresiaSolicitada && profile.solicitoPublicacion) {
            setIsPendingApproval(true); // Solicitud pendiente de aprobación
            setSelectedPlan(profile.membresiaSolicitada); // Plan solicitado por el entrenador
          } else {
            setSelectedPlan(profile.membresia); // Plan actual del entrenador
          }
        } else {
          throw new Error("Perfil vacío o no encontrado.");
        }
      } catch (error) {
        console.error("Error al obtener el perfil del entrenador:", error.message);
        toast.error(
          isAdmin
            ? "Perfil de entrenador no encontrado."
            : "Debes crear tu perfil antes de seleccionar un plan de membresía."
        );
        navigate(isAdmin ? -1 : "/crear-perfil", { replace: true });
      }
    };

    fetchTrainerProfile();
  }, [trainerId, user.id, isAdmin, navigate]);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan); // Selecciona el plan para actualizar o solicitar
  };

  const handleAction = async () => {
    if (!trainerProfile || Object.keys(trainerProfile).length === 0) {
      toast.error("El perfil del entrenador no está disponible. Intenta recargar la página.");
      return;
    }

    if (!selectedPlan) {
      toast.error("Por favor, selecciona un plan de membresía antes de continuar.");
      return;
    }

    if (!isAdmin && !trainerProfile.perfilCompleto) {
      toast.error("Debes completar tu perfil antes de seleccionar un plan de membresía.");
      return;
    }

    const updatedTrainer = isAdmin
      ? {
          type: trainerProfile.type,
          membresia: selectedPlan, // Admin actualiza con el plan que selecciona (independientemente de la solicitud)
          solicitoPublicacion: false,
          membresiaSolicitada: null, // Limpia la solicitud al aprobar/rechazar
          visibilidadPerfil: "PUBLIC",
          perfilCompleto: true,
        }
      : {
          type: trainerProfile.type,
          membresiaSolicitada: selectedPlan, // Entrenador solo solicita un plan
          solicitoPublicacion: true,
          perfilCompleto: true,
        };

    try {
      await dispatch(
        updateTrainer({
          trainerId: trainerProfile.id,
          trainerData: updatedTrainer,
        })
      ).unwrap();

      toast.success(
        isAdmin
          ? "Plan de membresía actualizado exitosamente."
          : "Solicitud de publicación enviada."
      );

      navigate(`/trainer-profile/${isAdmin ? trainerProfile.id : user.id}`);
    } catch (error) {
      console.error("Error al actualizar el plan de membresía:", error);
      toast.error("Error al actualizar el plan de membresía. Por favor, inténtalo de nuevo.");
    }
  };

  if (!trainerProfile) {
    return <div>Perfil no encontrado. Redireccionando...</div>;
  }

  const renderPlanCard = (plan, icon, title, price, features) => (
    <div
      className={`plan-card ${plan.toLowerCase()} ${selectedPlan === plan ? `selected-${plan.toLowerCase()}` : ""}`}
    >
      {trainerProfile.membresia === plan && <p className="current-plan h4 font-weight-bold text-gray-100">Plan Actual</p>}
      {trainerProfile.membresiaSolicitada === plan && isPendingApproval && (
        <p className="pending-approval h4 font-weight-bold text-gray-100">Pendiente de Aprobación</p>
      )}
      <FontAwesomeIcon icon={icon} className="icon" />
      <h3>{title}</h3>
      <p className="price">{price}</p>
      <ul className="features">{features.map((feature, index) => <li key={index}>{feature}</li>)}</ul>
      {(!isPendingApproval || isAdmin) && (
        <button className="select-button" onClick={() => handleSelectPlan(plan)}>
          Seleccionar
        </button>
      )}
    </div>
  );

  return (
    <div className="membership-plans-page">
      <div className="membership-plans">
        <button className="fa-solid fa-arrow-left back-button" onClick={() => navigate(-1)}></button>
        <h2>Elegir Plan</h2>
        <div className="plans-container">
          {renderPlanCard("BASIC", faMedal, "Básico", "$0/mes", [
            "Acceso a todas las clases grupales",
            "Uso de las instalaciones básicas",
            "Asistencia de entrenadores",
          ])}
          {renderPlanCard("PREMIUM", faStar, "Premium", "$20/mes", [
            "Acceso a todas las clases grupales",
            "Uso de todas las instalaciones",
            "Sesiones de entrenamiento personal",
            "Descuentos en eventos especiales",
          ])}
          {renderPlanCard("ELITE", faCrown, "Elite", "$40/mes", [
            "Acceso VIP a todas las clases",
            "Uso ilimitado de instalaciones premium",
            "Entrenamiento personal ilimitado",
            "Acceso a eventos exclusivos",
          ])}
        </div>
        {/* Botón de acción solo para administrador o si no hay solicitud pendiente */}
        {(isAdmin || !isPendingApproval) && (
          <button className="publish-button" onClick={handleAction}>
            {isAdmin ? "Actualizar Plan" : "Enviar Solicitud de Publicación"}
          </button>
        )}
      </div>
    </div>
  );
};

export default MembershipPlans;
