import React from 'react';

const Services = ({ formData, editable, handleChange }) => {
  const servicios = Array.isArray(formData.servicios) ? formData.servicios : [];

  return (
    <div className="services-section">
      <h3>Servicios Ofrecidos</h3>
      {editable ? (
        <textarea
          name="servicios"
          value={formData.servicios}
          onChange={handleChange}
          className="edit-textarea"
        />
      ) : (
        <p>
          {formData.servicios}
        </p>
      )}
    </div>
  );
};

export default Services;
