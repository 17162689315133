import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "./Modal";

const ImagesSection = ({ trainer, editable, onMediaUpload }) => {
  const [media, setMedia] = useState(Array.isArray(trainer.media) ? trainer.media : []);
  const [showModal, setShowModal] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  useEffect(() => {
    setMedia(Array.isArray(trainer.media) ? trainer.media : []);
  }, [trainer.media]);

  const onDrop = (acceptedFiles) => {
    onMediaUpload(acceptedFiles); // Llamada al padre para manejar la subida de archivos
    const newMedia = acceptedFiles.map((file) => ({
      type: file.type.startsWith("image") ? "image" : "video",
      url: URL.createObjectURL(file),
      file: file,
    }));
    setMedia([...media, ...newMedia]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "video/mp4": []
    },
    onDrop,
    multiple: true,
  });

  const handleDelete = (index) => {
    const newMedia = media.filter((_, i) => i !== index);
    setMedia(newMedia);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(media);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setMedia(items);
  };

  const openModal = (index) => {
    setCurrentMediaIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handlePrev = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex === 0 ? media.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex === media.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="images-section">
      {editable ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="media">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="media-container">
                {media.map((item, index) => (
                  <Draggable key={index} draggableId={`media-${index}`} index={index}>
                    {(provided) => (
                      <div
                        className="media-item"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        onClick={() => openModal(index)}
                      >
                        {item.type === "image" ? (
                          <img src={item.url} alt={`media-${index}`} className="media-image" />
                        ) : (
                          <video controls muted className="media-video">
                            <source src={item.url} type="video/mp4" />
                            Tu navegador no soporta la etiqueta de video.
                          </video>
                        )}
                        <button className="delete-button" onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(index);
                        }}>
                          X
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="media-container">
          {media.map((item, index) => (
            <div key={index} className="media-item" onClick={() => openModal(index)}>
              {item.type === "image" ? (
                <img src={item.url} alt={`media-${index}`} className="media-image" />
              ) : (
                <video controls muted className="media-video">
                  <source src={item.url} type="video/mp4" />
                  Tu navegador no soporta la etiqueta de video.
                </video>
              )}
            </div>
          ))}
        </div>
      )}
      {editable && (
        <>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Arrastra y suelta tus archivos aquí, o haz clic para seleccionar archivos</p>
          </div>
        </>
      )}
      <Modal
        show={showModal}
        onClose={closeModal}
        content={media[currentMediaIndex]}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </div>
  );
};

export default ImagesSection;
