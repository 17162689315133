import React from 'react'
import BarraSuperior from './BarraSuperior'
import ContenidoInicio from './ContenidoInicio'

const Contenido = ({ user, context, toggleSidebar}) => {
    return (
        <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
                <BarraSuperior user={user} context={context} toggleSidebar={toggleSidebar}/>
                <ContenidoInicio />
            </div>

        </div>
    )
}

export default Contenido