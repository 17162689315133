// src/features/viewsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Simulamos los datos para el ejemplo
const fetchPlatformViewsAPI = async () => {
  return 1000; // número de visitas a la plataforma
};

const fetchProfileViewsAPI = async (trainerId) => {
  return 200; // número de visitas al perfil del entrenador
};

export const fetchPlatformViews = createAsyncThunk('views/fetchPlatformViews', async () => {
  const response = await fetchPlatformViewsAPI();
  return response;
});

export const fetchProfileViews = createAsyncThunk('views/fetchProfileViews', async (trainerId) => {
  const response = await fetchProfileViewsAPI(trainerId);
  return response;
});

const viewsSlice = createSlice({
  name: 'views',
  initialState: {
    platformViews: 0,
    profileViews: {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatformViews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlatformViews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.platformViews = action.payload;
      })
      .addCase(fetchPlatformViews.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchProfileViews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfileViews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.profileViews[action.meta.arg] = action.payload;
      })
      .addCase(fetchProfileViews.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default viewsSlice.reducer;
