import React from 'react';
import { Collapse, Button } from "reactstrap";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../features/authSlice';

const InformacionUsuario = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const cerrarSesion = () => {
    dispatch(logout());
    navigate("/");
  }

  const handleProfileClick = () => {
    if (user.rol === 'TRAINER') {
      if (user.perfilCompleto) {
        navigate(`/trainer-profile/${user.id}`);
      } else {
        navigate('/crear-perfil');
      }
    } else if (user.rol === 'ADMIN') {
      navigate(`/admin-profile/${user.id}`);
    } else {
      navigate(`/user-profile/${user.id}`);
    }
  }

  const handleActivityClick = () => {
    navigate('/user-activity');
  }

  const handleAnalysisClick = () => {
    navigate('/analytics');
  }

  const handleAdminDashboardClick = () => {
    navigate('/dashboard');
  }

  const handleMembershipClick = () => {
    if (user.rol === 'ADMIN') {
      navigate('/membership-management'); // Redirige al componente de gestión de membresías del admin
    } else if (user.rol === 'TRAINER') {
      navigate(`/membership-plans/${user.id}`); // Redirige a la página de planes de membresía para entrenadores
    }
  }

  const handleSettingsClick = () => {
    navigate('/settings');
  }

  return (
    <li className="nav-item dropdown no-arrow">
      <Button className="nav-link dropdown-toggle" id="userDropdown" role="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => {
          setIsOpen(!isOpen);
        }} style={{ background: 'transparent', border: 'none' }}>
        <span className="mr-2 d-none d-lg-inline text-white-600 small">{user.nombre || 'Usuario'}</span>
        <div className="fa-solid fa-circle-user" style={{fontSize:'25px'}}></div>
      </Button>
      <Collapse isOpen={isOpen} className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="userDropdown">
        <a className="dropdown-item" onClick={handleProfileClick}>
          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
          {user.rol === 'TRAINER' && !user.perfilCompleto ? 'Crear Perfil' : 'Perfil'}
        </a>
        <a className="dropdown-item" onClick={handleSettingsClick}>
          <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
          Ajustes
        </a>
        {user.rol === 'USER' && (
          <a className="dropdown-item" onClick={handleActivityClick}>
            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
            Registro de actividades
          </a>
        )}
        {user.rol === 'TRAINER' && (
          <>
            <a className="dropdown-item" onClick={handleAnalysisClick}>
              <i className="fas fa-chart-line fa-sm fa-fw mr-2 text-gray-400"></i>
              Análisis y Estadísticas
            </a>
            <a className="dropdown-item" onClick={handleMembershipClick}>
              <i className="fas fa-id-card fa-sm fa-fw mr-2 text-gray-400"></i>
              Publicar Perfil
            </a>
          </>
        )}
        {user.rol === 'ADMIN' && (
          <>
            <a className="dropdown-item" onClick={handleAdminDashboardClick}>
              <i className="fas fa-tachometer-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Admin Dashboard
            </a>
            <a className="dropdown-item" onClick={handleAnalysisClick}>
              <i className="fas fa-chart-line fa-sm fa-fw mr-2 text-gray-400"></i>
              Análisis y Estadísticas
            </a>
            <a className="dropdown-item" onClick={handleMembershipClick}>
              <i className="fas fa-id-card fa-sm fa-fw mr-2 text-gray-400"></i>
              Gestión de Membresías
            </a>
          </>
        )}
        <div className="dropdown-divider"></div>
        <a className="dropdown-item" onClick={cerrarSesion}>
          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Cerrar sesión
        </a>
      </Collapse>
    </li>
  );
}

export default InformacionUsuario;
