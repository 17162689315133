import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadVerificationPhoto,
  approveVerification,
  rejectVerification,
} from "../features/VerificationSlice";
import { fetchTrainers } from "../features/TrainersSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const VerificationModal = ({ isOpen, toggle, trainerId, isAdmin }) => {
  const dispatch = useDispatch();
  const trainers = useSelector((state) => state.trainers.trainers);
  const [verificationPhoto, setVerificationPhoto] = useState(null);
  const [photoFile, setPhotoFile] = useState(null); // Almacena el archivo seleccionado
  const [trainer, setTrainer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (trainerId && trainers.length > 0) {
      const selectedTrainer = trainers.find((t) => t.id === trainerId);
      setTrainer(selectedTrainer);
      if (selectedTrainer && isAdmin) {
        setVerificationPhoto(selectedTrainer.fotoVerificacion);
      }
    }
  }, [trainerId, trainers, isAdmin]);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPhotoFile(file); // Guardamos el archivo seleccionado
    const reader = new FileReader();
    reader.onloadend = () => {
      setVerificationPhoto(reader.result); // Esto es solo para mostrar la vista previa
    };
    if (file) {
      reader.readAsDataURL(file); // Lee el archivo y actualiza la vista previa
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!photoFile) {
      toast.error("Por favor, sube una foto para la verificación.");
      return;
    }

    try {
      await dispatch(
        uploadVerificationPhoto({ trainerId, photoFile }) // Usamos el archivo real para la subida
      ).unwrap();
      toast.success("Perfil enviado para verificación.");
      toggle();
      dispatch(fetchTrainers()); // Forzar actualización
    } catch (error) {
      toast.error("Ocurrió un error al enviar la verificación.");
    }
  };

  const handleApprove = async () => {
    try {
      await dispatch(approveVerification(trainerId)).unwrap();
      toast.success("Perfil verificado exitosamente.");
      toggle();
      dispatch(fetchTrainers()); // Forzar actualización
    } catch (error) {
      toast.error("Ocurrió un error al aprobar la verificación.");
    }
  };

  const handleReject = async () => {
    try {
      await dispatch(rejectVerification(trainerId)).unwrap();
      toast.error("Solicitud de verificación rechazada.");
      toggle();
      dispatch(fetchTrainers()); // Forzar actualización
    } catch (error) {
      toast.error("Ocurrió un error al rechazar la verificación.");
    }
  };

  const handleProfileClick = () => {
    if (trainer) {
      navigate(`/entrenadores/${trainer.id}`);
      toggle(); // Cierra el modal al navegar al perfil
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="verification-modal">
      <ModalHeader toggle={toggle}>
        {isAdmin
          ? "Revisión de Verificación de Perfil"
          : "Verificación de Perfil"}
      </ModalHeader>
      <ModalBody>
        {isAdmin ? (
          <div className="verification-content">
            <h4
              onClick={handleProfileClick}
              style={{ cursor: "pointer", color: "white" }}
            >
              {trainer?.nombre}
            </h4>
            <div className="verification-photos">
              <div>
                <h5>Foto de Perfil</h5>
                <img
                  src={trainer?.fotoUrl || "https://via.placeholder.com/100"}
                  alt="Profile"
                />
              </div>
              <div>
                <h5>Foto de Verificación</h5>
                <img
                  src={
                    verificationPhoto ||
                    trainer?.fotoVerificacion ||
                    "https://via.placeholder.com/100"
                  }
                  alt="Verification"
                />
              </div>
            </div>
          </div>
        ) : trainer?.solicitoVerificado ? (
          <div className="verification-content">
            <h2>Verificación pendiente</h2>
            <p>Tu solicitud de verificación está en proceso.</p>
            <div>
              <h5>Foto enviada:</h5>
              <img
                src={verificationPhoto || trainer?.fotoVerificacion}
                alt="Verification Photo"
                className="verification-preview"
              />
            </div>
            <p>
              Un administrador revisará tu solicitud y te informará cuando haya
              una decisión.
            </p>
          </div>
        ) : (
          <div className="verification-content">
            <h2>Verificación de Perfil</h2>
            <p>
              Para verificar tu perfil, por favor sube una foto en tiempo real
              siguiendo estas instrucciones:
            </p>
            <ul>
              <li>Asegúrate de que tu cara esté claramente visible.</li>
              <li>
                Haz un gesto específico como levantar una mano o hacer una señal
                con los dedos.
              </li>
            </ul>
            <form onSubmit={handleSubmit}>
              <input
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
              />
              {verificationPhoto && (
                <img
                  src={verificationPhoto}
                  alt="Verification Preview"
                  className="verification-preview"
                />
              )}
              <Button type="submit" className="submit-button">
                Enviar para Verificación
              </Button>
            </form>
          </div>
        )}
      </ModalBody>
      {isAdmin && (
        <ModalFooter>
          <Button color="success" onClick={handleApprove}>
            Aprobar
          </Button>
          <Button color="danger" onClick={handleReject}>
            Rechazar
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default VerificationModal;
