import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { adminDeleteComment, adminUpdateComment } from "../features/AdminSlice";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

const ListadoReseñas = () => {
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.comments.comments);
  const trainers = useSelector((state) => state.trainers.trainers);
  const users = useSelector((state) => state.users.users);
  const [filteredComments, setFilteredComments] = useState(comments);
  const [filterInput, setFilterInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [newText, setNewText] = useState("");

  useEffect(() => {
    setFilteredComments(comments);
  }, [comments]);

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterInput(value);
    setFilteredComments(
      comments.filter(
        (comment) =>
          users
            .find((u) => u.id === comment.usuarioId)
            ?.nombre.toLowerCase()
            .includes(value) ||
          trainers
            .find((t) => t.id === comment.profesorId)
            ?.nombre.toLowerCase()
            .includes(value)
      )
    );
  };

  const handleDelete = (trainerId, commentId) => {
    confirmAlert({
      title: "Confirmar eliminación",
      message: "¿Estás seguro de que deseas eliminar esta reseña?",
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            setLoading(true);
            try {
              await dispatch(adminDeleteComment({ trainerId, commentId }));
              toast.success("Reseña eliminada con éxito");
            } catch (error) {
              console.error("Error al eliminar la reseña:", error);
              toast.error("Ocurrió un error al eliminar la reseña");
            } finally {
              setLoading(false);
            }
          },
        },
        {
          label: "No",
          onClick: () => toast.info("Eliminación cancelada"),
        },
      ],
    });
  };

  const openModal = (comment) => {
    setSelectedComment(comment);
    setNewText(comment.texto);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedComment(null);
  };

  const handleSaveEdit = async () => {
    if (newText.trim().length >= 5) {
      setLoading(true);
      try {
        await dispatch(
          adminUpdateComment({
            commentId: selectedComment.id,
            updatedData: { texto: newText },
          })
        );
        toast.success("Reseña actualizada con éxito");
        closeModal();
      } catch (error) {
        console.error("Error al actualizar la reseña:", error);
        toast.error("Ocurrió un error al actualizar la reseña");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("El comentario debe tener al menos 5 caracteres.");
    }
  };

  return (
    <div className="col-xl-12 col-lg-12 overflow-hidden" id="Tabla">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-success">
            Listado de Reseñas:
          </h6>
          <input
            type="text"
            placeholder="Buscar reseñas..."
            className="form-control"
            value={filterInput}
            onChange={handleFilter}
            disabled={loading}
          />
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-dark table-bordered">
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th>Entrenador</th>
                  <th>Comentario</th>
                  <th>Estrellas</th>
                  <th>Fecha</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredComments.map((comment) => (
                  <tr key={comment.id}>
                    <td>
                      {users.find((u) => u.id === comment.usuarioId)?.nombre ||
                        "Usuario no encontrado"}
                    </td>
                    <td>
                      {trainers.find((t) => t.id === comment.profesorId)
                        ?.nombre || "Entrenador no encontrado"}
                    </td>
                    <td>{comment.texto}</td>
                    <td>{comment.rating}</td>
                    <td>{new Date(comment.createAt).toLocaleDateString()}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => openModal(comment)}
                        disabled={loading}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          handleDelete(comment.profesorId, comment.id)
                        }
                        disabled={loading}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loading && <div className="text-center">Procesando...</div>}
          </div>
        </div>
      </div>

      {/* Modal para Editar Reseña */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Editar Reseña"
        ariaHideApp={false}
        className="custom-modal-content"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">
          <h2>Editar Reseña</h2>
          <button onClick={closeModal} className="custom-modal-close">
            &times;
          </button>
        </div>
        <div className="custom-modal-body">
          <textarea
            className="form-control"
            value={newText}
            onChange={(e) => setNewText(e.target.value)}
            rows="5"
          />
        </div>
        <div className="custom-modal-footer">
          <button className="cancel" onClick={closeModal}>
            Cancelar
          </button>
          <button className="btn-save" onClick={handleSaveEdit}>
            Guardar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ListadoReseñas;
