import React from 'react'
import ResetPassword from './ResetPassword'
import {Link} from 'react-router-dom';

const ForgetPassword = () => {
  return (
    <div className="container">

    <div className="card o-hidden border-0 shadow-lg my-5">
        <div className="card-body p-0">
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <div className="p-5">
                        <div className="text-center">
                            <h1 className="h4 text-gray-100 mb-4">Se te olvidó tu contraseña?</h1>
                        </div>
                        <ResetPassword/>
                        <hr/>
                        <div className="text-center">
                        <Link className="small" to="/registro">Crear una Cuenta!</Link>
                        </div>
                        <div className="text-center">
                            <Link className="small" to="/login">Ya tienes una cuenta? Ingresar!</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
)
}

export default ForgetPassword