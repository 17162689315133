import React from "react";

const Encabezado = () => {
  return (
    /* <!-- Encabezado --> */
    <div className="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 className="h3 mb-0 text-gray-100">Dashboard</h1>
      <a
        href="#"
        className="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
      >
        <i className="fas fa-download fa-sm text-white-50"></i> Generar Reporte
      </a>
    </div>
  );
};

export default Encabezado;
