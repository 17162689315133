import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, deleteUserAccount } from "../features/UsersSlice";
import { updateTrainer, deleteTrainerAccount } from "../features/TrainersSlice";
import { updateUserState, logout } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { validarContraseña } from "../utils/validaciones";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [profileVisibility, setProfileVisibility] = useState(
    user?.visibilidadPerfil
  );

  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  useEffect(() => {
    if (user?.visibilidadPerfil) {
      setProfileVisibility(user.visibilidadPerfil);
    }
  }, [user]);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleProfileVisibilityChange = async (e) => {
    const { value } = e.target;
    setProfileVisibility(value);

    try {
      const updatedTrainer = await dispatch(
        updateTrainer({
          trainerId: user.id,
          trainerData: {
            type: user.type,
            visibilidadPerfil: value,
          },
        })
      ).unwrap();
      dispatch(updateUserState(updatedTrainer));
      toast.success("Visibilidad del perfil actualizada correctamente");
    } catch (error) {
      console.error("Error al actualizar la visibilidad del perfil:", error);
      toast.error("Hubo un error al actualizar la visibilidad del perfil.");
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (
      !passwordData.currentPassword ||
      !passwordData.newPassword ||
      !passwordData.confirmNewPassword
    ) {
      toast.warn("Por favor, complete todos los campos de la contraseña.");
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      toast.warn("La nueva contraseña y la confirmación no coinciden.");
      return;
    }

    if (passwordData.currentPassword !== user.password) {
      toast.error("La contraseña actual es incorrecta.");
      return;
    }

    const erroresDeValidacion = validarContraseña(passwordData.newPassword);
    if (erroresDeValidacion.length > 0) {
      erroresDeValidacion.forEach((error) => toast.error(error));
      return;
    }

    if (passwordData.currentPassword === passwordData.newPassword) {
      toast.error("La nueva contraseña no puede ser igual a la actual.");
      return;
    }

    try {
      let response;
      if (user.rol === "TRAINER") {
        response = await dispatch(
          updateTrainer({
            trainerId: user.id,
            trainerData: {
              type: user.type,
              password: passwordData.newPassword,
            },
          })
        ).unwrap();
      } else {
        response = await dispatch(
          updateUser({
            userId: user.id,
            userData: {
              type: user.type,
              password: passwordData.newPassword,
            },
          })
        ).unwrap();
      }

      const updatedUser = response?.updatedUser || response;

      if (updatedUser) {
        dispatch(updateUserState(updatedUser)); // Actualiza el authSlice
        toast.success("Contraseña actualizada correctamente");

        // Limpia el estado y cierra el pop-up
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        setShowPasswordPopup(false);
        document
          .querySelector(".settings-page-container")
          .classList.remove("blurred-background");
      } else {
        throw new Error("No se pudo actualizar la contraseña.");
      }
    } catch (error) {
      console.error("Error al actualizar la contraseña:", error);
      toast.error(
        "Hubo un error al actualizar la contraseña. Por favor, intente de nuevo."
      );
    }
  };

  const handleDeleteAccount = async () => {
    confirmAlert({
      title: "Confirmación de eliminación",
      message:
        "¿Estás seguro de que deseas eliminar tu cuenta? Esta acción no se puede deshacer.",
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              if (user.rol === "TRAINER") {
                await dispatch(deleteTrainerAccount(user.id));
                dispatch(logout());
              } else {
                await dispatch(deleteUserAccount(user.id));
                dispatch(logout());
              }
              toast.success("Tu cuenta ha sido eliminada correctamente.");
              navigate("/");
            } catch (error) {
              console.error("Error al eliminar la cuenta:", error);
              toast.error("Hubo un error al eliminar la cuenta.");
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.info("Eliminación de cuenta cancelada.");
          },
        },
      ],
    });
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const renderTrainerSettings = () => (
    <>
      <div className="settings-form-group">
        {user.perfilCompleto && (
          <div>
            <h3>Privacidad</h3>
            <label htmlFor="profileVisibility">Visibilidad del Perfil:</label>
            <select
              id="profileVisibility"
              name="profileVisibility"
              value={profileVisibility}
              onChange={handleProfileVisibilityChange}
              className="custom-select"
            >
              <option value="PUBLIC">Público</option>
              <option value="PRIVATE">Oculto</option>
            </select>
          </div>
        )}
      </div>
      <div className="settings-form-group">
        <h3>Análisis y Estadísticas</h3>
        <button
          type="button"
          className="settings-analysis-button"
          onClick={() => navigate("/Analytics")}
        >
          Ver Análisis
        </button>
      </div>
    </>
  );

  return (
    <div className="settings-page-container">
      <div className="settings-page">
        <div className="settings">
          <button
            className="settings-back-button fa-solid fa-arrow-left back-button"
            onClick={() => navigate(-1)}
          ></button>
          <h2>Ajustes</h2>

          {user.rol === "TRAINER" && renderTrainerSettings()}
          {user.rol === "ADMIN" && (
            <div className="settings-form-group">
              <h3>Admin Dashboard</h3>
              <button
                type="button"
                className="settings-dashboard-button"
                onClick={() => navigate("/dashboard")}
              >
                Ir al Dashboard
              </button>
            </div>
          )}

          <div className="settings-form-group">
            <h3>Seguridad de la Cuenta</h3>
            <div className="settings-form-group">
              <button
                type="button"
                className="settings-change-password-button"
                onClick={() => {
                  setShowPasswordPopup(true);
                  document
                    .querySelector(".settings-page-container")
                    .classList.add("blurred-background");
                }}
              >
                Cambiar Contraseña
              </button>
            </div>
          </div>

          <div className="settings-form-group">
            <h3>Gestión de Cuenta</h3>
            <div className="settings-form-group">
              <button
                type="button"
                className="settings-logout-button"
                onClick={handleLogout}
              >
                Cerrar Sesión
              </button>
              <img
                src="https://elasticbeanstalk-us-east-2-047719631084.s3.us-east-2.amazonaws.com/Captura_de_pantalla_2024-08-30_232708-removebg+(1).png"
                className="logo-settings"
              />
              <p className="version-settings">
                Versión <strong> BETA</strong>
              </p>
              <button
                type="button"
                className="settings-delete-button"
                onClick={handleDeleteAccount}
              >
                Eliminar Cuenta
              </button>
            </div>
          </div>
        </div>

        {showPasswordPopup && (
          <div className="password-popup">
            <div className="password-popup-content">
              <h3 className="text-gray-100">Cambiar Contraseña</h3>
              <form onSubmit={handlePasswordSubmit}>
                <div className="settings-form-group">
                  <label>Contraseña Actual:</label>
                  <input
                    type="password"
                    name="currentPassword"
                    value={passwordData.currentPassword}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="settings-form-group">
                  <label>Nueva Contraseña:</label>
                  <input
                    type="password"
                    name="newPassword"
                    value={passwordData.newPassword}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="settings-form-group">
                  <label>Confirmar Nueva Contraseña:</label>
                  <input
                    type="password"
                    name="confirmNewPassword"
                    value={passwordData.confirmNewPassword}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="settings-form-group">
                  <button type="submit" className="settings-save-button">
                    Guardar Cambios
                  </button>
                </div>
              </form>
              <button
                type="button"
                className="settings-close-popup-button"
                onClick={() => {
                  // Limpia los campos del formulario al cerrar el pop-up
                  setPasswordData({
                    currentPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                  });
                  setShowPasswordPopup(false);
                  document
                    .querySelector(".settings-page-container")
                    .classList.remove("blurred-background");
                }}
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
