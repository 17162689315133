import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCoins, faGauge} from '@fortawesome/free-solid-svg-icons'

const BarraLateralMarca = () => {
    return (
        <div>
            {/* <!-- Barra lateral - Marca --> */}
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard">
                <div className="sidebar-brand-icon rotate-n-15">
                    <FontAwesomeIcon icon={faCoins} />
                </div>
                <div className="sidebar-brand-text mx-3"> Admin</div>
            </a>

            {/* <!-- Barra divisora --> */}
            <hr className="sidebar-divider my-0" />

            {/* <!-- Nav Item - Tablero --> */}
            <li className="nav-item active">
                <a className="nav-link">
                <FontAwesomeIcon icon={faGauge} style={{marginRight:'10px'}} />
                    <span>Tablero</span></a>
            </li>

            {/* <!-- Barra divisora --> */}
            <hr className="sidebar-divider" />
        </div>
    )
}

export default BarraLateralMarca