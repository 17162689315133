import React from 'react'
// import CrearTransaccion from './CrearTransaccion'
// import GraficoMontosComprados from './GraficoMontosComprados'
// import GraficoMontosVendidos from './GraficoMontosVendidos'
// import GraficoUnaMoneda from './GraficoUnaMoneda'
// import ListadoTransacciones from './ListadoTransacciones'
// import RecomendacionesIA from './RecomendacionesIA'
import ListadoUsuarios from './ListadoUsuarios'
import ListadoEntrenadores from './ListadoEntrenadores'
import ListadoReseñas from './ListadoReseñas'

const Requerimientos = () => {

    //Use state Transacciones 
    return (
        <div className="row">
            {/* <!-- Gráfico de área--> */}
            <ListadoEntrenadores />
            <ListadoUsuarios />
            <ListadoReseñas />
            {/* <ListadoTransacciones />
            <RecomendacionesIA />
            <CrearTransaccion />
            <GraficoUnaMoneda />
            <GraficoMontosComprados />
            <GraficoMontosVendidos /> */}
        </div>
    )
}

export default Requerimientos