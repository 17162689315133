import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login as loginService } from '../services/loginService';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await loginService(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al iniciar sesión');
    }
  }
);

const initialState = {
  isAuthenticated: !!localStorage.getItem('apiKey'),
  user: null,
  error: null,
};

const userFromLocalStorage = localStorage.getItem('user');
if (userFromLocalStorage) {
  try {
    initialState.user = JSON.parse(userFromLocalStorage);
  } catch (error) {
    console.error('Error parsing user from localStorage', error);
    localStorage.removeItem('user');
    initialState.user = null;
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
      localStorage.removeItem('user');
      localStorage.removeItem('apiKey');
    },
    updateUserState: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.error = null;
        localStorage.setItem('user', JSON.stringify(action.payload));
        localStorage.setItem('apiKey', 'mockApiKey');
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.error = action.payload;
      });
  },
});

export const { logout, updateUserState } = authSlice.actions;
export default authSlice.reducer;
