import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {fetchTrainer } from "../features/TrainersSlice";
import { fetchTrainerReviews } from "../features/CommentsSlice";
import avatarImg from "../assets/img/avatar.png";

const TrainerAlertas = ({ getNotificationCount = false }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [newComments, setNewComments] = useState([]);

  useEffect(() => {
    fetchTrainerData();
  }, []);

  const fetchTrainerData = async () => {
    try {
      const trainerData = await dispatch(fetchTrainer(user.id)).unwrap();
      const trainerComments = await dispatch(fetchTrainerReviews(user.id)).unwrap();
      setNewComments(trainerComments);
    } catch (error) {
      console.error("Error fetching trainer data:", error);
    }
  };

  if (getNotificationCount) {
    return newComments.length;
  }

  return (
    <>
      {newComments.length > 0 ? (
        newComments.slice(0, 3).map((comment) => (
          <div className="dropdown-item d-flex align-items-center" key={comment.id}>
            <div className="mr-3">
              <div className="icon-circle bg-success">
                <img
                  src={avatarImg}
                  alt={comment.userName}
                  className="img-profile rounded-circle"
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
            <div style={{ height: "70px" }}>
              <div className="font-weight-bold" style={{ height: "20px", marginTop: "5px" }}>
                {comment.userName}
              </div>
              <span className="small text-gray-500">{comment.rating}</span>
              <span className="small text-gray-500"> {comment.texto}</span>
            </div>
          </div>
        ))
      ) : (
        <div className="dropdown-item text-center small text-gray-500">No tienes nuevas reseñas</div>
      )}
    </>
  );
};

export default TrainerAlertas;
