// src/components/ResetPassword.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../features/UsersSlice";
import { updateTrainer } from "../features/TrainersSlice";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [userType, setUserType] = useState("user"); // Inicialmente usuario
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const trainers = useSelector((state) => state.trainers.trainers);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    let userToUpdate = null;

    // Buscar si es un entrenador o un usuario
    if (userType === "trainer") {
      userToUpdate = trainers.find(
        (trainer) =>
          trainer.email === emailOrUsername ||
          trainer.nombre === emailOrUsername
      );
    } else {
      userToUpdate = users.find(
        (user) =>
          user.email === emailOrUsername || user.nombre === emailOrUsername
      );
    }

    if (!userToUpdate) {
      toast.error("Usuario no encontrado.");
      return;
    }

    try {
      // Actualizar la contraseña según el tipo de usuario seleccionado
      if (userType === "trainer") {
        await dispatch(
          updateTrainer({
            trainerId: userToUpdate.id,
            trainerData: { type: "profesor",password: newPassword },
          })
        ).unwrap();
      } else {
        await dispatch(
          updateUser({
            userId: userToUpdate.id,
            userData: { type: "usuario", password: newPassword },
          })
        ).unwrap();
      }

      toast.success("Contraseña restablecida con éxito.");
    } catch (error) {
      toast.error("Error al restablecer la contraseña.");
      console.error("Error al restablecer la contraseña:", error);
    } finally {
      setEmailOrUsername("");
      setNewPassword("");
    }
  };

  return (
    <div className="auth-form">
      <div className="user-type-toggle">
        <button
          className={userType === "trainer" ? "active" : ""}
          onClick={() => setUserType("trainer")}
        >
          Soy Entrenador
        </button>
        <button
          className={userType === "user" ? "active" : ""}
          onClick={() => setUserType("user")}
        >
          Soy Usuario
        </button>
      </div>
      <form onSubmit={handleResetPassword} className="user">
        <div className="form-group">
          <input
            type="text"
            placeholder="Correo o nombre de usuario"
            value={emailOrUsername}
            onChange={(e) => setEmailOrUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Nueva contraseña"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-success btn-user btn-block">
          Restablecer Contraseña
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
