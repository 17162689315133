// src/features/analyticsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAnalytics = createAsyncThunk(
  'analytics/fetchAnalytics',
  async ({ role, trainerId }) => {
    // Simular fetch de datos basado en el rol y trainerId (si aplica)
    const response = await new Promise((resolve) =>
      setTimeout(() => resolve({
        publishedProfiles: 10,
        pendingProfiles: 5,
        activePromotions: 2,
        weeklyVisits: 300,
        visits: {
          last24Hours: 50,
          last7Days: 200,
          last30Days: 800,
        },
        uniqueVisits: {
          last24Hours: 40,
          last7Days: 180,
          last30Days: 700,
        },
        devices: {
          mobile: 500,
          desktop: 300,
        },
      }), 1000)
    );
    return response;
  }
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalytics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchAnalytics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default analyticsSlice.reducer;
