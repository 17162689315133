import avatarImg from "../assets/img/avatar.png";
import { v4 as uuidv4 } from 'uuid';
import firstTrainer from "../assets/img/first-trainer.jpg";
import secondTrainer from "../assets/img/second-trainer.jpg";
import thirdTrainer from "../assets/img/third-trainer.jpg";
import gymvideo from "../assets/img/gym-video.mp4";
import trainingImage01 from "../assets/img/body-builder-training-in-industrial-urban-gym-royalty-free-image-1593693142.jpg";
import trainingImage02 from "../assets/img/training-image-02.jpg";

// lista de imágenes locales
export const localImages = [
  firstTrainer,
  secondTrainer,
  thirdTrainer,
];

// Lista de medios locales (imágenes y videos)
export const localMedia = [
  { type: "image", url: firstTrainer },
  { type: "image", url: secondTrainer },
  { type: "image", url: thirdTrainer },
  { type: "image", url: trainingImage01 },
  { type: "image", url: trainingImage02 },
  { type: "video", url: gymvideo },
  { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { type: "image", url: "https://via.placeholder.com/1920x1080" },
  { type: "image", url: "https://via.placeholder.com/800" },
];


export const initialTrainers = [
    {
      id: uuidv4(),
      name: "Roberto Perez",
      email: "roberto@example.com",
      password: "trainer123!",
      phone: "123456789",
      speciality: "Fitness y Acondicionamiento Físico",
      location: {
        city: "Montevideo",
        latitude: -34.9011,
        longitude: -56.1645,
        mapLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423286.27404299776!2d-56.33349947570704!3d-34.85135419557574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a02b8e4c6c56e3%3A0x63b412cbd94f54e1!2sMontevideo%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035319198!5m2!1sen!2sie",
      },
      price: 50,
      verified: true,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: firstTrainer,
      description: "Bitters cliche tattooed 8-bit distillery mustache...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: ["$50/h - Entrenamiento Personal", "$200 - Paquete Mensual"],
      membership: "Basic", //valor enum en el backend.
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "image", url: firstTrainer },
        { type: "image", url: secondTrainer },
        { type: "video", url: gymvideo },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Ricardo Martinez",
      email: "ricardo@example.com",
      password: "trainer123@!",
      phone: "12456789",
      speciality: "Entrenamiento Cardiovascular",
      location:{
        city: "Punta del Este",
        latitude: -34.947526,
        longitude: -54.945334,
        mapLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3267.5770725678456!2d-54.94533448544779!3d-34.94752608037243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9582e69861b8ec5d%3A0x7599e5a0e426d3c1!2sPunta%20del%20Este%2C%20Maldonado%20Department%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035373573!5m2!1sen!2sie",
      },
      price: 40,
      verified: false,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: secondTrainer,
      description: "Quinoa cray iPhone mustache street art direct trade...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Premium",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Carlos Ramirez",
      email: "carlitos@example.com",
      password: "Trainer123@!",
      phone: "12345679",
      speciality: "Deportes de Combate y Artes Marciales",
      location:{
        city: "Colonia del Sacramento",
        latitude: -34.447526,
        longitude: -57.845334,
        mapLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3287.3571081285353!2d-57.8448979854599!3d-34.46023898250727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95aaba05b28e9c15%3A0xb3c81bd09c730a89!2sColonia%20del%20Sacramento%2C%20Colonia%20Department%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035414748!5m2!1sen!2sie",
      },
      price: 45,
      verified: true,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: thirdTrainer,
      description: "Paleo stumptown craft beer chia health goth cred...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Elite",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Pedro Sanchez",
      email: "pedrito@example.com",
      password: "trainer123",
      phone: "12345789",
      speciality: "Yoga y Meditación",
      location:{
        city: "Salto",
        latitude: -31.447526,
        longitude: -57.945334,
        mapLink:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3438.926572606632!2d-57.948405485540784!3d-31.390215984093736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95afdb573d8b91cd%3A0xc01f0e8423b4ef62!2sSalto%2C%20Salto%20Department%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035447221!5m2!1sen!2sie",
       },
      price: 50,
      verified: true,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: firstTrainer,
      description: "Bitters cliche tattooed 8-bit distillery mustache...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Premium",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Juan Martinez",
      email: "juancho@example.com",
      password: "trainer123",
      phone: "12345689",
      speciality: "Deportes al Aire Libre",
      location: {
        city: "Paysandú",
        latitude: -32.447526,
        longitude: -58.045334,
        mapLink:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3440.5005459924516!2d-58.079353785541545!3d-32.320782184080816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a8d8f7925f8f9b%3A0xd2087c22b7a1b79c!2sPaysand%C3%BA%2C%20Paysand%C3%BA%20Department%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035473700!5m2!1sen!2sie",  
      },
      price: 40,
      verified: false,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: secondTrainer,
      description: "Quinoa cray iPhone mustache street art direct trade...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Basic",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [{ type: "image", url: "https://via.placeholder.com/1920x1080" }],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Pepe Mendez",
      email: "pepe@example.com",
      password: "trainer123",
      phone: "1234567",
      speciality: "Entrenamiento Funcional",
      location: {
        city: "Rivera",
        latitude: -30.947526,
        longitude: -55.545334,
        mapLink:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3406.2443492468846!2d-55.54003668552269!3d-30.8975045815309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94aa9b9610e899b5%3A0xdf98bfc64b58e77a!2sRivera%2C%20Rivera%20Department%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035500970!5m2!1sen!2sie",
      
      },
      price: 45,
      verified: true,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: thirdTrainer,
      description: "Paleo stumptown craft beer chia health goth cred...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Basic",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Jorge Martinez",
      email: "jorge@example.com",
      password: "trainer123",
      phone: "123456789",
      speciality: "CrossFit",
      location: {
        city: "Canelones",
        latitude: -34.527526,
        longitude: -56.445334,
        mapLink:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26607.88890538713!2d-56.32130104893994!3d-34.5299192425657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a01fd81a3a1b61%3A0x7ad0ff5ad2449e08!2sCanelones%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035423141!5m2!1sen!2sie",
       
      },
      price: 50,
      verified: true,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: firstTrainer,
      description: "Bitters cliche tattooed 8-bit distillery mustache...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Premium",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Luis Lopez",
      email: "luis@example.com",
      password: "trainer123",
      phone: "12356789",
      speciality: "Pilates",
      location: {
        city: "Durazno",
        latitude: -32.997526,
        longitude: -56.545334,
        mapLink:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29898.919241960807!2d-56.49580404896354!3d-33.38338434060201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a05e1f1b7a02b7%3A0x5b3914972907fbd5!2sDurazno%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035582104!5m2!1sen!2sie",  
      },
      price: 40,
      verified: false,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: secondTrainer,
      description: "Quinoa cray iPhone mustache street art direct trade...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Premium",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Ignacio Ramirez",
      email: "nacho@example.com",
      password: "trainer123",
      phone: "1234567",
      speciality: "Entrenamiento de Equipo",
      location: {
        city: "Tacuarembó",
        latitude: -31.947526,
        longitude: -55.985334,
        mapLink:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3436.792055051658!2d-55.98915558554098!3d-31.716016284097282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95aa0b6d999e2b69%3A0x9cfab3b8d9ff11d4!2sTacuaremb%C3%B3%2C%20Tacuaremb%C3%B3%20Department%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035526520!5m2!1sen!2sie",  
      },
      price: 45,
      verified: true,
      isVerificationRequested: false, // Solicitud de verificación aún no realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: thirdTrainer,
      description: "Paleo stumptown craft beer chia health goth cred...",
      about: "Información detallada sobre el entrenador.",
      experience: [
        "Certificación en Entrenamiento Avanzado",
        "5 años de experiencia en gimnasios",
      ],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Elite",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: "Agustin Lopez",
      email: "agus@example.com",
      password: "trainer123",
      phone: "456789",
      speciality: "Deportes Acuáticos",
      location: {
        city: "Tacuarembó",
        latitude: -31.747526,
        longitude: -55.985334,
        mapLink:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3436.792055051658!2d-55.98915558554098!3d-31.716016284097282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95aa0b6d999e2b69%3A0x9cfab3b8d9ff11d4!2sTacuaremb%C3%B3%2C%20Tacuaremb%C3%B3%20Department%2C%20Uruguay!5e0!3m2!1sen!2sie!4v1631035526520!5m2!1sen!2sie",  
      },
      price: 45,
      verified: true,
      isVerificationRequested: false, // Solicitud de verificación  realizada
      verificationPhoto: "", // URL o blob de la foto de verificación (vacío inicialmente)
      profileVisibility: "public",
      isPublicationRequested: false,
      rating:"",
      image: thirdTrainer,
      description: "Paleo stumptown craft beer chia health goth cred...",
      about: "Información detallada sobre el entrenador.",
      experience: ["Certificación en Entrenamiento Avanzado"],
      services: [
        "Entrenamiento personal",
        "Planes de nutrición",
        "Asesoramiento online",
      ],
      availability: "Lunes a Viernes: 6am - 8pm",
      pricing: [
        "Plan Básico: $50/h",
        "Plan Avanzado: $200/mes",
        "Plan Premium: $500/mes",
      ],
      membership: "Elite",
      social: [
        { platform: "facebook-f", link: "#" },
        { platform: "instagram", link: "#" },
        { platform: "twitter", link: "#" },
        { platform: "tiktok", link: "#" },
      ],
      media: [
        { type: "image", url: "https://via.placeholder.com/1920x1080" },
        { type: "image", url: "https://via.placeholder.com/800" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
        { type: "video", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      ],
      comments: [],
      role: "trainer",
      profileComplete: true,
      createdAt: new Date().toISOString(),
    },
  ];
export const initialUsers = [
    {
      id: uuidv4(),
      name: 'Juan Pérez',
      email: 'juan@example.com',
      password: 'User123456!',
      phone: '123456789',
      profileImage: avatarImg,
      fitnessGoals: 'Pérdida de peso',
      trainingPreferences: 'Entrenamiento en casa',
      favoriteTrainers: [],
      reviews: [],
      role: 'user',
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: 'Carlos Sanchez',
      email: 'carlos@example.com',
      password: '123456',
      phone: '123456789',
      profileImage: avatarImg,
      fitnessGoals: 'Ganancia de músculo',
      trainingPreferences: 'Entrenamiento en gimnasio',
      favoriteTrainers: [],
      reviews: [],
      role: 'user',
      createdAt: new Date().toISOString(),
    },
    {
      id: uuidv4(),
      name: 'Pedro Rodriguez',
      email: 'pedro@example.com',
      password: '123456',
      phone: '123456789',
      profileImage: avatarImg,
      fitnessGoals: 'Pérdida de peso',
      trainingPreferences: 'Entrenamiento al aire libre',
      favoriteTrainers: [],
      reviews: [],
      role: 'user',
      createdAt: new Date().toISOString(),
    },
  ];
  // Función para cargar administradores desde localStorage
// const loadAdminsFromLocalStorage = () => {
//   const storedAdmins = localStorage.getItem('admins');
//   return storedAdmins ? JSON.parse(storedAdmins) : [
//     {
//       id: uuidv4(),
//       name: 'Admin1',
//       email: 'admin1@example.com',
//       password: 'admin123!', // En un backend real, esto estaría encriptado
//       role: 'admin',
//     },
//     {
//       id: uuidv4(),
//       name: 'Admin2',
//       email: 'admin2@example.com',
//       password: 'admin123!', // En un backend real, esto estaría encriptado
//       role: 'admin',
//     },
//   ];
// };

// // Guardar administradores en localStorage
// const saveAdminsToLocalStorage = (admins) => {
//   localStorage.setItem('admins', JSON.stringify(admins));
// };

// // Administradores iniciales cargados desde localStorage
// const initialAdmins = loadAdminsFromLocalStorage();

  //add trainer
  // export const addTrainer = createAsyncThunk(
  //   "trainer/addTrainer",
  //   async (trainerData) => {
  //     try {
  //       const newTrainer = {
  //         id: uuidv4(),
  //         name: trainerData.name || "",
  //         email: trainerData.email || "",
  //         password: trainerData.password || "",
  //         phone: "",
  //         speciality: "",
  //         location: {
  //           city: "",
  //           latitude: 0,
  //           longitude: 0,
  //           mapLink: "",
  //         },
  //         price: 0,
  //         verified: false,
  //         isVerificationRequested: false, 
  //         verificationPhoto: "", 
  //         profileVisibility: "private",
  //         isPublicationRequested: false,
  //         rating:"",
  //         image: "",
  //         description: "",
  //         about: "",
  //         experience: [],
  //         services: [],
  //         availability: "",
  //         pricing: [],
  //         membership: "",
  //         social: [],
  //         media: [],
  //         comments: [],
  //         role: "trainer",
  //         profileComplete: false,
  //         createdAt: new Date().toISOString(),
  //       };
  //       localTrainers = [...localTrainers, newTrainer];
  //       saveTrainersToLocalStorage(localTrainers);
  //       return newTrainer;
  //     } catch (error) {
  //       console.error("Error al agregar entrenador", error);
  //       throw new Error("No se pudo agregar el entrenador");
  //     }
  //   }
  // );

  // export const createTrainerProfile = createAsyncThunk(
  //   "trainer/createTrainerProfile",
  //   async (profileData) => {
  //     try {
  //       const index = localTrainers.findIndex(
  //         (trainer) => trainer.id === profileData.id
  //       );
  //       if (index !== -1) {
  //         const updatedTrainer = {
  //           ...localTrainers[index],
  //           ...profileData,
  //           profileComplete: true,
  //         };
  //         localTrainers = [
  //           ...localTrainers.slice(0, index),
  //           updatedTrainer,
  //           ...localTrainers.slice(index + 1),
  //         ];
  //         saveTrainersToLocalStorage(localTrainers);
  //         return updatedTrainer;
  //       } else {
  //         throw new Error("Entrenador no encontrado");
  //       }
  //     } catch (error) {
  //       console.error("Error al crear el perfil del entrenador", error);
  //       throw new Error("No se pudo crear el perfil del entrenador");
  //     }
  //   }
  // );
  
  // export const updateTrainer = createAsyncThunk(
  //   "trainer/updateTrainer",
  //   async (trainerData, { dispatch }) => {
  //     try {
  //       const index = localTrainers.findIndex(
  //         (trainer) => trainer.id === trainerData.id
  //       );
  
  //       if (index !== -1) {
  //         const updatedTrainer = {
  //           ...localTrainers[index],
  //           ...trainerData,
  //           location: {
  //             ...localTrainers[index].location,
  //             ...trainerData.location,
  //           },
  //         };
  
  //         localTrainers = [
  //           ...localTrainers.slice(0, index),
  //           updatedTrainer,
  //           ...localTrainers.slice(index + 1),
  //         ];
  //         saveTrainersToLocalStorage(localTrainers);
  //         dispatch(selectTrainer(updatedTrainer));
  
  //         return updatedTrainer;
  //       } else {
  //         throw new Error("Entrenador no encontrado");
  //       }
  //     } catch (error) {
  //       console.error("Error al actualizar el entrenador", error);
  //       throw new Error("No se pudo actualizar el entrenador");
  //     }
  //   }
  // );
  // export const updateTrainerComment = createAsyncThunk(
  //   "trainers/updateTrainerComment",
  //   async ({ trainerId, commentId, updatedData }, { getState }) => {
  //     try {
  //       const state = getState();
  //       const trainer = state.trainers.trainers.find((t) => t.id === trainerId);
  
  //       if (!trainer) {
  //         throw new Error("Entrenador no encontrado");
  //       }
  
  //       const updatedComments = trainer.comments.map((comment) =>
  //         comment.id === commentId ? { ...comment, ...updatedData } : comment
  //       );
  
  //       const updatedTrainer = {
  //         ...trainer,
  //         comments: updatedComments,
  //       };
  
  //       const updatedTrainers = state.trainers.trainers.map((t) =>
  //         t.id === trainerId ? updatedTrainer : t
  //       );
  
  //       saveTrainersToLocalStorage(updatedTrainers);
  
  //       return { trainerId, commentId, updatedData };
  //     } catch (error) {
  //       console.error("Error al actualizar comentario", error);
  //       throw new Error("No se pudo actualizar el comentario");
  //     }
  //   }
  // );
  
  // export const addTrainerComment = createAsyncThunk(
  //   "trainers/addTrainerComment",
  //   async ({ trainerId, comment }, { getState }) => {
  //     try {
  //       const state = getState();
  //       const trainer = state.trainers.trainers.find((t) => t.id === trainerId);
  
  //       if (!trainer) {
  //         throw new Error("Entrenador no encontrado");
  //       }
  
  //       const updatedTrainer = {
  //         ...trainer,
  //         comments: [...trainer.comments, comment],
  //       };
  
  //       const updatedTrainers = state.trainers.trainers.map((t) =>
  //         t.id === trainerId ? updatedTrainer : t
  //       );
  
  //       saveTrainersToLocalStorage(updatedTrainers);
  
  //       return { trainerId, comment };
  //     } catch (error) {
  //       console.error("Error al agregar comentario", error);
  //       throw new Error("No se pudo agregar el comentario");
  //     }
  //   }
  // );
  
  // export const addReplyToTrainerComment = createAsyncThunk(
  //   "trainers/addReplyToTrainerComment",
  //   async ({ trainerId, commentId, reply }, { getState }) => {
  //     try {
  //       const state = getState();
  //       const trainer = state.trainers.trainers.find((t) => t.id === trainerId);
  
  //       if (!trainer) {
  //         throw new Error("Entrenador no encontrado");
  //       }
  
  //       const updatedComments = trainer.comments.map((comment) =>
  //         comment.id === commentId
  //           ? { ...comment, replies: [...comment.replies, reply] }
  //           : comment
  //       );
  
  //       const updatedTrainer = {
  //         ...trainer,
  //         comments: updatedComments,
  //       };
  
  //       const updatedTrainers = state.trainers.trainers.map((t) =>
  //         t.id === trainerId ? updatedTrainer : t
  //       );
  
  //       saveTrainersToLocalStorage(updatedTrainers);
  
  //       return { trainerId, commentId, reply };
  //     } catch (error) {
  //       console.error("Error al agregar respuesta", error);
  //       throw new Error("No se pudo agregar la respuesta");
  //     }
  //   }
  // );
  
  // export const deleteTrainerComment = createAsyncThunk(
  //   "trainer/deleteTrainerComment",
  //   async ({ trainerId, commentId }, { getState }) => {
  //     try {
  //       const state = getState();
  //       const trainer = state.trainers.trainers.find(
  //         (trainer) => trainer.id === trainerId
  //       );
  
  //       if (!trainer) {
  //         throw new Error("Entrenador no encontrado");
  //       }
  
  //       const updatedComments = trainer.comments.filter(
  //         (comment) => comment.id !== commentId
  //       );
  
  //       const updatedTrainer = {
  //         ...trainer,
  //         comments: updatedComments,
  //       };
  
  //       localTrainers = localTrainers.map((trainer) =>
  //         trainer.id === trainerId ? updatedTrainer : trainer
  //       );
  //       saveTrainersToLocalStorage(localTrainers);
  
  //       return { trainerId, commentId };
  //     } catch (error) {
  //       console.error("Error al eliminar comentario", error);
  //       throw new Error("No se pudo eliminar el comentario");
  //     }
  //   }
  // );
        // .addCase(addTrainerComment.fulfilled, (state, action) => {
      //   const { trainerId, comment } = action.payload;
      //   const trainer = state.trainers.find(
      //     (trainer) => trainer.id === trainerId
      //   );
      //   if (trainer) {
      //     trainer.comments = [...trainer.comments, comment];
      //   }
      // })
      // .addCase(addReplyToTrainerComment.fulfilled, (state, action) => {
      //   const { trainerId, commentId, reply } = action.payload;
      //   const trainer = state.trainers.find(
      //     (trainer) => trainer.id === trainerId
      //   );
      //   if (trainer) {
      //     const comment = trainer.comments.find(
      //       (comment) => comment.id === commentId
      //     );
      //     if (comment) {
      //       comment.replies = [...comment.replies, reply];
      //     }
      //   }
      // })
      // .addCase(deleteTrainerComment.fulfilled, (state, action) => {
      //   const { trainerId, commentId } = action.payload;
      //   const trainer = state.trainers.find(
      //     (trainer) => trainer.id === trainerId
      //   );
      //   if (trainer) {
      //     trainer.comments = trainer.comments.filter(
      //       (comment) => comment.id !== commentId
      //     );
      //   }
      // })
      // .addCase(updateTrainerComment.fulfilled, (state, action) => {
      //   const { trainerId, commentId, updatedData } = action.payload;
      //   const trainer = state.trainers.find(
      //     (trainer) => trainer.id === trainerId
      //   );
      //   if (trainer) {
      //     trainer.comments = trainer.comments.map((comment) =>
      //       comment.id === commentId ? { ...comment, ...updatedData } : comment
      //     );
      //   }
      // })