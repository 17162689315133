import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faChartArea,
  faTable,
  faFolder,
  faArrowAltCircleLeft
} from "@fortawesome/free-solid-svg-icons";
import { Collapse, Button } from "reactstrap";
import { Link } from "react-router-dom";

const BarraLateralComplementos = () => {
  // Colapsar Open state
  const [isOpen, setIsOpen] = React.useState(false);
  const handleLogoClick = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };
  return (
    <div>
      {" "}
      {/* <!-- Titulo --> */}
      <div className="sidebar-heading">Complementos</div>
      {/* <!-- Nav Item -Páginas Cerrar menú --> */}
      <li className="nav-item">
        <Button
          className="nav-link collapsed"
          data-toggle="collapse"
          data-target="#collapsePages"
          aria-expanded="true"
          aria-controls="collapsePages"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ background: "#1e1e1e", border: "none" }}
        >
          <FontAwesomeIcon icon={faFolder} style={{ marginRight: "10px" }} />
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{ textAlign: "right", position: "relative", left: "80%" }}
          />
          <span>Rutas</span>
        </Button>
        <Collapse
          isOpen={isOpen}
          id="collapsePages"
          className="collapse"
          aria-labelledby="headingPages"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Pantallas de Inicio:</h6>
            <a className="collapse-item" href="/login">
              Login
            </a>
            <a className="collapse-item" href="/registro">
              Registro
            </a>
          </div>
        </Collapse>
      </li>
      {/* <!-- Nav Item -Graficas --> */}
      <li className="nav-item">
        <a className="nav-link" href="#Grafica">
          <FontAwesomeIcon icon={faChartArea} style={{ marginRight: "10px" }} />
          <span>Graficas</span>
        </a>
      </li>
      {/* <!-- Nav Item - Tables --> */}
      <li className="nav-item">
        <a className="nav-link" href="#Tabla">
          <FontAwesomeIcon icon={faTable} style={{ marginRight: "10px" }} />
          <span>Tablas</span>
        </a>
      </li>
      {/* <-- Barra divisora --> */}
      <hr className="sidebar-divider d-none d-md-block" />
      {/* <-- Ir A Inicio --> */}
      <Link to="/" className="logo-dashboard" onClick={handleLogoClick}>
      <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{ marginRight: "10px" }} />
        <span>
          TREINO<em> APP</em>
        </span>
      </Link>
    </div>
  );
};

export default BarraLateralComplementos;
