import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteCommentAsync, updateCommentAsync } from "./CommentsSlice";
import { deleteTrainerAccount, updateTrainer } from "./TrainersSlice";
import { deleteUserAccount, updateUser } from "./UsersSlice";
import {
  getAdmins,
  registerAdmin,
  loginAdmin,
  updateAdminAccount,
  deleteAdmin,
} from "../services/adminService";
import {logout } from "./authSlice";

// Thunk para obtener la lista de administradores
export const fetchAdmins = createAsyncThunk(
  "admins/fetchAdmins",
  async (_, { rejectWithValue }) => {
    try {
      const admins = await getAdmins(); // Llama al servicio de API para obtener todos los admins
      return admins;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error al obtener los admins"
      );
    }
  }
);

// Crear un nuevo admin
export const addAdmin = createAsyncThunk(
  "admins/addAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const newAdmin = await registerAdmin(adminData);
      return newAdmin;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al agregar admin"
      );
    }
  }
);

// Login Admin
export const loginAdminAccount = createAsyncThunk(
  "admins/loginAdminAccount",
  async (adminData, { rejectWithValue }) => {
    try {
      const logAdmin = await loginAdmin(adminData);
      return logAdmin;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al iniciar sesion de admin"
      );
    }
  }
);

// Thunk para actualizar un administrador
export const updateAdmin = createAsyncThunk(
  "admins/updateAdmin",
  async ({ adminId, adminData }, { rejectWithValue }) => {
    try {
      const updatedAdmin = await updateAdminAccount(adminId, adminData);
      return updatedAdmin;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al actualizar admin"
      );
    }
  }
);

export const deleteAdminAccount = createAsyncThunk(
  "admins/deleteUserAccount",
  async (adminId, { dispatch, rejectWithValue }) => {
    try {
      await deleteAdmin(adminId);
      dispatch(logout());
      return adminId;
    } catch (error) {
      console.error("Error al eliminar cuenta de admin", error);
      return rejectWithValue(
        error.response?.data || "Error al eliminar la cuenta del admin"
      );
    }
  }
);

// Thunk para eliminar un comentario como administrador
export const adminDeleteComment = createAsyncThunk(
  "admin/deleteComment",
  async ({ trainerId, commentId }, { dispatch }) => {
    try {
      await dispatch(deleteCommentAsync({ trainerId, commentId }));
    } catch (error) {
      console.error("Error al eliminar comentario", error);
      throw new Error("No se pudo eliminar el comentario");
    }
  }
);

// Thunk para editar un comentario como administrador
export const adminUpdateComment = createAsyncThunk(
  "admin/updateComment",
  async ({ commentId, updatedData }, { dispatch }) => {
    try {
      await dispatch(updateCommentAsync({commentId, updatedData }));
    } catch (error) {
      console.error("Error al actualizar comentario", error);
      throw new Error("No se pudo actualizar el comentario");
    }
  }
);

// Thunk para eliminar un entrenador como administrador
export const adminDeleteTrainer = createAsyncThunk(
  "admin/deleteTrainer",
  async (trainerId, { dispatch }) => {
    try {
      await dispatch(deleteTrainerAccount(trainerId));
    } catch (error) {
      console.error("Error al eliminar entrenador", error);
      throw new Error("No se pudo eliminar el entrenador");
    }
  }
);

// Thunk para actualizar un entrenador como administrador
export const adminUpdateTrainer = createAsyncThunk(
  "admin/updateTrainer",
  async (trainerData, { dispatch }) => {
    try {
      await dispatch(updateTrainer(trainerData));
    } catch (error) {
      console.error("Error al actualizar entrenador", error);
      throw new Error("No se pudo actualizar el entrenador");
    }
  }
);

// Thunk para eliminar un usuario como administrador
export const adminDeleteUser = createAsyncThunk(
  "admin/deleteUser",
  async (userId, { dispatch }) => {
    try {
      await dispatch(deleteUserAccount(userId));
    } catch (error) {
      console.error("Error al eliminar usuario", error);
      throw new Error("No se pudo eliminar el usuario");
    }
  }
);

// Thunk para actualizar un usuario como administrador
export const adminUpdateUser = createAsyncThunk(
  "admin/updateUser",
  async (userData, { dispatch }) => {
    try {
      await dispatch(updateUser(userData));
    } catch (error) {
      console.error("Error al actualizar usuario", error);
      throw new Error("No se pudo actualizar el usuario");
    }
  }
);

const adminsSlice = createSlice({
  name: "admins",
  initialState: {
    admins: [],
    selectedAdmin: null,
    status: "idle",
    error: null,
  },
  reducers: {
    selectAdmin: (state, action) => {
      state.selectedAdmin = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Admins
      .addCase(fetchAdmins.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.admins = action.payload;
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Add Admin
      .addCase(addAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.admins.push(action.payload);
      })
      .addCase(addAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Login admin
      .addCase(loginAdminAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAdminAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.admins.push(action.payload);
      })
      .addCase(loginAdminAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update Admin
      .addCase(updateAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.admins.findIndex(
          (admin) => admin.id === action.payload.id
        );
        if (index !== -1) {
          state.admins[index] = action.payload;
        }
        if (
          state.selectedAdmin &&
          state.selectedAdmin.id === action.payload.id
        ) {
          state.selectedAdmin = action.payload;
        }
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete admin
      .addCase(deleteAdminAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAdminAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.admins = state.admins.filter((admin) => admin.id !== action.payload);
        if (state.selectedAdmin && state.selectedAdmin.id === action.payload) {
          state.selectedAdmin = null;
        }
      })
      .addCase(deleteAdminAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //
      .addCase(adminDeleteComment.fulfilled, (state) => {
        state.status = "comment_deleted";
        console.log("Comentario eliminado exitosamente.");
      })
      .addCase(adminUpdateComment.fulfilled, (state) => {
        state.status = "comment_updated";
        console.log("Comentario actualizado exitosamente.");
      })
      .addCase(adminDeleteTrainer.fulfilled, (state) => {
        state.status = "trainer_deleted";
        console.log("Entrenador eliminado exitosamente.");
      })
      .addCase(adminUpdateTrainer.fulfilled, (state) => {
        state.status = "trainer_updated";
        console.log("Entrenador actualizado exitosamente.");
      })
      .addCase(adminDeleteUser.fulfilled, (state) => {
        state.status = "user_deleted";
        console.log("Usuario eliminado exitosamente.");
      })
      .addCase(adminUpdateUser.fulfilled, (state) => {
        state.status = "user_updated";
        console.log("Usuario actualizado exitosamente.");
      })
      .addCase(adminDeleteComment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.error("Error al eliminar comentario:", action.error.message);
      })
      .addCase(adminUpdateComment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.error("Error al actualizar comentario:", action.error.message);
      });
  },
});

export const { selectAdmin, clearError } = adminsSlice.actions;

export default adminsSlice.reducer;
