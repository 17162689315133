// src/utils/constants.js

export const categories = [
    "Fitness y Acondicionamiento Físico",
    "Entrenamiento Cardiovascular",
    "Deportes de Combate y Artes Marciales",
    "Yoga y Meditación",
    "Deportes al Aire Libre",
    "Entrenamiento Funcional",
    "Nutrición y Dietética",
    "Calistenia",
    "CrossFit",
    "Entrenamiento de Resistencia",
    "Deportes Acuáticos",
    "Entrenamiento de Equipo",
    "Pilates",
    "Movilidad y Rehabilitación",
    "Danza y Baile",
    "Otros"
  ];
  
  export const locations = [
    'Montevideo',
    'Canelones',
    'Las Piedras',
    'Maldonado',
    'Punta del Este',
    'Colonia del Sacramento',
    'Salto',
    'Paysandú',
    'Rivera',
    'Tacuarembó',
    'Melo',
    'Artigas',
    'San José de Mayo',
    'Durazno',
    'Florida',
    'Rocha',
    'Mercedes',
    'Treinta y Tres',
    'Piriápolis',
    'Minas',
    'Fray Bentos',
    'Young',
    'Tranqueras',
    'Bella Unión',
    'Juan Lacaze',
    'Nueva Palmira',
    'Carmelo',
    'San Carlos',
    'Pando',
    'Ciudad de la Costa'
  ];
  
  export const membershipOrder = {
    ELITE: 1,
    PREMIUM: 2,
    BASIC: 3,
  };