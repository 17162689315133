import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { adminDeleteUser } from '../features/AdminSlice';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';

const ListadoUsuarios = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usuarios = useSelector((state) => state.users.users);
  const [filteredUsuarios, setFilteredUsuarios] = useState(usuarios);
  const [filterInput, setFilterInput] = useState("");

  useEffect(() => {
    setFilteredUsuarios(usuarios);
  }, [usuarios]);

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterInput(value);
    setFilteredUsuarios(
      usuarios.filter(usu =>
        usu.nombre.toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = (userId) => {
    confirmAlert({
      title: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar este usuario?',
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            dispatch(adminDeleteUser(userId));
            toast.success('Usuario eliminado con éxito');
          }
        },
        {
          label: 'No',
          onClick: () => toast.info('Eliminación cancelada')
        }
      ]
    });
  };

  const handleEdit = (userId) => {
    navigate(`/user-profile/${userId}`);
  };

  return (
    <div className="col-xl-12 col-lg-12 overflow-hidden" id="Tabla">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-success">
            Listado de Usuarios:
          </h6>
          <input
            type="text"
            placeholder="Buscar usuarios..."
            className="form-control"
            value={filterInput}
            onChange={handleFilter}
          />
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-dark table-bordered">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Teléfono</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsuarios.map(usu => (
                  <tr key={usu.id}>
                    <td>{usu.nombre}</td>
                    <td>{usu.email}</td>
                    <td>{usu.telefono}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleEdit(usu.id)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(usu.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListadoUsuarios;
