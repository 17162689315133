import React from 'react';

const Experience = ({ formData, editable, handleChange }) => {
  const experiencia = Array.isArray(formData.experiencia) ? formData.experiencia : [];

  return (
    <div className="experience-section">
      <h3>Experiencia y Certificaciones</h3>
      {editable ? (
        <textarea
          name="experiencia"
          value={formData.experiencia}
          onChange={handleChange}
          className="edit-textarea"
        />
      ) : (
        <p>
          {formData.experiencia}
        </p>
      )}
    </div>
  );
};

export default Experience;
