// src/componentes/EncabezadoLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import EncabezadoInicio from './EncabezadoInicio';

const EncabezadoLayout = () => {
  return (
    <>
      <EncabezadoInicio />
      <Outlet />
    </>
  );
};

export default EncabezadoLayout;
