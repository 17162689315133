import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BarraLateral from './BarraLateral';
import Contenido from './Contenido';
import { fetchUsers } from '../features/UsersSlice';
import { fetchTrainers } from '../features/TrainersSlice';
import { fetchAllReviews } from '../features/CommentsSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const [isSidebarVisible, setSidebarVisible] = useState(true);

  // Control de acceso
  useEffect(() => {
    if (!user || user.rol !== 'ADMIN') {
      navigate('/login', { replace: true });
      return;
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user?.rol === 'ADMIN') {
      dispatch(fetchUsers());
      dispatch(fetchTrainers());
      dispatch(fetchAllReviews());
    }
  }, [dispatch, user]);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <div id="wrapper" className={isSidebarVisible ? '' : 'sidebar-hidden'}>
      <BarraLateral isSidebarVisible={isSidebarVisible} />
      <Contenido 
        user={user} 
        context="dashboard" 
        toggleSidebar={toggleSidebar} 
      />
    </div>
  );
};

export default Dashboard;
