import React from 'react'

const NoEncontrado = () => {
  return (
    <div>
        <h1>404 no encontrado</h1>
    </div>
  )
}

export default NoEncontrado