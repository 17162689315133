import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const NumeroUsuarios = () => {
  const numeroUsuarios = useSelector((state) => state.users.users);
  return (
    /* <!-- Content Col --> */
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-left-info shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                Numero De Usuarios:
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-100">
                {numeroUsuarios.length}
              </div>
            </div>
            <div className="col-auto text-gray-100">
              <FontAwesomeIcon
                icon={faUser}
                style={{ fontSize: "25px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumeroUsuarios;
