import React from 'react'
import BarraLateralComplementos from './BarraLateralComplementos'
import BarraLateralInterfaz from './BarraLateralInterfaz'
import BarraLateralMarca from './BarraLateralMarca'

const BarraLateral = () => {
  return (
    // <!-- Barra Lateral -->
    <ul className="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
         <BarraLateralMarca/>
         {/* <BarraLateralInterfaz/> */}
         <BarraLateralComplementos/>
    </ul>
  )
}

export default BarraLateral