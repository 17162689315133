import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunks para manejar operaciones asíncronas
export const fetchPlans = createAsyncThunk('membership/fetchPlans', async () => {
  const response = await fetch('/api/plans');
  return response.json();
});

export const createPlan = createAsyncThunk('membership/createPlan', async (newPlan) => {
  const response = await fetch('/api/plans', {
    method: 'POST',
    body: JSON.stringify(newPlan),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
});

export const updatePlan = createAsyncThunk('membership/updatePlan', async (updatedPlan) => {
  const response = await fetch(`/api/plans/${updatedPlan.id}`, {
    method: 'PUT',
    body: JSON.stringify(updatedPlan),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
});

export const deletePlan = createAsyncThunk('membership/deletePlan', async (planId) => {
  await fetch(`/api/plans/${planId}`, {
    method: 'DELETE',
  });
  return planId;
});

const membershipSlice = createSlice({
  name: 'membership',
  initialState: {
    plans: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plans = action.payload;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.plans.push(action.payload);
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        const index = state.plans.findIndex(plan => plan.id === action.payload.id);
        state.plans[index] = action.payload;
      })
      .addCase(deletePlan.fulfilled, (state, action) => {
        state.plans = state.plans.filter(plan => plan.id !== action.payload);
      });
  },
});

export default membershipSlice.reducer;
