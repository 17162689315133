import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faMedal, faStar } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrainers } from "../features/TrainersSlice";
import VerificationModal from "./VerificationModal";
import { useNavigate } from "react-router-dom";
import { fetchUsers } from "../features/UsersSlice";

const membershipIcons = {
  BASIC: faMedal,
  PREMIUM: faStar,
  ELITE: faCrown,
};

const AdminAlertas = ({ getNotificationCount = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verificationRequests, setVerificationRequests] = useState([]);
  const [publicationRequests, setPublicationRequests] = useState([]);
  const [newRegistrations, setNewRegistrations] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState(null);

  useEffect(() => {
    fetchAdminData();
  }, []);

  const fetchAdminData = async () => {
    try {
      const trainers = await dispatch(fetchTrainers()).unwrap();
      const users = await dispatch(fetchUsers()).unwrap();
      const pendingVerifications = trainers.filter(
        (trainer) => trainer.solicitoVerificado
      );
      const pendingPublications = trainers.filter(
        (trainer) => trainer.solicitoPublicacion
      );
      const recentRegistrations = [...users, ...trainers].filter((person) => {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        return new Date(person.createAt) > oneWeekAgo;
      });

      setVerificationRequests(pendingVerifications);
      setNewRegistrations(recentRegistrations);
      setPublicationRequests(pendingPublications);
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };

  if (getNotificationCount) {
    return verificationRequests.length + publicationRequests.length + newRegistrations.length;
  }

  const handleTrainerClick = (trainerId) => {
    navigate(`/entrenadores/${trainerId}`);
  };

  const handleVerifyClick = (trainer) => {
    setSelectedTrainer(trainer);
    setModalOpen(true);
  };

  return (
    <>
      {/* Solicitudes de Verificación */}
      {verificationRequests.length > 0 && (
        <div>
          <h6 className="dropdown-header">Solicitudes de Verificación</h6>
          {verificationRequests.slice(0, 3).map((request) => (
            <div
              className="dropdown-item d-flex align-items-center"
              key={request.id}
              onClick={() => handleVerifyClick(request)}
              style={{ cursor: "pointer" }}
            >
              <div className="mr-3">
                <div className="icon-circle bg-success">
                  <img
                    src={request.fotoVerificacion || "https://via.placeholder.com/50"}
                    alt={request.nombre}
                    className="img-profile rounded-circle"
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
              <div style={{ height: "70px" }}>
                <div className="font-weight-bold" style={{ height: "20px", marginTop: "5px" }}>
                  {request.nombre}
                </div>
                <span className="small text-gray-500">Ha solicitado verificación</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Solicitudes de Publicación */}
      {publicationRequests.length > 0 && (
        <div>
          <h6 className="dropdown-header">Solicitudes de Publicación</h6>
          {publicationRequests.slice(0, 3).map((request) => (
            <div
              className="dropdown-item d-flex align-items-center"
              key={request.id}
              onClick={() => handleTrainerClick(request.id)}
              style={{ cursor: "pointer" }}
            >
              <div className="mr-3">
                <div className="icon-circle bg-warning">
                  <FontAwesomeIcon icon={membershipIcons[request.membresiaSolicitada]} />
                </div>
              </div>
              <div style={{ height: "70px" }}>
                <div className="font-weight-bold" style={{ height: "20px", marginTop: "5px" }}>
                  {request.nombre}
                </div>
                <span className="small text-gray-500">
                  Ha solicitado el plan {request.membresiaSolicitada}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Nuevos Registros */}
      {newRegistrations.length > 0 && (
        <div>
          <h6 className="dropdown-header">Nuevos Registros</h6>
          {newRegistrations.slice(0, 3).map((registration) => (
            <div
              className="dropdown-item d-flex align-items-center"
              key={registration.id}
              style={{ cursor: "pointer" }}
            >
              <div className="mr-3">
                <div className="icon-circle bg-success">
                  <img
                    src={registration.foto || "https://via.placeholder.com/50"}
                    alt={registration.nombre}
                    className="img-profile rounded-circle"
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
              <div style={{ height: "70px" }}>
                <div className="font-weight-bold" style={{ height: "20px", marginTop: "5px" }}>
                  {registration.nombre}
                </div>
                <span className="small text-gray-500">Se ha registrado recientemente</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {verificationRequests.length > 0 && (
        <VerificationModal
          isOpen={modalOpen}
          toggle={() => setModalOpen(!modalOpen)}
          trainerId={selectedTrainer?.id}
          isAdmin={true}
        />
      )}
    </>
  );
};

export default AdminAlertas;
