// src/componentes/Loader.js
import React, { useEffect, useState } from 'react';

const Loader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setLoaded(true);
    };

    // Escucha el evento de carga en la ventana
    window.addEventListener('load', handleLoad);

    return () => {
      // Limpia el evento cuando el componente se desmonte
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <div id="js-preloader" className={`js-preloader ${loaded ? 'loaded' : ''}`}>
      <div className="preloader-inner">
        <span className="dot"></span>
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
