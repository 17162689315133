// src/components/LoginForm.js
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../features/authSlice';
import { toast } from 'react-toastify';

const LoginForm = () => {
  const [vacio, setVacio] = useState(true);
  const userInput = useRef(null);
  const pass = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.auth);
  const [inputError, setInputError] = useState({
    user: '',
    pass: '',
  });

  const habBtn = (e) => {
    setVacio(!e.target.value);
  };

  const ingresar = async () => {
    const datoUser = userInput.current.value;
    const datoPass = pass.current.value;

    if (!datoUser) {
      setInputError({ user: 'Ingresar nombre de usuario o correo electrónico' });
      return;
    }
    if (!datoPass) {
      setInputError({ pass: 'Ingresar contraseña' });
      return;
    }

    try {
      const resultAction = await dispatch(loginUser({ email: datoUser, password: datoPass }));

      if (loginUser.rejected.match(resultAction)) {
        toast.error(resultAction.payload || 'Usuario o contraseña incorrectos');
        return;
      }

      const user = resultAction.payload;
      if (user.rol === 'ADMIN') {
        navigate('/dashboard');
      } else {
        navigate('/');
      }

      toast.success(`Bienvenido ${user.nombre}`);
    } catch (msj) {
      console.error('Error en el inicio de sesión:', msj);
    }
  };

  return (
    <div className="auth-form">
      <form className="user">
        <div className="form-group">
          <input
            type="text"
            ref={userInput}
            onChange={habBtn}
            className="form-control form-control-user"
            id="registroUsuario"
            aria-describedby="emailHelp"
            placeholder="Ingresar correo electrónico..."
          />
          <p style={{ color: 'red' }}> {inputError.user} </p>
        </div>
        <div className="form-group">
          <input
            type="password"
            ref={pass}
            onChange={habBtn}
            className="form-control form-control-user"
            id="loginPassword"
            placeholder="Contraseña"
          />
          <p style={{ color: 'red' }}> {inputError.pass} </p>
        </div>
        {error && (
          <div className="form-group">
            <p style={{ color: 'red', fontSize: '16px' }}> {error} </p>
          </div>
        )}
        <input
          onClick={ingresar}
          type="button"
          value="Ingresar"
          id="btnLogin"
          disabled={vacio}
          className="btn btn-success btn-user btn-block"
        />
        <hr />
        <a
          href="https://accounts.google.com/AccountChooser"
          className="btn btn-google btn-user btn-block"
        >
          <i className="fab fa-google fa-fw"></i> Ingresar con Google
        </a>
      </form>
    </div>
  );
};

export default LoginForm;
