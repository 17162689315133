import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import avatarImg from "../assets/img/avatar.png";

const UserAlertas = ({ getNotificationCount = false }) => {
  const navigate = useNavigate();
  const [favoriteTrainers, setFavoriteTrainers] = useState([]);

  useEffect(() => {
    loadUserFavorites();
  }, []);

  const loadUserFavorites = () => {
    const storedFavorites = JSON.parse(localStorage.getItem("favoriteTrainers")) || [];
    setFavoriteTrainers(storedFavorites);

    const handleStorageChange = () => {
      const updatedFavorites = JSON.parse(localStorage.getItem("favoriteTrainers")) || [];
      setFavoriteTrainers(updatedFavorites);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  };

  if (getNotificationCount) {
    return favoriteTrainers.length;
  }

  const handleTrainerClick = (trainerId) => {
    navigate(`/entrenadores/${trainerId}`);
  };


  return (
    <>
      {favoriteTrainers.length > 0 ? (
        favoriteTrainers.slice(0, 3).map((trainer) => (
          <a className="dropdown-item d-flex align-items-center" key={trainer.id} onClick={() => handleTrainerClick(trainer.id)}>
            <div className="mr-3">
              <div className="icon-circle bg-success">
                <img
                  src={trainer.fotoUrl || avatarImg}
                  alt={trainer.nombre}
                  className="img-profile rounded-circle"
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
            <div style={{ marginBottom: "7px" }}>
              <div className="font-weight-bold" style={{ height: "20px", marginTop: "5px" }}>
                {trainer.nombre}
              </div>
              <span className="small text-gray-500">{trainer.especialidad}</span>
            </div>
          </a>
        ))
      ) : (
        <div className="dropdown-item text-center small text-gray-500">No tienes entrenadores favoritos</div>
      )}
    </>
  );
};

export default UserAlertas;
