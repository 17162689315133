// src/components/EncabezadoInicio.js
import React, { useEffect } from "react";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BarraSuperiorNavegacion from "./BarraSuperiorNavegacion";

const EncabezadoInicio = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    function mobileNav() {
      var width = $(window).width();
      $(".submenu").on("click", function () {
        if (width < 767) {
          $(".submenu ul").removeClass("active");
          $(this).find("ul").toggleClass("active");
        }
      });
    }

    $(window).on("resize", function () {
      mobileNav();
    });

    mobileNav();

    // Scroll event
    const handleScroll = () => {
      const scroll = $(window).scrollTop();
      const box = $(".header-text").height();
      const header = $("header").height();

      if (scroll >= box - header) {
        $("header").addClass("background-header");
      } else {
        $("header").removeClass("background-header");
      }
    };

    $(window).on("scroll", handleScroll);

    // Smooth scroll

    const onScroll = (event) => {
      const scrollPos = $(document).scrollTop();
      $('.nav a[href^="#"]').each(function () {
        const currLink = $(this);
        const refElement = $(currLink.attr("href"));

        if (
          refElement.length &&
          refElement.position().top <= scrollPos &&
          refElement.position().top + refElement.height() > scrollPos
        ) {
          $(".nav ul li a").removeClass("active");
          currLink.addClass("active");
        } else {
          currLink.removeClass("active");
        }
      });
    };

    $(document).on("scroll", onScroll);

    const scrollToTarget = (target) => {
      const $target = $(target);

      if ($target.length) {
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top + 1,
            },
            500,
            "swing",
            function () {
              window.location.hash = target;
              $(document).on("scroll", onScroll);
            }
          );
      }
    };

    $('.scroll-to-section a[href^="#"]').on("click", function (e) {
      e.preventDefault();
      const target = this.hash;

      if (location.pathname !== "/") {
        navigate("/", { state: { target } });
      } else {
        scrollToTarget(target);
      }
    });

    if (location.state?.target) {
      scrollToTarget(location.state.target);
    }

    const handleMenuTriggerClick = (e) => {
      e.preventDefault();
      $(".menu-trigger").toggleClass("active");
      $(".nav").stop(true, true).slideToggle(200);
    };

    $(".menu-trigger").on("click", handleMenuTriggerClick);

    $(".nav a").on("click", () => {
      if ($(window).width() < 767) {
        $(".menu-trigger").removeClass("active");
        $(".nav").slideUp(200);
      }
    });

    return () => {
      $(window).off("resize", mobileNav);
      $(window).off("scroll", handleScroll);
      $(document).off("scroll", onScroll);
      $(".menu-trigger").off("click", handleMenuTriggerClick);
      $(".nav a").off("click");
    };
  }, [location, navigate]);

  const handleLogoClick = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };

  return (
    <>
      <header className="header-area header-sticky bg-gradient-dark">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                <Link to="/" className="logo" onClick={handleLogoClick}>
                <img
                  src="https://elasticbeanstalk-us-east-2-047719631084.s3.us-east-2.amazonaws.com/Captura_de_pantalla_2024-08-30_232708-removebg+(1).png"
                  onClick={handleLogoClick}
                />
                  {/* TREINO<em> APP</em> */}
                </Link>
                <ul className="nav">
                  <li className="scroll-to-section">
                    <a
                      href="#main-banner"
                      onClick={(e) =>
                        e.preventDefault() ||
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                    >
                      Inicio
                    </a>
                  </li>
                  <li className="scroll-to-section">
                    <Link to="/entrenadores">Entrenadores</Link>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#how-it-works">Cómo Funciona</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#contact-us">Contacto</a>
                  </li>
                  {isAuthenticated && user ? (
                    <>
                      <li className="scroll-to-section">
                        <BarraSuperiorNavegacion user={user} context="inicio" />
                      </li>
                    </>
                  ) : (
                    <li>
                      <Link to="/login">Ingresar</Link>
                    </li>
                  )}
                </ul>
                <a className="menu-trigger">
                  <span>Menu</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* Barra de navegación inferior para móviles */}
      {isAuthenticated && user && (
        <div className="mobile-bottom-nav">
          <BarraSuperiorNavegacion user={user} context="inicio" />
        </div>
      )}
    </>
  );
};

export default EncabezadoInicio;
