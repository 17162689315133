import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentAsync,
  updateCommentAsync,
  deleteCommentAsync,
  fetchTrainerReviews,
} from "../features/CommentsSlice";
import { fetchUser, fetchUsers } from "../features/UsersSlice";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import avatarImg from "../assets/img/avatar.png";

const Comments = ({ trainerId }) => {
  const dispatch = useDispatch();
  const trainers = useSelector((state) => state.trainers.trainers);
  const users = useSelector((state) => state.users.users);
  const comments = useSelector((state) => state.comments.comments);
  const commentsStatus = useSelector((state) => state.comments.status);
  const user = useSelector((state) => state.auth.user);
  const [newComment, setNewComment] = useState("");
  const [rating, setRating] = useState(0);
  const [replyingCommentId, setReplyingCommentId] = useState(null);
  const [replyText, setReplyText] = useState("");
  console.log(comments);

  useEffect(() => {
    dispatch(fetchUsers());
    // Verificar si trainerId es válido
    console.log(trainerId, commentsStatus);
    if (trainerId && commentsStatus === "idle") {
      console.log(`Fetching comments for trainerId: ${trainerId}`);
      dispatch(fetchTrainerReviews(trainerId)).catch((error) => {
        console.error("Error fetching trainer reviews:", error);
        toast.error("Error al cargar los comentarios.");
      });
    }
  }, [dispatch, trainerId, commentsStatus]);

  const validateCommentInput = () => {
    const trimmedComment = newComment.trim();
    if (trimmedComment && trimmedComment.length < 5) {
      toast.warn("El comentario debe tener al menos 5 caracteres.");
      return false;
    }

    if (!trimmedComment && rating === 0) {
      toast.warn(
        "Debes escribir un comentario o asignar una calificación antes de enviar."
      );
      return false;
    }

    return true;
  };

  const handleAddComment = async () => {
    if (!user) {
      alert("Debes estar autenticado para dejar un comentario.");
      return;
    }

    if (!validateCommentInput()) return;

    const comment = {
      profesorId: trainerId,
      usuarioId: user.id,
      userName: user.name,
      userImage: user.profileImage,
      texto: newComment.trim(),
      rating: rating,
      createAt: new Date().toISOString(),
    };

    try {
      await dispatch(addCommentAsync(comment));
      dispatch(fetchUser(user.id)); // Refrescar los datos del usuario
      toast.success("Comentario agregado exitosamente.");
    } catch (error) {
      toast.error(
        "Ocurrió un error al agregar el comentario. Por favor, intenta nuevamente."
      );
      console.error("Error al agregar el comentario:", error);
    } finally {
      setNewComment("");
      setRating(0);
    }
  };

  const handleReply = async (commentId) => {
    if (!user) {
      toast.error("Debes estar autenticado para responder a un comentario.");
      return;
    }

    if (replyText.trim().length < 5) {
      toast.warn("La respuesta debe tener al menos 5 caracteres.");
      return;
    }

    const updatecomment = {
      respuesta: replyText,
    };

    try {
      console.log(commentId, updatecomment);
      await dispatch(
        updateCommentAsync({ commentId, updatedData: updatecomment })
      );
      setReplyingCommentId(null);
      setReplyText("");
    } catch (error) {
      toast.error(
        "Ocurrió un error al responder el comentario. Por favor, intenta nuevamente."
      );
      console.error("Error al responder el comentario:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (!user) {
      toast.error("Debes estar autenticado para eliminar un comentario.");
      return;
    }

    confirmAlert({
      title: "Confirmación de eliminación",
      message:
        "¿Estás seguro de que deseas eliminar la reseña? Esta acción no se puede deshacer.",
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              await dispatch(deleteCommentAsync({ trainerId, commentId }));
              toast.success("Comentario eliminado exitosamente.");
            } catch (error) {
              toast.error(
                "Ocurrió un error al eliminar el comentario. Por favor, intenta nuevamente."
              );
              console.error("Error al eliminar el comentario:", error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.info("Eliminación de comentario cancelado.");
          },
        },
      ],
    });
  };

  const getTrainerNameById = (trainerId) => {
    const trainer = trainers.find((t) => t.id === trainerId);
    return trainer ? trainer.nombre : "Entrenador desconocido";
  };
  const getTrainerFotoById = (trainerId) => {
    const trainer = trainers.find((t) => t.id === trainerId);
    return trainer ? trainer.fotoUrl : avatarImg;
  };
  const getUserNameById = (UserId) => {
    const user = users.find((u) => u.id === UserId);
    return user ? user.nombre : "Usuario desconocido";
  };
  const getUserFotoById = (UserId) => {
    const user = users.find((u) => u.id === UserId);
    return user ? user.foto : avatarImg;
  };

  const isTrainerAuthenticated =
    user && user.rol === "TRAINER" && user.id === trainerId;

  const filteredComments = comments.filter(
    (comment) => comment.profesorId === trainerId
  );

  return (
    <div className="unique-comments-section">
      <h2>Comentarios</h2>
      {filteredComments.length > 0 ? (
        filteredComments
          .slice()
          .reverse()
          .map((comment) => (
            <div key={comment.id} className="unique-comment">
              <div className="unique-comment-header">
                <img
                  src={avatarImg}
                  alt={getUserNameById(comment.usuarioId)}
                  className="unique-comment-user-image"
                />
                <div>
                  <strong>{getUserNameById(comment.usuarioId)}</strong>
                  <p>{new Date(comment.createAt).toLocaleDateString()}</p>
                  <p>{comment.texto}</p>
                </div>
                <div className="unique-rating">
                  {Array(comment.rating)
                    .fill()
                    .map((_, i) => (
                      <i key={i} className="fa fa-star"></i>
                    ))}
                </div>
              </div>
              {comment.respuesta && (
                <div key={comment.id} className="unique-reply">
                  <img
                    src={getTrainerFotoById(comment.profesorId)}
                    alt={getTrainerNameById(comment.profesorId)}
                    className="unique-comment-user-image"
                  />
                  <div>
                    <strong>{getTrainerNameById(comment.profesorId)}</strong>{" "}
                    <p>{comment.respuesta}</p>
                  </div>
                </div>
              )}
              {isTrainerAuthenticated && (
                <div className="unique-comment-actions">
                  {!comment.respuesta && (
                    <button
                      className="unique-reply-button"
                      onClick={() => setReplyingCommentId(comment.id)}
                    >
                      Responder
                    </button>
                  )}
                  <button
                    className="unique-delete-button"
                    onClick={() => handleDeleteComment(comment.id)}
                  >
                    Eliminar
                  </button>
                </div>
              )}
              {replyingCommentId === comment.id && (
                <div className="unique-reply-form">
                  <textarea
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    placeholder="Escribe tu respuesta (mínimo 5 caracteres)..."
                  ></textarea>
                  <button onClick={() => handleReply(comment.id)}>
                    Enviar Respuesta
                  </button>
                </div>
              )}
            </div>
          ))
      ) : (
        <p>No hay comentarios aún.</p>
      )}
      {user && user.rol === "USER" && (
        <div className="unique-new-comment-form">
          <h3>Dejar un comentario</h3>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Escribe tu comentario (mínimo 5 caracteres)..."
          ></textarea>
          <div className="unique-rating">
            {[1, 2, 3, 4, 5].map((star) => (
              <i
                key={star}
                className={`fa fa-star ${rating >= star ? "selected" : ""}`}
                onClick={() => setRating(star)}
              ></i>
            ))}
          </div>
          <button onClick={handleAddComment}>Enviar</button>
        </div>
      )}
      {!user && (
        <p>
          <a href="/login">Entra a tu cuenta</a> o{" "}
          <a href="/registro">crea una cuenta nueva</a> para compartir tu
          experiencia.
        </p>
      )}
    </div>
  );
};

export default Comments;
