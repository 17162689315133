import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faMedal,
  faCrown,
  faStar,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  createPlan,
  updatePlan,
  deletePlan,
  fetchPlans,
} from "../features/membershipSlice";
import { toast } from "react-toastify";

const MembershipManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.membership.plans);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [newPlan, setNewPlan] = useState({ name: "", price: "", features: [] });

  useEffect(() => {
    dispatch(fetchPlans());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPlan((prevPlan) => ({
      ...prevPlan,
      [name]: value,
    }));
  };

  const handleFeatureChange = (e, index) => {
    const updatedFeatures = [...newPlan.features];
    updatedFeatures[index] = e.target.value;
    setNewPlan({ ...newPlan, features: updatedFeatures });
  };

  const addFeature = () => {
    setNewPlan((prevPlan) => ({
      ...prevPlan,
      features: [...prevPlan.features, ""],
    }));
  };

  const removeFeature = (index) => {
    const updatedFeatures = newPlan.features.filter((_, i) => i !== index);
    setNewPlan({ ...newPlan, features: updatedFeatures });
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    setNewPlan(plan);
  };

  const handleSavePlan = () => {
    if (selectedPlan) {
      dispatch(updatePlan(newPlan)).then(() => {
        toast.success("Plan actualizado con éxito");
        setSelectedPlan(null);
        setNewPlan({ name: "", price: "", features: [] });
      });
    } else {
      dispatch(createPlan(newPlan)).then(() => {
        toast.success("Plan creado con éxito");
        setNewPlan({ name: "", price: "", features: [] });
      });
    }
  };

  const handleDeletePlan = (planId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este plan?")) {
      dispatch(deletePlan(planId)).then(() => {
        toast.success("Plan eliminado con éxito");
      });
    }
  };

  return (
    <div className="membership-plans-page">
      <div className="membership-management">
      <button
        className="fa-solid fa-arrow-left back-button"
        onClick={() => navigate(-1)}
      ></button>
        <h2>Gestión de Planes de Membresía</h2>
        <div className="plan-list">
          {plans.map((plan) => (
            <div key={plan.id} className="plan-card">
              <h3>{plan.name}</h3>
              <p className="price">${plan.price}/mes</p>
              <ul className="features">
                {plan.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              <div className="plan-actions">
                <button onClick={() => handleSelectPlan(plan)}>
                  <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button onClick={() => handleDeletePlan(plan.id)}>
                  <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="plan-form">
          <h3>{selectedPlan ? "Editar Plan" : "Nuevo Plan"}</h3>
          <input
            type="text"
            name="name"
            value={newPlan.name}
            onChange={handleInputChange}
            placeholder="Nombre del Plan"
          />
          <input
            type="number"
            name="price"
            value={newPlan.price}
            onChange={handleInputChange}
            placeholder="Precio del Plan"
          />
          <h4>Características</h4>
          {newPlan.features.map((feature, index) => (
            <div key={index} className="feature-item">
              <input
                type="text"
                value={feature}
                onChange={(e) => handleFeatureChange(e, index)}
              />
              <button onClick={() => removeFeature(index)}>Eliminar</button>
            </div>
          ))}
          <button onClick={addFeature}>Agregar Característica</button>
          <button onClick={handleSavePlan}>
            {selectedPlan ? "Actualizar Plan" : "Crear Plan"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MembershipManagement;
