import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdmins, updateAdmin, selectAdmin } from '../features/AdminSlice';
import { useNavigate, useParams } from 'react-router-dom';

const AdminProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector((state) => state.admins.admins.find((admin) => admin.id === id));
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const storedAdmin = JSON.parse(localStorage.getItem('ADMIN'));
    if (storedAdmin && storedAdmin.id === id) {
      setFormData(storedAdmin);
      setOriginalData(storedAdmin);
    } else {
      dispatch(fetchAdmins()).then(() => {
        const fetchedAdmin = admin;
        if (fetchedAdmin) {
          dispatch(selectAdmin(fetchedAdmin));
          setFormData(fetchedAdmin);
          setOriginalData(fetchedAdmin);
        }
      });
    }
  }, [dispatch, id, admin]);

  useEffect(() => {
    if (admin) {
      setFormData(admin);
      setOriginalData(admin);
      localStorage.setItem('ADMIN', JSON.stringify(admin)); // Actualizar el localStorage
    }
  }, [admin]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (JSON.stringify(formData) !== JSON.stringify(originalData)) {
      dispatch(updateAdmin(formData)).then(() => {
        localStorage.setItem('ADMIN', JSON.stringify(formData)); // Guardar en localStorage
        setEditable(false);
        setNotification('Cambios guardados exitosamente');
        setTimeout(() => {
          setNotification('');
        }, 3000);
      });
    } else {
      setNotification('No hay cambios para guardar');
      setTimeout(() => {
        setNotification('');
      }, 3000);
    }
  };

  const handleBackClick = () => {
    navigate(-1); // Navega hacia atrás en el historial
  };

  return (
    <div className="admin-profile-page">
      <div className="admin-profile">
        <button className="fa-solid fa-arrow-left back-button" onClick={handleBackClick}>
        </button>
        <h2>Perfil del Administrador</h2>
        {notification && <div className="notification">{notification}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nombre Completo:</label>
            <input
              type="text"
              name="name"
              value={formData?.name || ''}
              onChange={handleChange}
              disabled={!editable}
            />
          </div>
          <div className="form-group">
            <label>Correo Electrónico:</label>
            <input
              type="email"
              name="email"
              value={formData?.email || ''}
              onChange={handleChange}
              disabled={!editable}
            />
          </div>
          <div className="form-group">
            <label>Rol:</label>
            <input
              type="text"
              name="role"
              value={formData?.role || ''}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="buttons-container">
            <button
              type="button"
              className="edit-button-admin"
              onClick={() => setEditable(true)}
              disabled={editable}
            >
              Editar
            </button>
            <button type="submit" className="save-button" disabled={!editable}>
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminProfile;
