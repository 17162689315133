import React from "react";
import lineDec from "../assets/img/line-dec.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUserPlus,
  faFilter,
  faStar,
  faLockOpen,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";

const features = [
  {
    title: "Encuentra tu entrenador ideal",
    description:
      "Accede a una red de entrenadores verificados y especializados que te ayudarán a alcanzar tus metas.",
    icon: faSearch,
  },
  {
    title: "Fácil registro",
    description:
      "Regístrate en minutos y comienza tu viaje hacia un mejor tú. ¡Sin complicaciones!",
    icon: faUserPlus,
  },
  {
    title: "Personaliza tu experiencia",
    description:
      "Elige el entrenador, la categoría y entre otros filtros que mejor se ajusten a tus necesidades.",
    icon: faFilter,
  },
  {
    title: "Reseñas y evaluaciones",
    description:
      "Confía en las opiniones de otros usuarios para tomar decisiones informadas.",
    icon: faStar,
  },
  {
    title: "Acceso exclusivo a entrenadores",
    description:
      "Explora perfiles de entrenadores verificados y elige al que mejor se ajuste a tus necesidades y objetivos.",
    icon: faLockOpen,
  },
  {
    title: "Seguridad y soporte",
    description:
      "Disfruta de una plataforma segura y soporte dedicado para resolver cualquier duda.",
    icon: faShieldAlt,
  },
];

const FeatureItem = ({ title, description, icon, link }) => (
  <li className="feature-item">
    <div className="left-icon">
      <FontAwesomeIcon icon={icon} size="4x" />
    </div>
    <div className="right-content">
      <h4>{title}</h4>
      <p style={{marginLeft:100}}>{description}</p>
    </div>
  </li>
);

const HowItWorks = () => {
  // Divide los ítems en dos columnas
  const leftColumnFeatures = features.slice(0, 3);
  const rightColumnFeatures = features.slice(3);

  return (
    <section className="section" id="how-it-works">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>
                Cómo <em>Funciona</em>
              </h2>
              <img src={lineDec} alt="waves" className="img1" />
              <p>
                Descubre la forma más fácil de encontrar entrenadores
                profesionales y recibir la guía que necesitas para alcanzar tus
                metas.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <ul className="features-items">
              {leftColumnFeatures.map((feature, index) => (
                <FeatureItem key={index} {...feature} />
              ))}
            </ul>
          </div>
          <div className="col-lg-6">
            <ul className="features-items">
              {rightColumnFeatures.map((feature, index) => (
                <FeatureItem key={index} {...feature} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
