// src/components/VisitasPlataforma.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Visitas from './Visitas';
import { fetchPlatformViews } from '../features/ViewsSlice';

const VisitasPlataforma = () => {
  const dispatch = useDispatch();
  const platformViews = useSelector((state) => state.views.platformViews);

  useEffect(() => {
    dispatch(fetchPlatformViews());
  }, [dispatch]);

  return (
    <Visitas titulo="Vistas a la Plataforma" visitas={platformViews} />
  );
};

export default VisitasPlataforma;
