import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import emailjs from "emailjs-com"; // Importa EmailJS
import { toast } from 'react-toastify'; 

const ContactModal = ({ isOpen, toggle, trainerEmail }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false); // Para gestionar el estado de envío

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar que todos los campos estén completos
    if (!formData.name || !formData.email || !formData.message) {
      alert("Todos los campos son obligatorios");
      return;
    }

    setIsSending(true); // Cambia el estado mientras envías el correo

    // Configurar los datos para enviar a EmailJS
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
      trainer_name: trainerEmail, // correo del entrenador
    };

    console.log(templateParams.trainer_name);
    try {
      // Envíar el correo usando EmailJS
      await emailjs.send(
        "service_87ml07w", // Service ID
        "template_21mrgyi", // Template ID de Contacto al Entrenador
        templateParams,
        "Siq044lIJzJ2u6Rb7" // User ID
      );

      toast.success('Mensaje enviado exitosamente');
      toggle(); // Cierra el modal después de enviar
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      toast.error('Hubo un error al enviar el mensaje. Inténtalo de nuevo.');
    } finally {
      setIsSending(false); // Restablece el estado de envío
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Contactar al Entrenador</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">Nombre</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="message">Mensaje</Label>
            <Input
              type="textarea"
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <div style={{textAlign:"end"}}>
            <Button color="secondary" onClick={toggle}>
              Cancelar
            </Button>
            <Button color="success" type="submit" disabled={isSending}>
              {isSending ? "Enviando..." : "Enviar"}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ContactModal;
