import React from 'react';
import { Link } from 'react-router-dom';

const MainBanner = () => (
  <div className="main-banner" id="top">
    <video autoPlay muted loop id="bg-video" playsInline={true}>
      <source src="https://elasticbeanstalk-us-east-2-047719631084.s3.us-east-2.amazonaws.com/gym-video.mp4" type="video/mp4" />
    </video>
    <div className="video-overlay header-text">
      <div className="caption">
        <h6>Conecta con entrenadores, alcanza tus metas</h6>
        <h2>
          Encuentra Tu Entrenador <br /> <em>Ideal</em>
        </h2>
        <div className="main-button scroll-to-section">
          <Link to="/entrenadores" className="btnlink">Buscar Entrenadores</Link>
          <Link to="/registro" className="btnlink">Únete como Entrenador</Link>
        </div>
      </div>
    </div>
  </div>
);

export default MainBanner;
