import React from 'react';

const About = ({ formData, editable, handleChange }) => {
  
  return (
    <div className="about-section">
      <h3>Sobre mí</h3>
      {editable ? (
        <textarea
          name="sobreMi"
          value={formData.sobreMi}
          onChange={handleChange}
          className="edit-textarea"
        />
      ) : (
        <p>{formData.sobreMi}</p>
      )}
    </div>
  );
};

export default About;
