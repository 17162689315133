// src/components/VisitasPerfilEntrenador.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Visitas from './Visitas';
import { fetchProfileViews } from '../features/ViewsSlice';

const VisitasPerfilEntrenador = ({ trainerId }) => {
  const dispatch = useDispatch();
  const profileViews = useSelector((state) => state.views.profileViews[trainerId]);

  useEffect(() => {
    dispatch(fetchProfileViews(trainerId));
  }, [dispatch, trainerId]);

  return (
    <Visitas titulo="Vistas a tu Perfil" visitas={profileViews || 0} />
  );
};

export default VisitasPerfilEntrenador;
