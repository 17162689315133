import React from 'react'
import Encabezado from './Encabezado'
import MontoFinalInversiones from './MontoFinalInversiones'
import NumeroUsuarios from './NumeroUsuarios'
import NumeroEntrenadores from './NumeroEntrenadores'
import Requerimientos from './Requerimientos'
import VisitasPlataforma from './VisitasPlataforma';

const ContenidoInicio = () => {
  return (
    /* <!-- Contenido de la página de inicio--> */
    <div className="container-fluid">
      <Encabezado />
      <div className='row'>
      <MontoFinalInversiones />
      <NumeroUsuarios />
      <NumeroEntrenadores />
      <VisitasPlataforma />
      </div>
      <Requerimientos />
    </div>
  )
}

export default ContenidoInicio