import { useMap } from 'react-leaflet';
import { useEffect } from 'react';

const MapCenterer = ({ selectedLocation }) => {
  const map = useMap();

  useEffect(() => {
    if (selectedLocation) {
      map.setView([selectedLocation.lat, selectedLocation.lng], 13); // Ajusta el zoom y la ubicación
    }
  }, [selectedLocation, map]);

  return null;
};

export default MapCenterer;
