import React from 'react';

const Availability = ({ formData, editable, handleChange }) => {
  return (
    <div id="schedule" className="availability-section">
      <h3>Disponibilidad</h3>
      {editable ? (
        <textarea
          name="disponibilidad"
          value={formData.disponibilidad}
          onChange={handleChange}
          className="edit-textarea"
          placeholder="Escribe tu disponibilidad manualmente..."
          rows="4"
        />
      ) : (
        <p>{formData.disponibilidad}</p>
      )}
    </div>
  );
};

export default Availability;
