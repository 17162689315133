import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    // Simular verificación del token
    if (token) {
      // Aquí iría la lógica para verificar el token en el backend
      setVerificationStatus('Email verificado con éxito.');
      setTimeout(() => navigate('/login'), 3000); // Redirigir al login después de 3 segundos
    } else {
      setVerificationStatus('Token de verificación no válido.');
    }
  }, [location, navigate]);

  return (
    <div className="verify-email">
      <h1>{verificationStatus}</h1>
    </div>
  );
}

export default VerifyEmail;
