// src/services/adminService.js
import api from './axiosConfig';

// Obtener todos los administradores
export const getAdmins = async () => {
    const response = await api.get('/admins');
    return response.data;
  };

  // Registrar un nuevo administrador
export const registerAdmin = async (adminData) => {
    const response = await api.post('/crear_admin', adminData);
    return response.data;
  };
  
  // Inicio de sesión del administrador
  export const loginAdmin = async (adminData) => {
    const response = await api.post('/login_admin', adminData);
    return response.data;
  };
  
  // Actualizar un administrador
export const updateAdminAccount = async (adminId, adminData) => {
    const response = await api.put(`/admins/${adminId}`, adminData);
    return response.data;
  };
  
  // Eliminar un administrador
  export const deleteAdmin = async (adminId) => {
    const response = await api.delete(`/delete_admin/${adminId}`);
    return response.data;
  };
  