// src/services/trainerService.js
import api from './axiosConfig';
import axios from 'axios';

// Obtener todos los entrenadores
export const getTrainers = async () => {
  const response = await api.get('/profesores');
  return response.data;
};

// Obtener solo entrenadores publicados en la plataforma
export const getPublishedTrainers = async () => {
  const response = await api.get('/profesores_publicados');
  return response.data;
   //  condición de visibilidad // 
   //.filter((trainer) => trainer.profileComplete && trainer.membership && trainer.profileVisibility === "public")
};

// Obtener un entrenador por ID
export const getTrainerById = async (trainerId) => {
  const response = await api.get(`/find_profesor/${trainerId}`);
  return response.data;
};

// Obtener un entrenador por email
export const getTrainerByEmail = async (email) => {
  try {
    const response = await api.get('/find_profesor', {
      params: { email: email }  // Aquí pasas el email como parámetro de consulta
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trainer by email:', error);
    throw error;  // Manejo de errores
  }
};

// Registrar un nuevo entrenador
export const registerTrainer = async (trainerData) => {
  const response = await api.post('/sign_up', trainerData);
  return response.data;
};

// Inicio de sesión del entrenador
export const loginTrainer = async (trainerData) => {
  const response = await api.post('/login', trainerData);
  return response.data;
};

// Actualizar la cuenta de un entrenador
export const updateTrainerAccount = async (trainerId, trainerData) => {
  console.log(trainerId, trainerData);
  const response = await api.put(`/update/${trainerId}`, trainerData);
  console.log(response);
  return response.data;
};

// Actualizar la foto de un entrenador (subida a S3 y luego actualizar la BD)
export const updateTrainerPhoto = async (trainerId, photoFile) => {
  try {
    const formData = new FormData();
    console.log(trainerId, photoFile);

    formData.append('foto', photoFile);  // archivo de imagen
    formData.append('type', 'profesor'); // el tipo de usuario

    // Realiza la petición PUT para actualizar la foto del entrenador
    const response = await api.put(`/update_foto/${trainerId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // await updateTrainerAccount(trainerId, { type:"profesor", fotoUrl: photoFile });
    return response.data; // Retorna la respuesta del backend
  } catch (error) {
    console.error('Error al subir la foto de perfil del entrenador:', error);
    throw error;
  }
};

// Subir la foto de verificación del entrenador (similar a updateTrainerPhoto)
export const uploadVerificationPhoto = async (trainerId, photoFile) => {
  try {
    const formData = new FormData();
    console.log(trainerId, photoFile);

    formData.append('foto', photoFile);  // archivo de imagen
    formData.append('type', 'profesor'); // el tipo de usuario

    // Realiza la petición POST para subir la foto de verificación del entrenador
    const response = await api.post(`/upload_foto_verificacion/${trainerId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // await updateTrainerAccount(trainerId, { type:"profesor", fotoUrl: photoFile });
    return response.data; // Retorna la respuesta del backend
  } catch (error) {
    console.error('Error al subir la foto de verificación perfil del entrenador:', error);
    throw error;
  }
};

// Subir imágenes y videos al perfil del entrenador (entrenadores/media)
export const uploadTrainerMedia = async (trainerId, mediaFile) => {
  try {
    // 1. Solicitar URL firmada del backend para subir media (imágenes o videos) a S3
    const { data } = await api.put(`/get_media_upload_url/${trainerId}`, {
      fileType: mediaFile.type,
    });

    const { uploadUrl, key } = data;

    // 2. Subir el archivo media (imagen/video) a S3
    await axios.put(uploadUrl, mediaFile, {
      headers: { 'Content-Type': mediaFile.type },
    });

    // 3. Actualizar la URL de la media en la base de datos del entrenador
    const mediaUrl = `https://elasticbeanstalk-us-east-2-047719631084.s3.us-east-2.amazonaws.com/${key}`;
    const updatedMedia = {
      mediaUrl, // URL del archivo media
      tipo: mediaFile.type.startsWith('image') ? 'imagen' : 'video',
    };

    await updateTrainerAccount(trainerId, {
      $push: { media: updatedMedia },
    });

    return mediaUrl;
  } catch (error) {
    console.error('Error al subir media del entrenador:', error);
    throw error;
  }
};

// Eliminar la cuenta de un entrenador
export const deleteTrainer = async (trainerId) => {
  const response = await api.delete(`/delete/${trainerId}`);
  return response.data;
};

// Eliminar todos los entrenadores
export const deleteAllTrainers = async () => {
  const response = await api.delete('/delete');
  return response.data;
};