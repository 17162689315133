import "./App.css";

import Loader from "./componentes/Loader";
import Inicio from "./componentes/inicio";
import EncabezadoLayout from "./componentes/EncabezadoLayout";
import Entrenadores from "./componentes/Entrenadores";
import AdminProfile from "./componentes/AdminProfile";
import TrainerProfile from "./componentes/TrainerProfile";
import UserProfile from "./componentes/UserProfile";
import CrearPerfil from "./componentes/CrearPerfil";
import Login from "./componentes/Login";
import Registro from "./componentes/Registro";
import VerifyEmail from "./componentes/VerifyEmail";
import ForgetPassword from "./componentes/ForgetPassword";
import Dashboard from "./componentes/Dashboard";
import Settings from "./componentes/Settings";
import UserActivity from "./componentes/UserActivity";
import Analytics from "./componentes/Analytics";
import MembershipPlans from "./componentes/MembershipPlans";
import MembershipManagement from "./componentes/MembershipManagement";
import NoEncontrado from "./componentes/NoEncontrado";
import ProtectedRoute from "./componentes/ProtectedRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";

const App = () => {
   //localStorage.clear();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Loader />
        <Routes>
          <Route element={<EncabezadoLayout />}>
            <Route path="/" element={<Inicio />} />
            <Route path="/admin-profile/:id" element={<AdminProfile />} />
            <Route path="/entrenadores" element={<Entrenadores />} />
            <Route path="/entrenadores/:id" element={<TrainerProfile />} />
            <Route path="/user-profile/:id" element={<UserProfile />} />
            <Route path="/trainer-profile/:id" element={<TrainerProfile />} />
            <Route
              path="/crear-perfil/"
              element={
                <ProtectedRoute allowedRoles={["TRAINER", "ADMIN"]}>
                  <CrearPerfil />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-activity"
              element={
                <ProtectedRoute allowedRoles={["USER"]}>
                  <UserActivity />
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/settings"
              element={
                <ProtectedRoute allowedRoles={["USER", "TRAINER", "ADMIN"]}>
                  <Settings />
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/analytics"
              element={
                <ProtectedRoute allowedRoles={["TRAINER", "ADMIN"]}>
                  <Analytics />
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/membership-plans/:trainerId"
              element={
                <ProtectedRoute allowedRoles={["TRAINER", "ADMIN"]}>
                  <MembershipPlans />
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/membership-management"
              element={
                <ProtectedRoute allowedRoles={["ADMIN"]}>
                  <MembershipManagement />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/registro" element={<Registro />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/*" element={<NoEncontrado />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
