import React from "react";
import BarraSuperiorNavegacion from "./BarraSuperiorNavegacion";
import BusquedaBarraSuperior from "./BusquedaBarraSuperior";
import BarraSuperiorMenuDashboard from "./BarraSuperiorMenuDashboard";

const BarraSuperior = ({ user, context, toggleSidebar}) => {
  return (
    <div>
      {" "}
      {/* <!-- barra superior --> */}
      <nav className="navbar navbar-expand navbar-light topbar topbar-dashboard mb-4 static-top shadow">
        <BarraSuperiorMenuDashboard toggleSidebar={toggleSidebar} />
        <BusquedaBarraSuperior />
        <BarraSuperiorNavegacion user={user} context={context} />
      </nav>
      {/* <!-- Fin de la barra superior --> */}
    </div>
  );
};

export default BarraSuperior;
