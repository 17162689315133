import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCrown,
  faStar,
  faMedal,
  faHeart,
  faEllipsisV,
  faPhone,
  faShareAlt,
  faExclamationTriangle,
  faEnvelope,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import VerificationModal from "./VerificationModal";
import ContactModal from "./ContactModal";
import { categories, locations } from "../utils/constants";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../features/UsersSlice";

const membershipIcons = {
  BASIC: faMedal,
  PREMIUM: faStar,
  ELITE: faCrown,
};

const ProfileActions = ({
  trainer,
  onFavorite,
  onShare,
  onReport,
  onContact,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleWhatsAppMessage = () => {
    const message = `Hola ${trainer.nombre}, vi tu perfil en TreinoApp y me gustaría ponerme en contacto contigo.`;
    const whatsappUrl = `https://wa.me/${
      trainer.telefono
    }?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="profile-actions">
      <button className="contact-button" onClick={handleWhatsAppMessage}>
        <FontAwesomeIcon icon={faPhone} /> WhatsApp
      </button>
      <button
        className="call-button"
        onClick={() => window.open(`tel:${trainer.telefono}`, "_blank")}
      >
        <FontAwesomeIcon icon={faPhone} /> Llamar
      </button>
      <button className="email-button" onClick={onContact}>
        <FontAwesomeIcon icon={faEnvelope} /> Contactar
      </button>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle caret className="dropdown-toggle-actions">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={onShare}>
            <FontAwesomeIcon icon={faShareAlt} /> Compartir
          </DropdownItem>
          <DropdownItem onClick={onFavorite}>
            <FontAwesomeIcon icon={faHeart} /> Agregar a Favoritos
          </DropdownItem>
          <DropdownItem onClick={onReport}>
            <FontAwesomeIcon icon={faExclamationTriangle} /> Denunciar
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const TrainerProfileHeader = ({
  trainer = {},
  user,
  editable,
  setEditable,
  formData,
  setFormData,
  profileImage,
  handleImageChange,
  handleChange,
  handleSubmit,
  isTrainerAuthenticated,
  isCreating,
  handleSelectPlan,
}) => {
  const canEditProfile =
    (isTrainerAuthenticated && user?.id === trainer?.id) ||
    user?.rol === "ADMIN";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Verifica si el entrenador ya está en la lista de favoritos del usuario
  const isFavorite = useSelector((state) => {
    if (!user || !user.entrenadoresFavoritos) return false;
    return user.entrenadoresFavoritos.some((favT) => favT.id === trainer.id); // Verifica si el ID está en favoritos
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleContactModalOpen = () => {
    setIsContactModalOpen(true);
  };

  const handleContactModalClose = () => {
    setIsContactModalOpen(false);
  };

  const handleFavorite = async (e) => {
    console.log("id entrenador " + trainer.id);
    e.preventDefault();
    // Log de información actual del usuario y sus favoritos
    console.log("User:", user);
    console.log("Current Favorites:", user.entrenadoresFavoritos);

    // Clona los entrenadores favoritos actuales
    let updatedFavorites = [...(user.entrenadoresFavoritos || [])];

    if (isFavorite) {
      // Si ya es favorito, eliminarlo
      updatedFavorites = updatedFavorites.filter(
        (favT) => favT.id !== trainer.id
      );
      console.log(`Removed trainer  ${trainer.id} from favorites.`);
    } else {
      // Si no es favorito, agregarlo
      updatedFavorites.push({
        id: trainer.id,
        nombre: trainer.nombre,
        foto: trainer.foto,
        especialidad: trainer.especialidad,
      });
      console.log(`Added trainer ${trainer.id} to favorites.`);
    }

    console.log("Updated Favorites:", updatedFavorites);

    // Actualiza el usuario con la nueva lista de favoritos
    dispatch(
      updateUser({
        userId: user.id,
        userData: {
          type: user.type,
          entrenadoresFavoritos: updatedFavorites,
        },
      })
    )
      .then((response) => {
        console.log("Update User Response:", response);
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
  };

  const handleShare = () => {
    const trainerProfileLink = `${window.location.origin}/entrenadores/${trainer.id}`;
    const message = `¡Mira este perfil de entrenador en TreinoApp! ${trainerProfileLink}`;

    // URL para compartir en WhatsApp
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleReport = () => {
    navigate("/#contact-us");
  };

  const handleMembershipClick = () => {
    if (trainer?.id) {
      handleSelectPlan();
    } else {
      console.error("Perfil de entrenador no encontrado");
    }
  };

  // Aquí aseguramos que trainer.social es un arreglo
  const socialLinks = Array.isArray(trainer.social) ? trainer.social : [];

  return (
    <div className="profile-header">
      <div className="profile-image-container">
        <img
          src={profileImage}
          alt={formData.nombre}
          className="profile-image"
        />
        {editable && (
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="edit-input"
          />
        )}
      </div>
      <div className="profile-info">
        {editable ? (
          <>
            <label className="required-label">Nombre:</label>
            <input
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              className="edit-input required-field"
            />

            <label className="required-label">Especialidad:</label>
            <select
              name="especialidad"
              value={formData.especialidad}
              onChange={handleChange}
              className="edit-input required-field"
            >
              <option value="" disabled>
                Selecciona una especialidad
              </option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>

            <label className="required-label">Ubicación:</label>
            <select
              name="ciudad"
              value={formData.ciudad}
              onChange={handleChange}
              className="edit-input required-field"
            >
              <option value="" disabled>
                Selecciona una localidad
              </option>
              {locations.map((location, index) => (
                <option key={index} value={location}>
                  {location}
                </option>
              ))}
            </select>

            <label className="required-label">Teléfono:</label>
            <input
              type="tel"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              className="edit-input required-field"
            />

            <label className="required-label">Descripción:</label>
            <textarea
              name="descripcion"
              value={formData.descripcion}
              onChange={handleChange}
              className="edit-input required-field"
            />

            <label className="required-label">Tarifa:</label>
            <input
              type="number"
              name="precio"
              value={formData.precio}
              onChange={handleChange}
              className="edit-input required-field"
            />
          </>
        ) : (
          <>
            <h1>
              {trainer.nombre}
              {trainer.verificado ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="sm"
                  className="verified-icon"
                />
              ) : canEditProfile ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="sm"
                  className="unverified-icon"
                  onClick={handleModalOpen}
                  style={{ cursor: "pointer" }}
                />
              ) : null}
           {/* Mostrar el ícono de membresía solo si el entrenador tiene un plan */}
           {trainer.membresia ? (
                <FontAwesomeIcon
                  icon={membershipIcons[trainer.membresia]}
                  size="sm"
                  className={`membership-icon-profile ${trainer.membresia.toLowerCase()}`}
                  title={trainer.membresia}
                  onClick={canEditProfile ? handleMembershipClick : null}
                  style={{ cursor: canEditProfile ? "pointer" : "default" }}
                />
              ) : (
                // Si el entrenador no tiene plan y el usuario es un administrador, mostrar ícono para actualizar membresía
                user?.rol === "ADMIN" && (
                  <FontAwesomeIcon
                    icon={faTools} // Ícono que el admin puede ver y usar
                    size="sm"
                    className="membership-icon-profile no-membership"
                    title="Actualizar Membresía"
                    onClick={handleMembershipClick}
                    style={{ cursor: "pointer" }}
                  />
                )
              )}
            </h1>
            <p>
              <strong>Especialidad:</strong> {trainer.especialidad}
            </p>
            <p>
              <strong>Ubicación:</strong> {trainer.ciudad}
            </p>
            <p>
              <strong>Teléfono:</strong> {trainer.telefono}
            </p>
            <p>
              <strong>Tarifa:</strong> ${trainer.precio}/h
            </p>
            <div className="rating">
              <FontAwesomeIcon icon={faStar} className="star-icon" />
              <span>{trainer.rating ? trainer.rating.toFixed(1) : ""}</span>
            </div>
          </>
        )}
        {!editable && (
          <ProfileActions
            trainer={trainer}
            onFavorite={handleFavorite}
            onShare={handleShare}
            onReport={handleReport}
            onContact={handleContactModalOpen}
          />
        )}
        <div className="social-icons">
          {socialLinks.map((s, index) => (
            <a key={index} href={s.link} className={`fab fa-${s.platform}`}></a>
          ))}
        </div>
        {editable && !isCreating && (
          <button type="button" className="save-button" onClick={handleSubmit}>
            Guardar
          </button>
        )}
      </div>
      {canEditProfile && !isCreating && (
        <button
          type="button"
          className="edit-button-trainer"
          onClick={() => setEditable(!editable)}
        >
          {editable ? "Cancelar" : "Editar"}
        </button>
      )}
      {isModalOpen && (
        <VerificationModal
          isOpen={isModalOpen}
          toggle={handleModalClose}
          trainerId={trainer.id}
        />
      )}
      {isContactModalOpen && (
        <ContactModal
          isOpen={isContactModalOpen}
          toggle={handleContactModalClose}
          trainerEmail={trainer.email}
        />
      )}
    </div>
  );
};

export default TrainerProfileHeader;
