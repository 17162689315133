import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const BarraSuperiorMenuDashboard = ({ toggleSidebar }) => {
  return (
    <div>
      {/* <!-- barra superior Menu --> */}
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
        onClick={toggleSidebar} // Llama a la función para alternar la visibilidad
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      {/* <!-- Fin de la barra superior Menu--> */}
    </div>
  );
};

export default BarraSuperiorMenuDashboard;
