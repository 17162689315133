import { configureStore } from "@reduxjs/toolkit";
import monedasReducer from "../features/MonedasSlice";
import transaccionesReducer from "../features/TransaccionesSlice";
import trainersReducer from "../features/TrainersSlice";
import authReducer from '../features/authSlice';
import commentsReducer from '../features/CommentsSlice';
import usersReducer from '../features/UsersSlice';
import adminsReducer from '../features/AdminSlice';
import analyticsReducer from '../features/AnalyticsSlice';
import viewsReducer from '../features/ViewsSlice';
import membershipReducer from '../features/membershipSlice'; 


export const store = configureStore({
    reducer: {
        admins: adminsReducer,
        auth: authReducer,
        monedas: monedasReducer,
        transacciones: transaccionesReducer,
        trainers: trainersReducer,
        comments: commentsReducer,
        users: usersReducer,
        analytics: analyticsReducer,
        views: viewsReducer,
        membership: membershipReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Desactiva la verificación de serialización
        }),
});
