import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    monedas:[]
}

export const monedasSlice = createSlice({
    name:"monedasSlice",
    initialState,
    reducers:{
         guardarMonedas:(state, action) =>{
            state.monedas = action.payload;
         },
         agregarTarea:(state, action) =>{
             state.monedas.push(action.payload);
         }
    }
})

export const{ guardarMonedas, agregarTarea} = monedasSlice.actions;

export default monedasSlice.reducer;