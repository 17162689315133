import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { fetchPublishedTrainers} from "../features/TrainersSlice";
import lineDec from "../assets/img/line-dec.png";
import TrainerItem from "./TrainerItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { membershipOrder } from "../utils/constants";

const CustomPrevArrow = ({ onClick }) => (
  <button className="slick-prev" onClick={onClick}>
    {/* <FontAwesomeIcon icon={faChevronLeft} /> */}
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button className="slick-next" onClick={onClick}>
    {/* <FontAwesomeIcon icon={faChevronRight} /> */}
  </button>
);

const FeaturedTrainers = () => {
  const dispatch = useDispatch();
  const trainers = useSelector((state) => state.trainers.trainers);
  const status = useSelector((state) => state.trainers.status);
  const error = useSelector((state) => state.trainers.error);
  const [eliteTrainers, setEliteTrainers] = useState([]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchPublishedTrainers());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (trainers.length > 0) {
      applyFilters();
    }
  }, [trainers]);

  const applyFilters = () => {
    const eliteT = trainers
      .filter(
        (trainer) =>
          trainer.membresia === "ELITE" &&
          trainer.perfilCompleto &&
          trainer.visibilidadPerfil === "PUBLIC"
      )
      .sort(
        (a, b) => membershipOrder[a.membresia] - membershipOrder[b.membresia]
      );
    setEliteTrainers(eliteT);
  };
  const settings = {
    dots: false, // Deshabilitar los puntos de navegación
    infinite: true, // Hacer que el carrusel sea infinito
    speed: 500, // Velocidad de transición
    slidesToShow: 3, // Mostrar 3 entrenadores a la vez
    slidesToScroll: 1, // Desplazar 1 entrenador a la vez
    autoplay: true, // Desplazamiento automático
    autoplaySpeed: 3000, // Velocidad de desplazamiento automático (3 segundos)
    arrows: true, // Mostrar flechas de navegación
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024, // Para pantallas medianas
        settings: {
          slidesToShow: 2, // Mostrar 2 entrenadores a la vez
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Para pantallas pequeñas
        settings: {
          slidesToShow: 1, // Mostrar 1 entrenador a la vez
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (status === "loading") {
    return <div>Cargando entrenadores destacados...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="section" id="featured-trainers">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>
                ENTRENADORES <em>DESTACADOS</em>
              </h2>
              <img src={lineDec} alt="" />
              <p>
                Todos los entrenadores están comprometidos a ayudarte a alcanzar
                tus objetivos...
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Slider {...settings}>
              {eliteTrainers.length > 0 ? (
                eliteTrainers.map((trainer, index) => (
                  <div key={index} className="trainer-slider-item">
                    <TrainerItem key={index} {...trainer} />
                  </div>
                ))
              ) : (
                <div className="col-lg-12">
                  <p>No hay entrenadores destacados disponibles.</p>
                </div>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedTrainers;
