import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   addTrainerComment,
//   addReplyToTrainerComment,
//   deleteTrainerComment,
//   updateTrainerComment,
// } from "./TrainersSlice";
import { fetchUser } from "./UsersSlice";
import {
  addReview,
  deleteReview,
  updateReview,
  getUserReviews,
  getTrainerReviews,
  getAllReviews,
  getReviewById,
} from "../services/reviewService";

// Funciones temporales para manejar el almacenamiento en localStorage.
// const loadCommentsFromLocalStorage = () => {
//   try {
//     const storedComments = localStorage.getItem("comments");
//     return storedComments ? JSON.parse(storedComments) : [];
//   } catch (error) {
//     console.error("Error cargando los comentarios desde localStorage", error);
//     return [];
//   }
// };

// const saveCommentsToLocalStorage = (comments) => {
//   try {
//     localStorage.setItem("comments", JSON.stringify(comments));
//   } catch (error) {
//     console.error("Error guardando los comentarios en localStorage", error);
//   }
// };

// Thunks para operaciones asincrónicas

// Obtener reseñas por usuario
export const fetchUserReviews = createAsyncThunk(
  "comments/fetchUserReviews",
  async (userId, { rejectWithValue }) => {
    try {
      const reviews = await getUserReviews(userId);
      return reviews;
    } catch (error) {
      return rejectWithValue("Error al obtener reseñas del usuario");
    }
  }
);

// Obtener reseñas por entrenador
export const fetchTrainerReviews = createAsyncThunk(
  "comments/fetchTrainerReviews",
  async (trainerId, { rejectWithValue }) => {
    try {
      const reviews = await getTrainerReviews(trainerId);
      return reviews;
    } catch (error) {
      return rejectWithValue("Error al obtener reseñas del entrenador");
    }
  }
);

// Obtener todas las reseñas (para el administrador)
export const fetchReview = createAsyncThunk(
  "comments/fetchAllReviews",
  async (reviewId, { rejectWithValue }) => {
    try {
      const review = await getReviewById(reviewId);
      return review;
    } catch (error) {
      return rejectWithValue("Error al obtener la reseña");
    }
  }
);
// Obtener todas las reseñas (para el administrador)
export const fetchAllReviews = createAsyncThunk(
  "comments/fetchAllReviews",
  async (_, { rejectWithValue }) => {
    try {
      const reviews = await getAllReviews();
      return reviews;
    } catch (error) {
      return rejectWithValue("Error al obtener todas las reseñas");
    }
  }
);

// Añadir comentario
export const addCommentAsync = createAsyncThunk(
  "comments/addComment",
  async (newComment, { dispatch,  }) => {
    try {
       console.log(newComment);
       const user = await dispatch(fetchUser(newComment.usuarioId)).unwrap();
       console.log(user);
       if (!user) {
         throw new Error("Usuario no encontrado");
      }

      // const newComment = {
      //   trainerId,
      //   userId,
      //   //  userName: user.name,
      //   //userImage: user.profileImage,
      //   text,
      //   rating,
      //   date: new Date().toISOString(),
      //   replies: [],
      // };

      // Guardar el comentario en el backend
      const savedReview = await addReview(newComment);

      // await dispatch(addTrainerComment({ trainerId, comment: savedReview }));
      // await dispatch(addUserReview({ userId, review: savedReview }));

      // const updatedComments = [...state.comments.comments, newComment];
      // saveCommentsToLocalStorage(updatedComments);

      return savedReview;
    } catch (error) {
      console.error("Error al agregar comentario", error);
      throw new Error("No se pudo agregar el comentario");
    }
  }
);

export const replyToCommentAsync = createAsyncThunk(
  "comments/replyToComment",
  async ({ trainerId, commentId, updateComment }, { dispatch, getState }) => {
    try {
      const state = getState();
      const comment = state.comments.comments.find((c) => c.id === commentId);

      if (!comment) {
        throw new Error("Comentario no encontrado");
      }

      const trainer = state.trainers.trainers.find((t) => t.id === trainerId);

      if (!trainer) {
        throw new Error("Entrenador no encontrado");
      }

      // const reply = {
      //   userName: trainer.name, // Nombre real del entrenador.
      //   userImage: trainer.image, // Imagen del perfil del entrenador.
      //   date: new Date().toISOString(),
      //   text: replyText,
      // };

      // Guardar la respuesta en el backend
      const updatedReview = await updateReview(commentId, updateComment);

      // await dispatch(addReplyToTrainerComment({ trainerId, commentId, reply }));

      return updatedReview.respuesta;
    } catch (error) {
      console.error("Error al agregar respuesta", error);
      throw new Error("No se pudo agregar la respuesta");
    }
  }
);

// Eliminar un comentario
export const deleteCommentAsync = createAsyncThunk(
  "comments/deleteComment",
  async ({ trainerId, commentId }, { dispatch }) => {
    try {
      // Eliminar el comentario en el backend
      await deleteReview(commentId);

      // Actualizar el estado global
      // await dispatch(deleteTrainerComment({ trainerId, commentId }));

      return { trainerId, commentId };
    } catch (error) {
      console.error("Error al eliminar comentario", error);
      throw new Error("No se pudo eliminar el comentario");
    }
  }
);

// Actualizar un comentario
export const updateCommentAsync = createAsyncThunk(
  "comments/updateComment",
  async ({ commentId, updatedData }, { rejectWithValue }) => {
    try {
      console.log(commentId, updatedData);
      // Actualizar el comentario en el backend
      const updatedReview = await updateReview(commentId, updatedData);

      // Actualizar el estado global
      // await dispatch(
      //   updateTrainerComment({
      //     trainerId,
      //     commentId,
      //     updatedData: updatedReview,
      //   })
      // );

      return updatedReview;
    } catch (error) {
      console.error("Error al actualizar comentario", error);
      return rejectWithValue(
        error.response?.data?.message || "Error al actualizar comentario"
      );    }
  }
);

// Slice principal
// const initialState = {
//   comments: loadCommentsFromLocalStorage(),
// };
// Slice principal
const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    comments: [],
    status: "idle",
    error: null,
  },
  reducers: {
    clearComments: (state) => {
      state.comments = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Añadir comentario
      .addCase(addCommentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCommentAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.comments.push(action.payload);
      })
      .addCase(addCommentAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Responder a un comentario
      .addCase(replyToCommentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(replyToCommentAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { commentId, reply } = action.payload;
        const comment = state.comments.find((c) => c.id === commentId);
        if (comment) {
          comment.replies.push(reply);
        }
      })
      .addCase(replyToCommentAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Eliminar un comentario
      .addCase(deleteCommentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCommentAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.comments = state.comments.filter(
          (comment) => comment.id !== action.payload.commentId
        );
      })
      .addCase(deleteCommentAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Actualizar un comentario
      .addCase(updateCommentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCommentAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.comments.findIndex(
          (c) => c.id === action.payload.id
        );
        if (index !== -1) {
          state.comments[index] = action.payload;
        }
      })
      .addCase(updateCommentAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Manejo de reseñas de usuario
      .addCase(fetchUserReviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserReviews.fulfilled, (state, action) => {
        state.comments = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchUserReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Manejo de reseñas de entrenador
      .addCase(fetchTrainerReviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrainerReviews.fulfilled, (state, action) => {
        state.comments = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchTrainerReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Manejo de todas las reseñas (para el admin)
      .addCase(fetchAllReviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllReviews.fulfilled, (state, action) => {
        state.comments = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchAllReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearComments} = commentsSlice.actions;

export default commentsSlice.reducer;
