// src/services/userService.js
import api from './axiosConfig';
import axios from 'axios';

// Obtener todos los usuarios
export const getUsers = async () => {
  const response = await api.get('/usuarios');
  return response.data;
};

// Obtener un usuario por ID
export const getUserById = async (userId) => {
  const response = await api.get(`/find_usuario/${userId}`);
  return response.data;
};

// Obtener un usuario por email
export const getUserByEmail = async (email) => {
  try {
    const response = await api.get('/find_usuario', {
      params: { email: email } 
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user by email:', error);
    throw error;  
  }
};

// Registrar un nuevo usuario
export const createUser = async (userData) => {
  const response = await api.post('/crear_usuario', userData);
  return response.data;
};

// Inicio de sesion de usuario
export const loginUser = async (userData) => {
  const response = await api.post('/login_usuario', userData);
  return response.data;
};

// Actualizar un usuario
export const updateUserAccount = async (userId, userData) => {
  const response = await api.put(`/update_usuario/${userId}`, userData);
  return response.data;
};

// src/services/userService.js

// Actualizar la foto de un usuario (subida a S3 y luego actualizar la BD)
export const updateUserPhoto = async (userId, photoFile) => {
  try {
    // 1. Solicitar URL firmada del backend para subir la foto a S3
    const { data } = await api.put(`/update_foto_usuario/${userId}`, {
      fileType: photoFile.type,
    });

    const { uploadUrl, key } = data; // URL firmada y ruta de S3

    // 2. Subir la foto a S3 usando la URL firmada
    await axios.put(uploadUrl, photoFile, {
      headers: {
        'Content-Type': photoFile.type,
      },
    });

    // 3. Actualizar la URL de la foto en la base de datos 
    const photoUrl = `https://elasticbeanstalk-us-east-2-047719631084.s3.us-east-2.amazonaws.com/${key}`;
    await updateUserAccount(userId, { type:"usuario",foto: photoUrl });

    return photoUrl;
  } catch (error) {
    console.error('Error al subir la foto de perfil:', error);
    throw error;
  }
};


// Eliminar un usuario
export const deleteUser = async (userId) => {
  const response = await api.delete(`/delete_usuario/${userId}`);
  return response.data;
};

// Eliminar todos los usuarios
export const deleteAllUsers = async () => {
  const response = await api.delete('/delete_usuarios');
  return response.data;
};

// // Agregar un entrenador a la lista de favoritos
// export const addFavoriteTrainer = async (userId, trainerId) => {
//   const response = await api.patch(`/usuarios/${userId}/addFavoriteTrainer`, { trainerId });
//   return response.data;
// };

// // Eliminar un entrenador de la lista de favoritos
// export const removeFavoriteTrainer = async (userId, trainerId) => {
//   const response = await api.patch(`/usuarios/${userId}/removeFavoriteTrainer`, { trainerId });
//   return response.data;
// };

