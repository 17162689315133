import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAnalytics } from '../features/AnalyticsSlice';
import { useNavigate } from "react-router-dom";
import VisitasPerfilEntrenador from './VisitasPerfilEntrenador';

const Analytics = () => {
  const dispatch = useDispatch();
  const analytics = useSelector((state) => state.analytics.data);
  const status = useSelector((state) => state.analytics.status);
  const error = useSelector((state) => state.analytics.error);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(fetchAnalytics({ role: user.role, trainerId: user.id }));
    }
  }, [dispatch, user]);

  if (status === 'loading') {
    return <div className="analytics-page">Loading...</div>;
  }

  if (status === 'failed') {
    return <div className="analytics-page">Error: {error}</div>;
  }

  return (
    <div className="analytics-page">
      <div className="analytics">
        <button className="fa-solid fa-arrow-left back-button" onClick={() => navigate(-1)}></button>
        <h2>{user.role === 'ADMIN' ? 'Estadísticas de la Plataforma' : 'Analíticas'}</h2>
        <div className="analytics-cards">
          <div className="analytics-card">
            <h3>{analytics.publishedProfiles || 0}</h3>
            <p>Perfiles publicados</p>
          </div>
          <div className="analytics-card">
            <h3>{analytics.pendingProfiles || 0}</h3>
            <p>Perfiles pendientes</p>
          </div>
          <div className="analytics-card">
            <h3>{analytics.activePromotions || 0}</h3>
            <p>Promociones activas</p>
          </div>
          <div className="analytics-card">
            <h3>{analytics.weeklyVisits || 0}</h3>
            <p>Visitas esta semana</p>
          </div>
        </div>
        <div className="analytics-details">
          <div className="analytics-section">
            <h4>Visitas</h4>
            <p>Últimas 24 horas: {analytics.visits?.last24Hours || 0}</p>
            <p>Últimos 7 días: {analytics.visits?.last7Days || 0}</p>
            <p>Últimos 30 días: {analytics.visits?.last30Days || 0}</p>
          </div>
          <div className="analytics-section">
            <h4>Visitas únicas</h4>
            <p>Últimas 24 horas: {analytics.uniqueVisits?.last24Hours || 0}</p>
            <p>Últimos 7 días: {analytics.uniqueVisits?.last7Days || 0}</p>
            <p>Últimos 30 días: {analytics.uniqueVisits?.last30Days || 0}</p>
          </div>
          <div className="analytics-section">
            <h4>Dispositivos</h4>
            <p>Móviles: {analytics.devices?.mobile || 0}</p>
            <p>Desktop: {analytics.devices?.desktop || 0}</p>
          </div>
          {user.role === 'trainer' && (
            <div className="analytics-section">
              <h4>Visitas a tu Perfil</h4>
              <VisitasPerfilEntrenador trainerId={user.id} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
