import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPublishedTrainers } from "../features/TrainersSlice";
import lineDec from "../assets/img/line-dec.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView } from "@fortawesome/free-solid-svg-icons";
import TrainerItem from "./TrainerItem";
import TrainerMap from "./TrainerMap";
import { categories, locations, membershipOrder } from "../utils/constants";
import { getDistance } from "geolib";

const Trainers = () => {
  const dispatch = useDispatch();
  const trainers = useSelector((state) => state.trainers.trainers);
  const status = useSelector((state) => state.trainers.status);
  const error = useSelector((state) => state.trainers.error);

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    category: "",
    location: "",
    verified: false,
  });
  const [filteredTrainers, setFilteredTrainers] = useState([]);
  const [isMapView, setIsMapView] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null); // Estado para la ubicación seleccionada
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchPublishedTrainers());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (trainers.length > 0) {
      applyFilters();
    }
  }, [trainers, search, filter]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleLocateMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setFilter((prevFilter) => ({
            ...prevFilter,
            userLocation: userLocation,
          }));
          setIsMapView(true);
        },
        (error) => {
          console.error("Error al obtener la ubicación", error);
        }
      );
    } else {
      alert("La geolocalización no es compatible con este navegador.");
    }
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilter({
      ...filter,
      [name]: type === "checkbox" ? checked : value,
    });

    // Actualizar la ubicación seleccionada cuando se cambia la ubicación en el filtro
    if (name === "location" && value) {
      const location = locations.find(
        (loc) => loc.toLowerCase() === value.toLowerCase()
      );
      if (location) {
        // Aquí debes definir las coordenadas para cada ubicación en el filtro
        const locationCoords = {
          montevideo: { lat: -34.9011, lng: -56.1645 },
          canelones: { lat: -34.7745, lng: -55.9396 },
          // Añade aquí las coordenadas de otras ubicaciones
        };
        setSelectedLocation(locationCoords[value.toLowerCase()]);
      }
    }
  };

  const applyFilters = () => {
    const filtered = trainers
      .slice()
      .filter(
        (trainer) =>
          trainer.perfilCompleto &&
          trainer.membresia &&
          trainer.visibilidadPerfil === "PUBLIC"
      )
      .sort((a, b) => {
        if (membershipOrder[a.membresia] !== membershipOrder[b.membresia]) {
          return membershipOrder[a.membresia] - membershipOrder[b.membresia];
        } else {
          return b.verificado - a.verificado;
        }
      })
      .filter((trainer) => {
        const nameMatch = trainer.nombre
          ?.toLowerCase()
          .includes(search.toLowerCase());
        const categoryMatch =
          filter.category === "" ||
          trainer.especialidad
            ?.toLowerCase()
            .includes(filter.category.toLowerCase());
        const locationMatch =
          filter.location === "" ||
          trainer.ciudad
            ?.toLowerCase()
            .includes(filter.location.toLowerCase());
        const verifiedMatch =
          filter.verified === false || trainer.verificado === filter.verified;

        let proximityMatch = true;
        if (filter.userLocation) {
          const distance = getDistance(
            {
              latitude: filter.userLocation.lat,
              longitude: filter.userLocation.lng,
            },
            {
              latitude: trainer.latitud,
              longitude: trainer.longitud,
            }
          );
          proximityMatch = distance < 1000000; // 10 km por ejemplo
        }

        return (
          nameMatch &&
          categoryMatch &&
          locationMatch &&
          verifiedMatch &&
          proximityMatch
        );
      });
    setFilteredTrainers(filtered);
  };

  if (status === "loading") {
    return <div>Cargando entrenadores...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="section" id="trainers">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>
                Búsqueda de <em>Entrenadores</em>
              </h2>
              <img src={lineDec} alt="" />
              <p>Encuentra el entrenador perfecto para ti...</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Buscar por nombre"
                value={search}
                onChange={handleSearch}
              />
              <select
                name="category"
                value={filter.category}
                onChange={handleFilterChange}
              >
                <option value="">Todas las categorías</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              <select
                name="location"
                value={filter.location}
                onChange={handleFilterChange}
              >
                <option value="">Ubicación</option>
                {locations.map((location, index) => (
                  <option key={index} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              <button className="my-location-button" onClick={handleLocateMe}>
                <FontAwesomeIcon icon={faStreetView} />
              </button>
              <label>
                <input
                  type="checkbox"
                  name="verified"
                  checked={filter.verified}
                  onChange={handleFilterChange}
                />
                Verificado
              </label>
              <button
                className="toggle-view-button"
                onClick={() => setIsMapView(!isMapView)}
              >
                {isMapView ? "Ver Lista" : "Ver Mapa"}
              </button>
              {isMobile && (
                <button className="search-button" onClick={applyFilters}>
                  Buscar
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {isMapView ? (
            <TrainerMap
              trainers={filteredTrainers}
              selectedLocation={selectedLocation}
            />
          ) : filteredTrainers.length > 0 ? (
            filteredTrainers.map((trainer, index) => (
              <TrainerItem key={index} {...trainer} />
            ))
          ) : (
            <div className="col-lg-12">
              <p>
                No se encontraron entrenadores que coincidan con los criterios
                de búsqueda.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Trainers;
