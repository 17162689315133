import React from 'react'
import LoginForm from './LoginForm';
import { Link } from 'react-router-dom';

const Login = () => {
    return (
        <div className="container">
            <div className="row justify-content-center">

                <div className="col-xl-10 col-lg-12 col-md-9">

                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-100 mb-4">Bienvenido!</h1>
                                        </div>
                                        <LoginForm />
                                        <hr />
                                        <div className="text-center">
                                            <Link className="small" to="/forget-password">Se te olvidó tu contraseña?</Link>
                                        </div>
                                        <div className="text-center">
                                            <Link className="small" to="/registro">Crear una Cuenta!</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Login