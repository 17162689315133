// src/components/BarraSuperiorNavegacion.js
import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faHome } from "@fortawesome/free-solid-svg-icons";
import Alertas from "./Alertas";
import InformacionUsuario from "./InformacionUsuario";
import { useNavigate, useLocation } from "react-router-dom";

const BarraSuperiorNavegacion = ({ user, context }) => {
  const navigate = useNavigate();
  const location = useLocation(); 

  const navbarClass =
    context === "dashboard"
      ? "navbar-nav ml-auto"
      : "navbar-inicio topbar mb-4 static-top bg-gradient-dark";

  const handleHomeClick = () => {
    navigate("/");
  };
  const handleSearchClick = () => {
    navigate("/entrenadores");
  };
  const activeColor = "#6cc558";
  const inactiveColor = "#e4e4e7";

  return (
    <ul className={`${navbarClass}`}>
      <li className="nav-item dropdown no-arrow mx-1 icon-mobile">
        <Button
          className="nav-link dropdown-toggle"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={handleHomeClick}
          style={{
            background: "transparent",
            border: "none",
            fontSize: "larger",
            color: location.pathname === "/" ? activeColor : inactiveColor,
          }}
        >
          <FontAwesomeIcon icon={faHome} />
        </Button>
      </li>
      <li className="nav-item dropdown no-arrow mx-1 icon-mobile">
        <Button
          className="nav-link dropdown-toggle"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={handleSearchClick}
          style={{
            background: "transparent",
            border: "none",
            fontSize: "larger",
            color: location.pathname === "/entrenadores" ? activeColor : inactiveColor,
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </li>
      <Alertas />
      <InformacionUsuario user={user} />
    </ul>
  );
};

export default BarraSuperiorNavegacion;

// import React from 'react'
// import Alertas from './Alertas'
// import InformacionUsuario from './InformacionUsuario'

// const BarraSuperiorNavegacion = ({user}) => {
//     return (
//         /* <!-- Barra superior Barra de navegación --> */
//         <ul className="navbar-nav ml-auto">
//             <Alertas/>
//             <InformacionUsuario user={user}/>
//         </ul>
//     )
// }

// export default BarraSuperiorNavegacion
