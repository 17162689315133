import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { v4 as uuidv4 } from "uuid";
// import { initialUsers } from "../utils/loadedUsers";
import {
  getUsers,
  getUserById,
  getUserByEmail,
  createUser,
  loginUser,
  updateUserAccount,
  updateUserPhoto,
  deleteUser,
  deleteAllUsers,
} from "../services/userService";


// Thunks para operaciones asincrónicas

//Obtener todos los usuarios
export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const users = await getUsers(); // Llama al servicio de API para obtener todos los usuarios
      return users;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error al obtener los usuarios"
      );
    }
  }
);

// Obtener un usuario por ID
export const fetchUser = createAsyncThunk(
  "users/fetchUser",
  async (userId, { rejectWithValue }) => {
    try {
      const user = await getUserById(userId);
      return user;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al obtener el usuario"
      );
    }
  }
);

// Obtener un usuario por email
export const fetchUserByEmail = createAsyncThunk(
  "users/fetchUserByEmail",
  async (email, { rejectWithValue }) => {
    try {
      const user = await getUserByEmail(email);
      return user;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al obtener el usuario por email"
      );
    }
  }
);

// Crear un nuevo usuario
export const addUser = createAsyncThunk(
  "users/addUser",
  async (userData, { rejectWithValue }) => {
    try {
      const newUser = await createUser(userData);
      return newUser;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al agregar usuario"
      );
    }
  }
);

// Login usuario
export const loginUserAccount = createAsyncThunk(
  "users/loginUserAccount",
  async (userData, { rejectWithValue }) => {
    try {
      const logUser = await loginUser(userData);
      return logUser;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al iniciar sesion de usuario"
      );
    }
  }
);

// Actualizar un usuario existente
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ userId, userData }, { rejectWithValue }) => {
    try {
      const updatedUser = await updateUserAccount(userId, userData);
      return updatedUser;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al actualizar usuario"
      );
    }
  }
);

// Actualizar la foto de un usuario existente (subida a S3 y actualización en la BD)
export const updateUserProfilePhoto = createAsyncThunk(
  "users/updateUserProfilePhoto",
  async ({ userId, photoFile }, { rejectWithValue }) => {
    try {
      const updatedUser = await updateUserPhoto(userId, photoFile);
      return updatedUser;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error al actualizar la foto del usuario"
      );
    }
  }
);

// Eliminar usuario
export const deleteUserAccount = createAsyncThunk(
  "users/deleteUserAccount",
  async (userId, { dispatch, rejectWithValue }) => {
    try {
      await deleteUser(userId);
      return userId;
    } catch (error) {
      console.error("Error al eliminar cuenta de usuario", error);
      return rejectWithValue(
        error.response?.data || "Error al eliminar la cuenta del usuario"
      );
    }
  }
);

// Eliminar todos los usuarios
export const deleteAllUsersAccount = createAsyncThunk(
  "users/deleteAllUsersAccount",
  async (_, { rejectWithValue }) => {
    try {
      await deleteAllUsers();
      return "Todos los usuarios fueron eliminados con exito";
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error al eliminar todos los usuarios"
      );
    }
  }
);

/// Añadir o eliminar entrenador favorito
export const toggleFavoriteTrainer = createAsyncThunk(
  "users/toggleFavoriteTrainer",
  async ({ trainer }, { getState, rejectWithValue }) => {
    try {
      const { users } = getState();
      const user = users.selectedUser; // Obtener el usuario actual

      if (!user) {
        throw new Error("Usuario no encontrado");
      }

      // Asegúrate de que entrenadoresFavoritos sea un array
      let entrenadoresFavoritos = user.entrenadoresFavoritos || [];

      const isFavorite = entrenadoresFavoritos.some((favT) => favT.id === trainer.id);

      if (isFavorite) {
        // Elimina de favoritos
        entrenadoresFavoritos = entrenadoresFavoritos.filter(
          (favT) => favT.id !== trainer.id
        );
      } else {
        // Agrega a favoritos
        entrenadoresFavoritos.push(trainer);
      }

      // Actualizar favoritos en localStorage
      localStorage.setItem("favoriteTrainers", JSON.stringify(entrenadoresFavoritos));

      return { userId: user.id, entrenadoresFavoritos };
    } catch (error) {
      return rejectWithValue(error.message || "Error al modificar entrenadores favoritos");
    }
  }
);



// // Añadir o eliminar entrenador favorito
// export const toggleFavoriteTrainer = createAsyncThunk(
//   "users/toggleFavoriteTrainer",
//   async ({ userId, trainerId, isFavorite }, { rejectWithValue }) => {
//     try {
//       if (isFavorite) {
//         await removeFavoriteTrainer(userId, trainerId); // Elimina de favoritos
//       } else {
//         await addFavoriteTrainer(userId, trainerId); // Agrega a favoritos
//       }
//       return { userId, trainerId, isFavorite: !isFavorite };
//     } catch (error) {
//       return rejectWithValue(
//         error.response?.data?.message ||
//           "Error al modificar entrenadores favoritos"
//       );
//     }
//   }
// );

// Slice principal
const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    selectedUser: null,
    status: "idle",
    error: null,
  },
  reducers: {
    selectUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    clearUsers: (state) => {
      state.users = [];
      state.status = 'idle';
      state.error = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Users
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch Single User by ID
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedUser = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch Single User by Email
      .addCase(fetchUserByEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserByEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedUser = action.payload;
      })
      .addCase(fetchUserByEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Add User
      .addCase(addUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users.push(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Login User
      .addCase(loginUserAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUserAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users.push(action.payload);
      })
      .addCase(loginUserAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update User
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
        if (state.selectedUser && state.selectedUser.id === action.payload.id) {
          state.selectedUser = action.payload;
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update User Profile Photo
      .addCase(updateUserProfilePhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserProfilePhoto.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
        if (state.selectedUser && state.selectedUser.id === action.payload.id) {
          state.selectedUser = action.payload;
        }
      })
      .addCase(updateUserProfilePhoto.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      
      // Delete User
      .addCase(deleteUserAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUserAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = state.users.filter((user) => user.id !== action.payload);
        if (state.selectedUser && state.selectedUser.id === action.payload) {
          state.selectedUser = null;
        }
      })
      .addCase(deleteUserAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete All Users
      .addCase(deleteAllUsersAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAllUsersAccount.fulfilled, (state) => {
        state.status = "succeeded";
        state.users = [];
        state.selectedUser = null;
      })
      .addCase(deleteAllUsersAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(toggleFavoriteTrainer.fulfilled, (state, action) => {
        const { userId, entrenadoresFavoritos } = action.payload;
        const user = state.users.find((user) => user.id === userId);
    
        if (user) {
          user.entrenadoresFavoritos = entrenadoresFavoritos; // Actualiza la lista de favoritos
        }
    
        if (state.selectedUser && state.selectedUser.id === userId) {
          state.selectedUser.entrenadoresFavoritos = entrenadoresFavoritos;
        }
      });
    
      

      // // Toggle Favorite Trainer
      // .addCase(toggleFavoriteTrainer.pending, (state) => {
      //   state.status = "loading";
      // })
      // .addCase(toggleFavoriteTrainer.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   const { userId, trainerId, isFavorite } = action.payload;
      //   const user = state.users.find((user) => user.id === userId);
      //   if (user) {
      //     if (isFavorite) {
      //       user.entrenadoresFavoritos = user.entrenadoresFavoritos.filter(
      //         (id) => id !== trainerId
      //       );
      //     } else {
      //       user.entrenadoresFavoritos = [...user.entrenadoresFavoritos, trainerId];
      //     }
      //   }
      //   if (state.selectedUser && state.selectedUser.id === userId) {
      //     state.selectedUser = user;
      //   }
      // })
      // .addCase(toggleFavoriteTrainer.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload;
      // });
  },
});

export const { selectUser, clearUsers, clearError,
  loadFavoritesFromStorage, } = usersSlice.actions;

export default usersSlice.reducer;
