// src/services/reviewService.js
import api from './axiosConfig';

// Añadir una reseña
export const addReview = async (review) => {
  const response = await api.post(`/crear_review`, review);
  return response.data;
};

// Eliminar una reseña
export const deleteReview = async (reviewId) => {
  const response = await api.delete(`/delete_review/${reviewId}`);
  return response.data;
};

// Actualizar una reseña
export const updateReview = async (reviewId, reviewData) => {
  console.log(reviewId, reviewData);
  const response = await api.put(`/update_review/${reviewId}`, reviewData);
  return response.data;
};

// Añadir o actualizar una respuesta a una reseña
export const addOrUpdateReply = async (reviewId, replyText) => {
  const response = await api.patch(`/reviews/${reviewId}`, { reply: replyText });
  return response.data;
};

// Obtener reseñas por usuario
export const getUserReviews = async (userId) => {
  const response = await api.get(`/reviews_usuario/${userId}`); 
  return response.data;
};

// Obtener reseñas por entrenador
export const getTrainerReviews = async (trainerId) => {
  const response = await api.get(`/reviews_profesor/${trainerId}`);
  return response.data;
};

// Obtener todas las reseñas (para el administrador)
export const getReviewById = async (reviewId) => {
  const response = await api.get(`/find_review/${reviewId}`);
  return response.data;
};

// Obtener todas las reseñas (para el administrador)
export const getAllReviews = async () => {
  const response = await api.get(`/reviews`);
  return response.data;
};